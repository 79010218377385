import { useEffect, useState } from 'react';

import { Box, Divider, Group, Overlay, Switch, Text } from '@mantine/core';
import { showNotification } from '@mantine/notifications';

import {
  useGetUserSettingsQuery,
  useModifyUserSettingsMutation,
} from '@api/userSettings/userSettings.api';

import useAuth from '@hooks/useAuth';

export default function ModalPatchNotificationsSettings() {
  // ==========================================================================
  // General
  // ==========================================================================
  const { user, patch: modifyUser, isLoading } = useAuth();

  // ==========================================================================
  // State
  // ==========================================================================
  const [pushNotifServiceExpiryEnabled, setPushNotifServiceExpiryEnabled] =
    useState(false);
  const [
    pushNotifWebsiteScanErrorsEnabled,
    setPushNotifWebsiteScanErrorsEnabled,
  ] = useState(false);

  const [emailNotifServiceExpiryEnabled, setEmailNotifServiceExpiryEnabled] =
    useState(false);
  const [
    emailNotifWebsiteScanErrorsEnabled,
    setEmailNotifWebsiteScanErrorsEnabled,
  ] = useState(false);

  // ==========================================================================
  // Api
  // ==========================================================================
  const { data: userSettings } = useGetUserSettingsQuery();

  const [modifyUserSettings, { isLoading: isModifyUserSettingsLoading }] =
    useModifyUserSettingsMutation();

  useEffect(() => {
    if (userSettings) {
      setPushNotifServiceExpiryEnabled(
        userSettings.find(
          (setting) => setting.key === 'notificationServiceExpiryPush',
        )?.value === 'true',
      );

      setPushNotifWebsiteScanErrorsEnabled(
        userSettings.find(
          (setting) => setting.key === 'notificationWebsiteScanErrorsPush',
        )?.value === 'true',
      );

      setEmailNotifServiceExpiryEnabled(
        userSettings.find(
          (setting) => setting.key === 'notificationServiceExpiryEmail',
        )?.value === 'true',
      );

      setEmailNotifWebsiteScanErrorsEnabled(
        userSettings.find(
          (setting) => setting.key === 'notificationWebsiteScanErrorsEmail',
        )?.value === 'true',
      );
    }
  }, [userSettings]);

  // ==========================================================================
  // Handlers
  // ==========================================================================
  const handleSettingsChange = async (key: string, value: boolean) => {
    switch (key) {
      case 'pushNotifEnabled':
        if (value === true) {
          if (Notification.permission === 'denied') {
            // Request notification permission
            showNotification({
              title: 'Notifiche disabilitate',
              message: 'Le notifiche sono state disabilitate dal browser.',
            });
          }
          const permission = await Notification.requestPermission();

          if (permission === 'granted') {
            try {
              await modifyUser({ pushNotificationsEnabled: true });
            } catch (e) {
              console.error(e);
            }
          }
        } else {
          try {
            await modifyUser({ pushNotificationsEnabled: false });
          } catch (e) {
            console.error(e);
          }
        }
        break;
      case 'emailNotifEnabled':
        try {
          await modifyUser({ emailNotificationsEnabled: value });
        } catch (e) {
          console.error(e);
        }
        break;
      default:
        try {
          await modifyUserSettings({
            settings: [
              {
                key,
                value: value.toString(),
              },
            ],
          }).unwrap();
        } catch (e) {
          console.error(e);
        }
        break;
    }
  };

  // ==========================================================================
  // Render
  // ==========================================================================
  return (
    <Group p="md" justify="space-between">
      <Box pos="relative">
        <Overlay />
        <Text mb="md">
          <strong>Notifiche push</strong>
        </Text>
        <Switch
          label="Abilita"
          mb="xs"
          disabled={isLoading || isModifyUserSettingsLoading}
          checked={user?.pushNotificationsEnabled}
          onChange={(e) =>
            handleSettingsChange('pushNotifEnabled', e.currentTarget.checked)
          }
        />
        <Divider my="sm" />
        <Switch
          label="Scadenza servizio"
          mb="xs"
          disabled={!user?.pushNotificationsEnabled}
          checked={pushNotifServiceExpiryEnabled}
          onChange={(e) =>
            handleSettingsChange(
              'notificationServiceExpiryPush',
              e.currentTarget.checked,
            )
          }
        />
        <Switch
          label="Errore siti web"
          mb="xs"
          disabled={!user?.pushNotificationsEnabled}
          checked={pushNotifWebsiteScanErrorsEnabled}
          onChange={(e) =>
            handleSettingsChange(
              'notificationWebsiteScanErrorsPush',
              e.currentTarget.checked,
            )
          }
        />
      </Box>
      <div>
        <Text mb="md">
          <strong>Notifiche email</strong>
        </Text>
        <Switch
          label="Abilita"
          mb="xs"
          disabled={isLoading || isModifyUserSettingsLoading}
          checked={user?.emailNotificationsEnabled}
          onChange={(e) =>
            handleSettingsChange('emailNotifEnabled', e.currentTarget.checked)
          }
        />
        <Divider my="sm" />
        <Switch
          label="Scadenza servizio"
          mb="xs"
          disabled={!user?.emailNotificationsEnabled}
          checked={emailNotifServiceExpiryEnabled}
          onChange={(e) =>
            handleSettingsChange(
              'notificationServiceExpiryEmail',
              e.currentTarget.checked,
            )
          }
        />
        <Switch
          label="Errore siti web"
          mb="xs"
          disabled={!user?.emailNotificationsEnabled}
          checked={emailNotifWebsiteScanErrorsEnabled}
          onChange={(e) =>
            handleSettingsChange(
              'notificationWebsiteScanErrorsEmail',
              e.currentTarget.checked,
            )
          }
        />
      </div>
    </Group>
  );
}
