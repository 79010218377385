import { DataTable } from 'mantine-datatable';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { User } from '@interfaces/auth.interface';
import {
  ActionIcon,
  Center,
  Group,
  Pagination,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { openConfirmModal, openModal } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { IconPencil, IconTrash } from '@tabler/icons-react';
import { setMinHeightTable } from '@utils/general';

import { isApiError } from '@api/index';
import {
  useDeleteUserMutation,
  useGetUsersCountQuery,
  useGetUsersQuery,
  UserApiQueryParams,
} from '@api/users/users.api';
import { generateUserListMock } from '@api/users/users.mock';

import useLayoutProps from '@components/layout/useLayoutProps';
import ModalAddUser from '@components/ModalAddUser';
import ModalPatchUser from '@components/ModalPatchUser';
import SearchAddContainer from '@components/SearchAddContainer';

export default function Users() {
  // ==========================================================================
  // General
  // ==========================================================================
  const [searchParams] = useSearchParams();
  const theme = useMantineTheme();
  useLayoutProps({
    title: 'Utenti',
  });

  // ==========================================================================
  // State
  // ==========================================================================
  const [filters, setFilters] = useState<UserApiQueryParams>({
    page: +(searchParams.get('page') || 1),
    pageLength: +(searchParams.get('pageLength') || 50),
    searchQuery: '',
  });

  const [searchQuery] = useDebouncedValue(filters.searchQuery, 200, {
    leading: true,
  });

  // ==========================================================================
  // Api
  // ==========================================================================
  const {
    data: users = generateUserListMock(5),
    isLoading,
    error: errorUsers,
  } = useGetUsersQuery({
    ...filters,
    searchQuery,
  });

  const { data: userCount = { count: 0 }, isLoading: isLoadingUserCount } =
    useGetUsersCountQuery(searchQuery);

  const [userDelete, { isLoading: deleteLoading }] = useDeleteUserMutation();

  // ==========================================================================
  // Handlers
  // ==========================================================================
  const onEditUserClick = async (user: User) => {
    openModal({
      title: 'Modifica utente',
      children: <ModalPatchUser user={user} />,
      size: 'lg',
    });
  };

  const onOpenUserClick = async () => {
    openModal({
      title: 'Crea un nuovo utente',
      children: <ModalAddUser />,
      size: 'md',
    });
  };

  // ==========================================================================
  // Render
  // ==========================================================================
  const totalPages = Math.ceil(userCount.count / filters.pageLength!);

  return (
    <SearchAddContainer
      searchPlaceholder="Ricerca per nome, cognome o email"
      searchValue={filters.searchQuery}
      onSearchChange={(newValue) =>
        setFilters({ ...filters, searchQuery: newValue })
      }
      onActionButtonClick={onOpenUserClick}
    >
      <DataTable
        minHeight={setMinHeightTable(users)}
        withRowBorders
        striped
        styles={{
          root: {
            borderRadius: theme.radius.md,
            boxShadow: theme.shadows.lg,
          },
          header: {
            backgroundColor: '#1e2023',
          },
        }}
        records={users}
        columns={[
          { accessor: 'name', title: 'Nome' },
          { accessor: 'surname', title: 'Cognome' },
          { accessor: 'email', title: 'Email' },
          {
            accessor: 'actions',
            title: '',
            render: (record: User) => (
              <Group justify="flex-end">
                <ActionIcon
                  title="Modifica"
                  onClick={() => onEditUserClick(record)}
                >
                  <IconPencil />
                </ActionIcon>
                <ActionIcon
                  title="Elimina"
                  loading={deleteLoading}
                  onClick={() => {
                    openConfirmModal({
                      title: 'Eliminazione utente',
                      size: 'lg',
                      children: (
                        <Text>
                          Stai per eliminare l'utente {record.name}. Sicuro di
                          voler continuare?
                        </Text>
                      ),
                      labels: {
                        confirm: 'Conferma eliminazione',
                        cancel: 'Annulla',
                      },
                      confirmProps: { color: 'red' },
                      onConfirm: async () => {
                        try {
                          await userDelete({ id: record.id }).unwrap();
                          showNotification({
                            title: 'Eliminazione utente',
                            message:
                              "L'eliminazione dell'utente avvenuta con successo",
                          });
                        } catch (e) {
                          console.error(e);
                          if (isApiError(e)) {
                            showNotification({
                              color: 'red',
                              title: 'Errore',
                              message: `${e.data.message}`,
                            });
                          }
                        }
                      },
                    });
                  }}
                >
                  <IconTrash />
                </ActionIcon>
              </Group>
            ),
          },
        ]}
        fetching={isLoading || isLoadingUserCount}
        noRecordsText={
          errorUsers ? 'Errore. Ricaricare la pagina' : 'Nessun Utente trovato'
        }
      ></DataTable>

      {users.length > 0 && totalPages > 1 && (
        <Center mt="xl">
          <Pagination
            value={filters.page}
            onChange={(newPage) => setFilters({ ...filters, page: newPage })}
            total={totalPages}
          />
        </Center>
      )}
    </SearchAddContainer>
  );
}
