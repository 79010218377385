import { createBrowserRouter } from 'react-router-dom';

import Layout from '@components/layout/Layout';
import PrivateRoute from '@components/PrivateRoute';
import RequirePermissionRoute from '@components/RequirePermissionRoute';

import Agencies from './agencies/Agencies';
import ClientDetail from './clientDetail/ClientDetail';
import ClientProjects from './clientProjects/ClientProjects';
import Clients from './Clients';
import ClientServices from './clientServices/ClientServices';
import Home from './dashboard/Dashboard';
import Jobs from './jobs/Jobs';
import Login from './login/Login';
import Notifications from './Notifications';
import PageNotFound from './PageNotFound/PageNotFound';
import PasswordReset from './passwordReset/PasswordReset';
import PasswordResetRequest from './passwordResetRequest/PasswordResetRequest';
import Projects from './Projects';
import ProjectServices from './ProjectServices';
import Services from './services/Services';
import ServiceShImport from './serviceShImport/ServiceShImport';
import Settings from './Settings';
import UserActivate from './userActivate/UserActivate';
import Users from './users/Users';
import WebsiteScanner from './WebsiteScanner';

export const LOGIN_PATH = '/login';

const router = createBrowserRouter([
  { path: 'login', element: <Login /> },
  // { path: 'users/activate/:token', element: <ActivateUser /> },
  { path: '/password-reset', element: <PasswordResetRequest /> },
  { path: '/password-reset/:token', element: <PasswordReset /> },
  { path: '/user-activate/:token', element: <UserActivate /> },

  {
    path: '/',
    element: <Layout />,
    children: [
      { index: true, element: <PrivateRoute element={<Home />} /> },
      {
        path: '/utenti',
        element: (
          <RequirePermissionRoute permission="users" element={<Users />} />
        ),
      },
      {
        path: '/clienti',
        element: <PrivateRoute element={<Clients />} />,
      },
      {
        path: '/jobs',
        element: <PrivateRoute element={<Jobs />} />,
      },
      {
        path: '/clienti/:id',
        element: <PrivateRoute element={<ClientDetail />} />,
      },
      {
        path: '/clienti/:id/servizi/',
        element: <PrivateRoute element={<ClientServices />} />,
      },
      {
        path: '/clienti/:id/progetti',
        element: <PrivateRoute element={<ClientProjects />} />,
      },
      {
        path: '/servizi',
        element: <PrivateRoute element={<Services />} />,
      },
      {
        path: '/servizi/import/:id',
        element: <PrivateRoute element={<ServiceShImport />} />,
      },
      {
        path: '/progetti',
        element: <PrivateRoute element={<Projects />} />,
      },

      {
        path: '/progetto/:id/servizi',
        element: <PrivateRoute element={<ProjectServices />} />,
      },
      {
        path: '/agenzie',
        element: <PrivateRoute element={<Agencies />} />,
      },
      {
        path: '/impostazioni',
        element: <PrivateRoute element={<Settings />} />,
      },
      {
        path: '/notifiche',
        element: <PrivateRoute element={<Notifications />} />,
      },
      {
        path: '/website-scanner',
        element: (
          <RequirePermissionRoute
            permission="tools"
            element={<WebsiteScanner />}
          />
        ),
      },
      { path: '*', element: <PageNotFound /> },
    ],
  },
]);

export default router;
