import { DataTable, DataTableSortStatus } from 'mantine-datatable';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import {
  ActionIcon,
  Badge,
  Box,
  Button,
  Container,
  Group,
  rem,
  Select,
  Space,
  Switch,
  Text,
  Tooltip,
  useMantineTheme,
} from '@mantine/core';
import { openConfirmModal, openModal } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import {
  IconAlertCircle,
  IconCircleDashedNumber2,
  IconCircleFilled,
  IconPencil,
  IconTrash,
} from '@tabler/icons-react';
import { dateMinusPlusDays, dateToDateString } from '@utils/date';
import { setMinHeightTable } from '@utils/general';
import { numberToCurrency } from '@utils/number';

import socket from '@lib/socket';

import { isApiError } from '@api/index';
import { useGetJobsStatusQuery } from '@api/jobs/jobs.api';
import {
  Service,
  useDeleteServiceMutation,
  usePatchServiceMutation,
} from '@api/services/services.api';
import {
  CustomSecondaryServiceMap,
  CustomServiceShMap,
  ServiceSh,
  useDeleteServicesShMutation,
  usePatchServicesShMutation,
  useStartImportServicesJobMutation,
} from '@api/servicessh/servicesSh.api';

import ModalAddService from './ModalAddService';
import ModalPatchService from './ModalPatchService';
import ModalPatchServiceSh from './modalPatchServiceSh/ModalPatchServiceSh';
import PaginationRow from './PaginationRow';
import SearchAddContainer from './SearchAddContainer';

interface ServicesTableProps {
  showProjectColumn?: boolean;
  servicesData: Service[];
  servicesPagination: {
    count: number;
    pageLength: number;
    page: number;
    onPageChange: (newPage: number) => void;
    onPageLengthChange: (newPageLength: number) => void;
  };
  servicesSearchValue?: string;
  onServicesSearchValueChange: (value: string) => void;
  servicesDateExpiryFilter?: string;
  onServicesDateExpiryFilterChange: (value: string | null) => void;
  servicesShData: CustomServiceShMap[];
  servicesShPagination: {
    count: number;
    pageLength: number;
    page: number;
    onPageChange: (newPage: number) => void;
    onPageLengthChange: (newPageLength: number) => void;
  };
  servicesShSearchValue?: string;
  onServicesShSearchValueChange: (value: string) => void;
  servicesShDateExpiryFilter?: string;
  onServicesShDateExpiryFilterChange: (value: string | null) => void;
  servicesShProjectsAssociatedFilter?: string;
  servicesFooter?: { rinnovo: string | 0; miroir: string | 0 };
  servicesShFooter?: { rinnovo: string | 0; miroir: string | 0 };
  hideProfitColumn?: boolean;
  hideAddServicesButtons?: boolean;
  hideToPayColumn?: boolean;
  hideToRenewColumn?: boolean;
  hideFilterAssociatedFilter: boolean;
  hideToNotifyColumn?: boolean;
  loadingServicesBool?: boolean;
  loadingServicesShBool?: boolean;
  errorServices?: boolean;
  errorServicesSh?: boolean;
  // Sorting
  sortStatusService: DataTableSortStatus<Service>;
  setSortStatusService: React.Dispatch<
    React.SetStateAction<DataTableSortStatus<Service>>
  >;
  sortStatusServiceSh: DataTableSortStatus<CustomServiceShMap>;
  setSortStatusServiceSh: React.Dispatch<
    React.SetStateAction<DataTableSortStatus<CustomServiceShMap>>
  >;
}

function ServicesTables({
  servicesData,
  servicesPagination,
  servicesSearchValue,
  onServicesSearchValueChange,
  servicesDateExpiryFilter,
  onServicesDateExpiryFilterChange,
  servicesShData,
  servicesShPagination,
  servicesShSearchValue,
  onServicesShSearchValueChange,
  servicesShDateExpiryFilter,
  onServicesShDateExpiryFilterChange,
  hideFilterAssociatedFilter,
  hideProfitColumn,
  hideAddServicesButtons,
  hideToRenewColumn,
  hideToNotifyColumn,
  loadingServicesBool,
  loadingServicesShBool,
  errorServices,
  hideToPayColumn,
  errorServicesSh,
  servicesFooter,
  servicesShFooter,
  sortStatusService,
  setSortStatusService,
  sortStatusServiceSh,
  setSortStatusServiceSh,
}: ServicesTableProps) {
  // ==========================================================================
  // General
  // ==========================================================================

  // ==========================================================================
  // State
  // ==========================================================================

  const [importServicesProgress, setImportServicesProgress] = useState(0);
  const theme = useMantineTheme();
  // ==========================================================================
  // Api
  // ==========================================================================
  const {
    data: jobsStatus = {
      websiteScanner: {
        failed: [],
        completed: [],
        inProgress: [],
      },
      servicesImporter: {
        failed: [],
        completed: [],
        inProgress: [],
      },
    },
  } = useGetJobsStatusQuery();

  const [serviceShDelete, { isLoading: deleteShLoading }] =
    useDeleteServicesShMutation();

  const [
    startImportServicesJob,
    { isLoading: isLoadingStartImportServicesJob },
  ] = useStartImportServicesJobMutation();

  const [patchServiceSh] = usePatchServicesShMutation();

  const changeServiceShToRenew = async (id: number, toRenew: boolean) => {
    try {
      await patchServiceSh({
        id,
        body: { toRenew },
      }).unwrap();
    } catch (e) {
      console.error(e);
      showNotification({
        color: 'red',
        title: 'Errore',
        message: 'Impossibile attivare il servizio',
      });
    }
  };

  const changeServiceShToNotify = async (id: number, toNotify: boolean) => {
    try {
      await patchServiceSh({
        id,
        body: { toNotify },
      }).unwrap();
    } catch (e) {
      console.error(e);
      showNotification({
        color: 'red',
        title: 'Errore',
        message: 'Impossibile attivare il servizio',
      });
    }
  };

  const now = new Date();

  const [serviceDelete, { isLoading: deleteLoading }] =
    useDeleteServiceMutation();

  const [patchService] = usePatchServiceMutation();

  const changeServiceToRenew = async (id: number, toRenew: boolean) => {
    try {
      await patchService({
        id,
        body: { toRenew },
      }).unwrap();
    } catch (e) {
      console.error(e);
      showNotification({
        color: 'red',
        title: 'Errore',
        message: 'Impossibile attivare il servizio',
      });
    }
  };

  const changeServiceToNotify = async (id: number, toNotify: boolean) => {
    try {
      await patchServiceSh({
        id,
        body: { toNotify },
      }).unwrap();
    } catch (e) {
      console.error(e);
      showNotification({
        color: 'red',
        title: 'Errore',
        message: 'Impossibile attivare il servizio',
      });
    }
  };

  // ==========================================================================
  // Handlers
  // ==========================================================================

  const onStartImportClick = async () => {
    try {
      await startImportServicesJob().unwrap();
    } catch {
      showNotification({
        color: 'red',
        title: 'Errore job',
        message: 'Un altro job è già in elaborazione',
      });
    }
  };

  const onEditServiceClick = async (service: Service) => {
    openModal({
      title: 'Modifica servizio',
      children: <ModalPatchService service={service} />,
    });
  };

  const onEditServiceShClick = async (serviceSh: ServiceSh) => {
    openModal({
      title: 'Modifica servizio',
      children: <ModalPatchServiceSh serviceSh={serviceSh} />,
    });
  };

  // Progress socket
  useEffect(() => {
    const onImportProgress = (data: {
      id: number;
      category: string;
      progress: number;
    }) => {
      if (data.category === 'servicesImporter') {
        setImportServicesProgress(data.progress);
      }
    };

    // Shellrent import progress
    socket.on('job_progress', onImportProgress);

    return () => {
      socket.off('job_progress', onImportProgress);
    };
  }, []);

  // ==========================================================================
  // Render
  // ==========================================================================
  const totalPagesService = Math.ceil(
    servicesPagination.count / servicesPagination.pageLength,
  );

  const totalPagesSh = Math.ceil(
    servicesShPagination.count / servicesShPagination.pageLength,
  );

  const dateExpiryFilter = (
    <Select
      value={servicesDateExpiryFilter}
      onChange={onServicesDateExpiryFilterChange}
      placeholder="Filtro scadenza"
      data={[
        { label: 'Tutti i servizi', value: 'all' },
        { label: 'Servizi scaduti', value: 'expired' },
        { label: 'Servizi non scaduti', value: 'notExpired' },
        { label: 'Servizi in scadenza', value: 'almostExpired' },
      ]}
      allowDeselect={false}
    />
  );

  const dateExpiryShFilter = (
    <Select
      value={servicesShDateExpiryFilter}
      onChange={onServicesShDateExpiryFilterChange}
      placeholder="Filtro scadenza"
      data={[
        { label: 'Tutti i servizi', value: 'all' },
        { label: 'Servizi scaduti', value: 'expired' },
        { label: 'Servizi non scaduti', value: 'notExpired' },
        { label: 'Servizi in scadenza', value: 'almostExpired' },
      ]}
      allowDeselect={false}
    />
  );

  return (
    <>
      {/* Mantine Datatable ServiceSH*/}
      <SearchAddContainer
        title={`Servizi Shellrent (${servicesShPagination.count})`}
        additionalFilters={
          hideFilterAssociatedFilter ? [dateExpiryShFilter] : dateExpiryShFilter
        }
        searchPlaceholder="Ricerca per nome"
        searchValue={servicesShSearchValue}
        onSearchChange={onServicesShSearchValueChange}
        rightSection={
          hideAddServicesButtons ? undefined : (
            <>
              {jobsStatus.servicesImporter.completed.length > 0 && (
                <Button
                  to={`/servizi/import/${jobsStatus.servicesImporter.completed[0]}`}
                  component={Link}
                >
                  Gestisci importazione
                </Button>
              )}
              {jobsStatus.servicesImporter.failed.length > 0 && (
                <Box
                  bg="#3b2326"
                  py={rem('0.35rem')}
                  px={rem('1rem')}
                  style={{ borderRadius: theme.radius?.md }}
                >
                  <Group>Importazione fallita</Group>
                </Box>
              )}

              <Button
                onClick={onStartImportClick}
                loading={
                  isLoadingStartImportServicesJob ||
                  jobsStatus.servicesImporter.inProgress.length > 0 ||
                  false
                }
                loaderProps={
                  jobsStatus.servicesImporter.inProgress.length > 0 &&
                  importServicesProgress > 0 &&
                  importServicesProgress < 100
                    ? {
                        children: `${importServicesProgress}%`,
                      }
                    : undefined
                }
              >
                {jobsStatus.servicesImporter.failed.length > 0
                  ? 'Avvia nuova importazione'
                  : 'Avvia importazione'}
              </Button>
            </>
          )
        }
      >
        <DataTable
          withRowBorders
          striped
          minHeight={setMinHeightTable(servicesShData)}
          styles={{
            root: {
              borderRadius: theme.radius.md,
              boxShadow: theme.shadows.lg,
            },
            header: {
              backgroundColor: '#1e2023',
            },
          }}
          columns={[
            {
              accessor: 'toRenew',
              title: 'Da rinnovare',
              hidden: hideToRenewColumn,
              render: (record: CustomServiceShMap) => (
                <Group>
                  {record.projects.length === 0 && (
                    <Tooltip label="Il servizio non é associato a nessun progetto o cliente">
                      <Group>
                        <IconAlertCircle color="#c00404" />
                      </Group>
                    </Tooltip>
                  )}
                  <Switch
                    checked={record.toRenew}
                    onChange={(e) =>
                      changeServiceShToRenew(record.id, e.currentTarget.checked)
                    }
                    style={{ justifyContent: 'center' }}
                  />
                </Group>
              ),
            },
            {
              accessor: 'toNotify',
              title: 'Da notificare',
              hidden: hideToNotifyColumn,
              render: (record: CustomServiceShMap) => (
                <Switch
                  checked={record.toNotify}
                  onChange={(e) =>
                    changeServiceShToNotify(record.id, e.currentTarget.checked)
                  }
                  style={{ justifyContent: 'center' }}
                />
              ),
            },
            {
              accessor: 'shellrentId',
              title: 'Id Sh',
              sortable: true,
              footer: servicesShFooter && (
                <Text style={{ fontWeight: 'bold' }}>Totali:</Text>
              ),
            },
            {
              accessor: 'name',
              title: 'Nome',
              sortable: true,
              render: (record: CustomServiceShMap) => (
                <Group justify="space-between">
                  {record.name}
                  {record.secondaryServicesMapped &&
                    record.secondaryServicesMapped.length > 0 && (
                      <IconCircleDashedNumber2 />
                    )}
                </Group>
              ),
            },
            {
              accessor: 'dateExpiry',
              title: 'Scadenza',
              sortable: true,
              render: (record: CustomServiceShMap) => {
                const dateExpiry = new Date(record.dateExpiry);
                return (
                  <Group gap="xs">
                    {(dateExpiry < now ||
                      dateMinusPlusDays(dateExpiry, -30) < now) && (
                      <IconCircleFilled
                        style={{
                          color:
                            dateExpiry < now
                              ? '#a12828'
                              : dateMinusPlusDays(dateExpiry, -30) < now
                                ? '#cc8a21'
                                : undefined,
                          height: rem('1rem'),
                          width: rem('1rem'),
                        }}
                      />
                    )}
                    {dateToDateString(dateExpiry)}
                  </Group>
                );
              },
            },
            {
              accessor: 'renewPrice',
              title: '€ Rinnovo',
              render: (record: CustomServiceShMap) => (
                <>€{numberToCurrency(record.renewPrice)}</>
              ),
              footer: servicesShFooter && (
                <Text fw={700}>
                  € {servicesShFooter && servicesShFooter.rinnovo}
                </Text>
              ),
            },
            {
              accessor: 'renewPriceMarkup',
              title: '€ Miroir',
              render: (record: CustomServiceShMap) => {
                const renewPriceMarkup = record.projects.reduce(
                  (acc, project) => acc + project.renewPriceMarkup,
                  0,
                );
                return (
                  <>
                    €
                    {numberToCurrency(
                      record.renewPriceMarkup || renewPriceMarkup,
                    )}
                  </>
                );
              },
              footer: servicesShFooter && (
                <Text fw={700}>€ {servicesShFooter?.miroir}</Text>
              ),
            },
            {
              accessor: 'Guadagno',
              title: 'Guadagno',
              hidden: hideProfitColumn,
              render: (record: CustomServiceShMap) => {
                const renewPriceMarkup = record.projects.reduce(
                  (acc, project) => acc + project.renewPriceMarkup,
                  0,
                );

                return (
                  <>
                    €
                    {numberToCurrency(
                      (record.renewPriceMarkup || renewPriceMarkup) -
                        record.renewPrice,
                    )}
                  </>
                );
              },
            },
            {
              accessor: 'toPay',
              title: 'Stato pagamento',
              hidden: hideToPayColumn,
              render: (record: CustomServiceShMap) =>
                record.toPay ? (
                  <Badge variant="filled" color="#c00404" radius="sm">
                    Non pagato
                  </Badge>
                ) : (
                  <Badge variant="filled" color="green" radius="sm">
                    Pagato
                  </Badge>
                ),
            },
            {
              accessor: 'Progetti',
              title: 'Progetti',
              render: (record: CustomServiceShMap) => record.projects.length,
            },
            {
              accessor: 'actions',
              title: '',
              render: (record: CustomServiceShMap) => (
                <Group justify="flex-end">
                  <ActionIcon
                    title="Modifica"
                    onClick={() => onEditServiceShClick(record)}
                  >
                    <IconPencil />
                  </ActionIcon>
                  <ActionIcon
                    title="Elimina"
                    loading={deleteShLoading}
                    onClick={async () => {
                      openConfirmModal({
                        title: 'Eliminazione servizio',
                        size: 'lg',
                        children: (
                          <Text>
                            Stai per eliminare il servizio {record.name}. Sicuro
                            di voler continuare?
                          </Text>
                        ),
                        labels: {
                          confirm: 'Conferma eliminazione',
                          cancel: 'Annulla',
                        },
                        confirmProps: { color: '#c00404' },
                        onConfirm: async () => {
                          try {
                            await serviceShDelete({
                              id: record.id,
                            }).unwrap();
                            showNotification({
                              title: 'Servizio eliminato',
                              message:
                                "L'eliminazione del servizio è avvenuta con successo",
                            });
                          } catch (e) {
                            console.error(e);
                            if (isApiError(e)) {
                              showNotification({
                                color: 'red',
                                title: 'Errore',
                                message: `${e.data.message}`,
                              });
                            }
                          }
                        },
                      });
                    }}
                  >
                    <IconTrash />
                  </ActionIcon>
                </Group>
              ),
            },
          ]}
          records={servicesShData}
          fetching={loadingServicesShBool}
          rowExpansion={{
            allowMultiple: true,
            expandable: ({ record }: { record: CustomServiceShMap }) =>
              record.secondaryServicesMapped &&
              record.secondaryServicesMapped.length > 0,
            content: ({ record }) => (
              <Container p="md" size="100%">
                <DataTable
                  withRowBorders
                  withTableBorder
                  striped
                  styles={{
                    root: {
                      borderRadius: theme.radius.md,
                    },
                  }}
                  records={
                    record.secondaryServicesMapped as CustomSecondaryServiceMap[]
                  }
                  columns={[
                    {
                      accessor: 'toRenew',
                      title: 'Da rinnovare',
                      hidden: hideToRenewColumn,
                      render: (record: CustomSecondaryServiceMap) => (
                        <Group>
                          {record.projects.length === 0 && (
                            <Tooltip label="Il servizio non é associato a nessun progetto o cliente">
                              <Group>
                                <IconAlertCircle color="#c00404" />
                              </Group>
                            </Tooltip>
                          )}
                          <Switch
                            checked={record.toRenew}
                            onChange={(e) =>
                              changeServiceShToRenew(
                                record.id,
                                e.currentTarget.checked,
                              )
                            }
                            style={{ justifyContent: 'center' }}
                          />
                        </Group>
                      ),
                    },
                    {
                      accessor: 'toNotify',
                      title: 'Da notificare',
                      hidden: hideToNotifyColumn,
                      render: (record: CustomSecondaryServiceMap) => (
                        <Switch
                          checked={record.toNotify}
                          style={{ justifyContent: 'center' }}
                        />
                      ),
                    },
                    { accessor: 'id', title: 'Id Sh' },
                    { accessor: 'name', title: 'Nome' },
                    {
                      accessor: 'dateExpiry',
                      title: 'Scadenza',

                      render: (record: CustomSecondaryServiceMap) => {
                        const dateExpiry = new Date(record.dateExpiry);

                        return (
                          <Group gap="xs">
                            {(dateExpiry < now ||
                              dateMinusPlusDays(dateExpiry, -30) < now) && (
                              <IconCircleFilled
                                style={{
                                  color:
                                    dateExpiry < now
                                      ? '#a12828'
                                      : dateMinusPlusDays(dateExpiry, -30) < now
                                        ? '#cc8a21'
                                        : undefined,
                                  height: rem('1rem'),
                                  width: rem('1rem'),
                                }}
                              />
                            )}
                            {dateToDateString(dateExpiry)}
                          </Group>
                        );
                      },
                    },
                    {
                      accessor: 'renewPrice',
                      title: '€ Rinnovo',
                      render: (record: CustomSecondaryServiceMap) => (
                        <>€{numberToCurrency(record.renewPrice)}</>
                      ),
                    },
                    {
                      accessor: 'renewPriceMarkup',
                      title: '€ Miroir',
                      render: (record: CustomSecondaryServiceMap) => {
                        const renewPriceMarkup = record.projects.reduce(
                          (acc, project) => acc + project.renewPriceMarkup,
                          0,
                        );
                        return (
                          <>
                            €
                            {numberToCurrency(
                              record.renewPriceMarkup || renewPriceMarkup,
                            )}
                          </>
                        );
                      },
                    },
                    {
                      accessor: 'Guadagno',
                      title: 'Guadagno',
                      hidden: hideProfitColumn,
                      render: (record: CustomSecondaryServiceMap) => {
                        const renewPriceMarkup = record.projects.reduce(
                          (acc, project) => acc + project.renewPriceMarkup,
                          0,
                        );

                        return (
                          <>
                            €
                            {numberToCurrency(
                              (record.renewPriceMarkup || renewPriceMarkup) -
                                record.renewPrice,
                            )}
                          </>
                        );
                      },
                    },
                    {
                      accessor: 'Progetti',
                      title: 'Progetti',
                      render: (record: CustomSecondaryServiceMap) =>
                        record.projects.length,
                    },
                    {
                      accessor: 'actions',
                      title: '',
                      render: (record: CustomSecondaryServiceMap) => (
                        <Group justify="flex-end">
                          <ActionIcon
                            title="Modifica"
                            onClick={() => onEditServiceShClick(record)}
                          >
                            <IconPencil />
                          </ActionIcon>
                          <ActionIcon
                            title="Elimina"
                            loading={deleteShLoading}
                            onClick={async () => {
                              openConfirmModal({
                                title: 'Eliminazione servizio',
                                size: 'lg',
                                children: (
                                  <Text>
                                    Stai per eliminare il servizio {record.name}
                                    . Sicuro di voler continuare?
                                  </Text>
                                ),
                                labels: {
                                  confirm: 'Conferma eliminazione',
                                  cancel: 'Annulla',
                                },
                                confirmProps: { color: '#c00404' },
                                onConfirm: async () => {
                                  try {
                                    await serviceShDelete({
                                      id: record.id,
                                    }).unwrap();
                                    showNotification({
                                      title: 'Servizio eliminato',
                                      message:
                                        "L'eliminazione del servizio è avvenuta con successo",
                                    });
                                  } catch (e) {
                                    console.error(e);
                                    if (isApiError(e)) {
                                      showNotification({
                                        color: 'red',
                                        title: 'Errore',
                                        message: `${e.data.message}`,
                                      });
                                    }
                                  }
                                },
                              });
                            }}
                          >
                            <IconTrash />
                          </ActionIcon>
                        </Group>
                      ),
                    },
                  ]}
                ></DataTable>
              </Container>
            ),
          }}
          sortStatus={sortStatusServiceSh}
          onSortStatusChange={setSortStatusServiceSh}
          noRecordsText={
            errorServicesSh
              ? 'Errore. Ricaricare la pagina'
              : 'Nessun servizio trovato'
          }
        ></DataTable>
        {servicesShData.length > 0 && (
          <PaginationRow
            searchParamsKey="Sh"
            page={servicesShPagination.page}
            pageLength={servicesShPagination.pageLength}
            totalPages={totalPagesSh}
            onPageChange={servicesShPagination.onPageChange}
            onPageLengthChange={servicesShPagination.onPageLengthChange}
          />
        )}
      </SearchAddContainer>

      <Space h={'xl'} />

      {/* Mantine Datatable Service*/}
      <SearchAddContainer
        title={`Servizi (${servicesPagination.count})`}
        additionalFilters={dateExpiryFilter}
        searchPlaceholder="Ricerca per nome"
        searchValue={servicesSearchValue}
        onSearchChange={onServicesSearchValueChange}
        onActionButtonClick={
          hideAddServicesButtons
            ? undefined
            : () =>
                openModal({
                  title: 'Inserisci nuovo servizio',
                  children: <ModalAddService />,
                })
        }
      >
        {/* Mantine Datatable Service */}
        <DataTable
          minHeight={setMinHeightTable(servicesData)}
          withRowBorders
          striped
          styles={{
            root: {
              borderRadius: theme.radius.md,
              boxShadow: theme.shadows.lg,
            },
            header: {
              backgroundColor: '#1e2023',
            },
          }}
          columns={[
            {
              accessor: 'toRenew',
              title: 'Da rinnovare',

              hidden: hideToRenewColumn,
              render: (record: Service) => (
                <Switch
                  checked={record.toRenew}
                  onChange={(e) =>
                    changeServiceToRenew(record.id, e.currentTarget.checked)
                  }
                  style={{ justifyContent: 'center' }}
                />
              ),
            },
            {
              accessor: 'toNotify',
              title: 'Da notificare',
              hidden: hideToNotifyColumn,
              render: (record: Service) => (
                <Switch
                  checked={record.toNotify}
                  onChange={(e) =>
                    changeServiceToNotify(record.id, e.currentTarget.checked)
                  }
                  style={{ justifyContent: 'center' }}
                />
              ),
            },
            {
              accessor: 'name',
              title: 'Nome',
              sortable: true,
              footer: servicesFooter && servicesData.length > 0 && (
                <Text style={{ fontWeight: 'bold' }}>Totali:</Text>
              ),
            },
            {
              accessor: 'dateExpiry',
              title: 'Scadenza',
              sortable: true,
              render: (record: Service) => {
                const dateExpiry = new Date(record.dateExpiry);
                return (
                  <Group gap="xs">
                    {(dateExpiry < now ||
                      dateMinusPlusDays(dateExpiry, -30) < now) && (
                      <IconCircleFilled
                        style={{
                          color:
                            dateExpiry < now
                              ? '#a12828'
                              : dateMinusPlusDays(dateExpiry, -30) < now
                                ? '#cc8a21'
                                : undefined,
                          height: rem('1rem'),
                          width: rem('1rem'),
                        }}
                      />
                    )}
                    {dateToDateString(dateExpiry)}
                  </Group>
                );
              },
            },
            {
              accessor: 'renewPrice',
              title: '€ Rinnovo',
              render: (record: Service) => (
                <>€{numberToCurrency(record.renewPrice)}</>
              ),
              footer: servicesFooter && (
                <Text fw={700}>€ {servicesFooter?.rinnovo}</Text>
              ),
            },
            {
              accessor: 'renewPriceMarkup',
              title: '€ Miroir',
              render: (record: Service) => {
                const renewPriceMarkup = record.projects.reduce(
                  (acc, project) => acc + project.renewPriceMarkup,
                  0,
                );
                return (
                  <>
                    €
                    {numberToCurrency(
                      record.renewPriceMarkup || renewPriceMarkup,
                    )}
                  </>
                );
              },
              footer: servicesFooter && (
                <Text fw={700}>€ {servicesFooter?.miroir}</Text>
              ),
            },
            {
              accessor: 'Guadagno',
              title: 'Guadagno',
              hidden: hideProfitColumn,
              render: (record: Service) => {
                const renewPriceMarkup = record.projects.reduce(
                  (acc, project) => acc + project.renewPriceMarkup,
                  0,
                );

                return (
                  <>
                    €
                    {numberToCurrency(
                      (record.renewPriceMarkup || renewPriceMarkup) -
                        record.renewPrice,
                    )}
                  </>
                );
              },
            },
            {
              accessor: 'toPay',
              title: 'Stato pagamento',
              hidden: hideToPayColumn,
              render: (record: Service) =>
                record.toPay ? (
                  <Badge variant="filled" color="#c00404" radius="sm">
                    Non pagato
                  </Badge>
                ) : (
                  <Badge variant="filled" color="green" radius="sm">
                    Pagato
                  </Badge>
                ),
            },
            {
              accessor: 'Progetti',
              title: 'Progetti',
              render: (record: Service) => record.projects.length,
            },
            {
              accessor: 'actions',
              title: '',
              render: (record: Service) => (
                <Group justify="flex-end">
                  <ActionIcon title="Modifica">
                    <IconPencil onClick={() => onEditServiceClick(record)} />
                  </ActionIcon>
                  <ActionIcon
                    title="Elimina"
                    loading={deleteLoading}
                    onClick={() => {
                      openConfirmModal({
                        title: 'Eliminazione servizio',
                        size: 'lg',
                        children: (
                          <Text>
                            Stai per eliminare il servizio {record.name}. Sicuro
                            di voler continuare?
                          </Text>
                        ),
                        labels: {
                          confirm: 'Conferma eliminazione',
                          cancel: 'Annulla',
                        },
                        confirmProps: { color: 'red' },
                        onConfirm: async () => {
                          try {
                            await serviceDelete({ id: record.id }).unwrap();
                            showNotification({
                              title: 'Servizio eliminato',
                              message:
                                "L'eliminazione del servizio è avvenuta con successo",
                            });
                          } catch (e) {
                            console.error(e);
                            if (isApiError(e)) {
                              showNotification({
                                color: 'red',
                                title: 'Errore',
                                message: `${e.data.message}`,
                              });
                            }
                          }
                        },
                      });
                    }}
                  >
                    <IconTrash />
                  </ActionIcon>
                </Group>
              ),
            },
          ]}
          records={servicesData}
          fetching={loadingServicesBool}
          noRecordsText={
            errorServices
              ? 'Errore. Ricaricare la pagina'
              : 'Nessun servizio trovato'
          }
          sortStatus={sortStatusService}
          onSortStatusChange={setSortStatusService}
        ></DataTable>
        {servicesData.length > 0 && (
          <PaginationRow
            page={servicesPagination.page}
            pageLength={servicesPagination.pageLength}
            totalPages={totalPagesService}
            onPageChange={servicesPagination.onPageChange}
            onPageLengthChange={servicesPagination.onPageLengthChange}
          />
        )}
      </SearchAddContainer>
    </>
  );
}

export default ServicesTables;
