import { FC, useEffect, useRef, useState } from 'react';
import AvatarEditor from 'react-avatar-editor';

import {
  Avatar,
  Box,
  Button,
  FileButton,
  Group,
  rem,
  Slider,
} from '@mantine/core';
import { closeModal } from '@mantine/modals';
import { IconUpload, IconZoomIn, IconZoomOut } from '@tabler/icons-react';

import useAuth from '@hooks/useAuth';

const ModalPatchUserProfilePicture: FC = () => {
  // ==========================================================================
  // General
  // ==========================================================================
  const { user, patchProfilePicture } = useAuth();

  // ==========================================================================
  // State
  // ==========================================================================
  const [profilePicture, setProfilePicture] = useState<File | null>(null);
  const [croppedProfilePicture, setCroppedProfilePicture] =
    useState<HTMLCanvasElement | null>(null);
  const [profilePictureScale, setProfilePictureScale] = useState(1);

  const avatarEditorRef = useRef<AvatarEditor>(null);

  // ==========================================================================
  // Api
  // ==========================================================================

  // ==========================================================================
  // Form
  // ==========================================================================

  // ==========================================================================
  // Handlers
  // ==========================================================================
  const handleRefreshImage = () => {
    setCroppedProfilePicture(
      avatarEditorRef.current?.getImageScaledToCanvas() || null,
    );
  };

  useEffect(handleRefreshImage, [profilePictureScale]);

  const onConfirmed = async () => {
    if (profilePicture) {
      const blob = await new Promise<Blob>((res) =>
        croppedProfilePicture!.toBlob((blob) => res(blob!)),
      );

      const data = new FormData();

      data.append('picture', blob, profilePicture.name);

      await patchProfilePicture(data);
    }

    closeModal('profilePictureEditor');
  };

  // ==========================================================================
  // Render
  // ==========================================================================
  return (
    <>
      {profilePicture && (
        <Box mb="xl">
          <AvatarEditor
            ref={avatarEditorRef}
            image={profilePicture}
            borderRadius={100}
            style={{
              width: '100%',
              height: 'auto',
              aspectRatio: '1 / 1',
              borderRadius: '15px',
            }}
            scale={profilePictureScale}
            onImageReady={handleRefreshImage}
            onPositionChange={handleRefreshImage}
          />
          <Group mt="md">
            <IconZoomOut />
            <Slider
              onChange={setProfilePictureScale}
              min={1}
              max={2}
              label={null}
              step={0.01}
              style={{ flexGrow: 1 }}
            />
            <IconZoomIn />
          </Group>
        </Box>
      )}
      <Group justify="center">
        <Avatar
          size={rem('10rem')}
          radius={rem('10rem')}
          src={croppedProfilePicture?.toDataURL() || user!.profilePicture}
        >
          {user!.name![0].toUpperCase()}
          {user!.surname![0].toUpperCase()}
        </Avatar>

        <FileButton onChange={setProfilePicture} accept="image/png,image/jpeg">
          {(props) => (
            <Button {...props} variant="default" leftSection={<IconUpload />}>
              Carica immagine
            </Button>
          )}
        </FileButton>
      </Group>
      <Group justify="flex-end" mt="lg">
        <Button
          variant="default"
          onClick={() => closeModal('profilePictureEditor')}
        >
          Annulla
        </Button>
        <Button onClick={onConfirmed}>Conferma</Button>
      </Group>
    </>
  );
};

export default ModalPatchUserProfilePicture;
