import api from '../';

type CronType = 'websiteScannerScan';

interface Cron {
  type: CronType;
  cronTime: string[];
  active: boolean;
}

export const { useGetCronQuery, useUpsertCronMutation } = api.injectEndpoints({
  endpoints: (builder) => ({
    getCron: builder.query<Cron, CronType>({
      query: (type) => ({
        url: 'cron',
        params: { type },
      }),
      providesTags: ['cron'],
    }),
    upsertCron: builder.mutation<Cron, Cron>({
      query: (body) => ({
        url: 'cron',
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['cron'],
    }),
  }),
});
