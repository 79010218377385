import { DataTable, DataTableSortStatus } from 'mantine-datatable';
import { FC, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import {
  ActionIcon,
  Button,
  Group,
  Select,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { openConfirmModal } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { IconTrash } from '@tabler/icons-react';
import { dateToDateHourseString } from '@utils/date';
import { setMinHeightTable } from '@utils/general';

import socket from '@lib/socket';

import { isApiError } from '@api/index';
import {
  CategoryFitler as CategoryFilter,
  Job,
  JobApiQueryParams,
  JobsStatus,
  SortBy,
  StatusFilter,
  useDeleteJobMutation,
  useGetJobsCountQuery,
  useGetJobsQuery,
} from '@api/jobs/jobs.api';
import { generateDataJobs } from '@api/jobs/jobs.mock';

import useLayoutProps from '@components/layout/useLayoutProps';
import PaginationRow from '@components/PaginationRow';
import SearchAddContainer from '@components/SearchAddContainer';

const Jobs: FC = () => {
  // ==========================================================================
  // General
  // ==========================================================================
  const [searchParams, setSearchParams] = useSearchParams();
  const theme = useMantineTheme();
  const navigate = useNavigate();

  const [progressData, setProgressData] = useState<Record<number, number>>({});

  useLayoutProps({ title: 'Jobs' });

  // Notification socket
  useEffect(() => {
    const onImportProgress = (data: {
      id: number;
      name: string;
      progress: number;
    }) => {
      setProgressData((progressData) => ({
        ...progressData,
        [data.id]: data.progress,
      }));
    };

    // Shellrent import progress
    socket.on('job_progress', onImportProgress);

    return () => {
      socket.off('job_progress', onImportProgress);
    };
  }, [navigate]);

  // ==========================================================================
  // State
  // ==========================================================================

  const [filters, setFilters] = useState<JobApiQueryParams>({
    page: +(searchParams.get('page') || 1),
    pageLength: +(searchParams.get('pageLength') || 50),
    searchQuery: '',
    status: searchParams.get('status')
      ? (searchParams.get('status') as StatusFilter)
      : undefined,
    category: searchParams.get('category')
      ? (searchParams.get('category') as CategoryFilter)
      : undefined,
  });

  const [searchQuery] = useDebouncedValue(filters.searchQuery, 200, {
    leading: true,
  });

  const [sortStatus, setSortStatus] = useState<DataTableSortStatus<Job>>({
    columnAccessor: 'createdAt',
    direction: 'desc',
  });

  useEffect(() => {
    if (sortStatus) {
      setFilters((f: JobApiQueryParams) => ({
        ...f,
        sortBy: sortStatus.columnAccessor as SortBy,
        sortOrder: sortStatus.direction,
      }));
    }
  }, [sortStatus]);

  // ==========================================================================
  // Api
  // ==========================================================================

  const { data: jobsCount = { count: 0 }, isLoading: isLoadingJobsCount } =
    useGetJobsCountQuery({
      category: filters.category,
      status: filters.status,
      searchQuery,
    });

  const {
    data: jobs = generateDataJobs(10),
    isLoading: isLoadingJobs,
    error: errorJobs,
  } = useGetJobsQuery(
    {
      ...filters,
      searchQuery: searchQuery,
    },
    { refetchOnMountOrArgChange: true },
  );

  const nameByCategory = (category: string): string => {
    let btnLabel: string;
    switch (category) {
      case 'servicesImporter':
        btnLabel = 'Importa';
        break;
      default:
        btnLabel = 'Dettagli';
        break;
    }

    return btnLabel;
  };

  const mapCategory = (category: string): string => {
    switch (category) {
      case 'servicesImporter':
        return 'Importazione servizi Shellrent';
      case 'websiteScanner':
        return 'Scansione siti web';
      default:
        return '';
    }
  };

  const mapStatus = (status: JobsStatus): string => {
    switch (status) {
      case 'completed':
        return 'Completato';
      case 'inProgress':
        return 'In elaborazione';
      case 'failed':
        return 'Fallito';
    }

    return status;
  };

  const [jobDelete, { isLoading: isLoadingDeleteJob }] = useDeleteJobMutation();

  const onJobsStatusChange = (value: string | null) => {
    if (value !== null) {
      const statusValue = value as StatusFilter;
      searchParams.set('status', statusValue);
      setSearchParams(searchParams);
      setFilters({
        ...filters,
        status: statusValue,
      });
    }
  };

  const onJobsCategoryChange = (value: string | null) => {
    const categoryValue = value as CategoryFilter;
    searchParams.set('category', categoryValue);
    setSearchParams(searchParams);
    setFilters({
      ...filters,
      category: categoryValue,
    });
  };

  const jobsStatusFilter = filters.status?.toString();
  const jobsCategoryFilter = filters.category?.toString();

  const statusFilter = (
    <Select
      key="status filter"
      value={jobsStatusFilter}
      onChange={onJobsStatusChange}
      placeholder="Filtro stato"
      data={[
        { label: 'Tutti i Jobs', value: 'all' },
        { label: 'Completato', value: 'completed' },
        { label: 'Fallito', value: 'failed' },
        { label: 'In elaborazione', value: 'inProgress' },
      ]}
      allowDeselect={false}
    />
  );

  const categoryFilter = (
    <Select
      key="category filter"
      value={jobsCategoryFilter}
      onChange={onJobsCategoryChange}
      placeholder="Filtro categoria"
      data={[
        { label: 'Tutti i Jobs', value: 'all' },
        {
          label: 'Importazione servizi Shellrent',
          value: 'servicesImporter',
        },
        {
          label: 'Website scanner',
          value: 'websiteScanner',
        },
      ]}
      allowDeselect={false}
    />
  );

  // ==========================================================================
  // Render
  // ==========================================================================
  const totalPages = Math.ceil(jobsCount.count / filters.pageLength!);

  return (
    <>
      <SearchAddContainer
        additionalFilters={[statusFilter, categoryFilter]}
        searchPlaceholder="Ricerca per nome"
        searchValue={filters.searchQuery}
        onSearchChange={(newValue) =>
          setFilters({ ...filters, searchQuery: newValue })
        }
      >
        <DataTable
          minHeight={setMinHeightTable(jobs)}
          withRowBorders
          striped
          styles={{
            root: {
              borderRadius: theme.radius.md,
              boxShadow: theme.shadows.lg,
            },
            header: {
              backgroundColor: '#1e2023',
            },
          }}
          records={jobs}
          columns={[
            { accessor: 'name', title: 'Nome' },
            { accessor: 'jobBullId', title: 'Id' },
            {
              accessor: 'category',
              title: 'Categoria',
              render: (record: Job) => mapCategory(record.category.toString()),
            },
            {
              accessor: 'status',
              title: 'Stato',
              render: (record: Job) =>
                mapStatus(record.status) +
                (record.status === 'inProgress' &&
                progressData[record.jobBullId]
                  ? ` (${progressData[record.jobBullId]}%)`
                  : ''),
            },
            {
              accessor: 'createdAt',
              title: 'Avviato il',
              sortable: true,
              render: (record: Job) =>
                dateToDateHourseString(new Date(record.createdAt)),
            },
            {
              accessor: 'actions',
              title: '',
              render: (record: Job) => {
                const showDetailsButton =
                  record.managed === false &&
                  record.status === 'completed' &&
                  record.category === 'servicesImporter';

                return (
                  <Group justify="flex-end">
                    {showDetailsButton ? (
                      <Button
                        onClick={() =>
                          navigate(
                            `/jobs/${record.category
                              .toString()
                              .toLowerCase()}/${record.id}`,
                          )
                        }
                      >
                        {nameByCategory(record.category.toString())}
                      </Button>
                    ) : (
                      ''
                    )}

                    <ActionIcon
                      title="Elimina"
                      loading={isLoadingDeleteJob}
                      disabled={record.status === 'inProgress'}
                      onClick={async () => {
                        openConfirmModal({
                          title: 'Eliminazione job',
                          size: 'lg',
                          children: (
                            <Text>
                              Stai per eliminare il job {record.name} in data{' '}
                              {dateToDateHourseString(
                                new Date(record.createdAt),
                              )}
                              . Sicuro di voler procedere?
                            </Text>
                          ),
                          labels: {
                            confirm: 'Conferma eliminazione',
                            cancel: 'Annulla',
                          },
                          confirmProps: { color: 'red' },
                          onConfirm: async () => {
                            try {
                              await jobDelete({ id: record.id }).unwrap();

                              showNotification({
                                title: 'Eliminazione job',
                                message:
                                  "L'eliminazione del job è avvenuta con successo",
                              });
                            } catch (e) {
                              if (isApiError(e)) {
                                if (e.status === 400) {
                                  showNotification({
                                    color: 'red',
                                    title: 'Errore',
                                    message: e.data.message,
                                  });
                                } else {
                                  showNotification({
                                    color: 'red',
                                    title: 'Errore',
                                    message:
                                      "Errore nell'eliminazione del job. Riprova più tardi",
                                  });
                                }
                              }
                            }
                          },
                        });
                      }}
                    >
                      <IconTrash />
                    </ActionIcon>
                  </Group>
                );
              },
            },
          ]}
          fetching={isLoadingJobs || isLoadingJobsCount}
          noRecordsText={
            errorJobs ? 'Errore. Ricaricare la pagina' : 'Nessun job trovato'
          }
          sortStatus={sortStatus}
          onSortStatusChange={setSortStatus}
        ></DataTable>
        {jobs.length > 0 && (
          <PaginationRow
            page={filters.page!}
            pageLength={filters.pageLength!}
            totalPages={totalPages}
            onPageChange={(newPage) =>
              setFilters({ ...filters, page: newPage })
            }
            onPageLengthChange={(newPageLength) =>
              setFilters({ ...filters, pageLength: newPageLength, page: 1 })
            }
          />
        )}
      </SearchAddContainer>
    </>
  );
};

export default Jobs;
