import { FC } from 'react';

import { ModalProps } from '@interfaces/modals.interface';
import {
  Button,
  Grid,
  NumberInput,
  Switch,
  Text,
  TextInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { closeAllModals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { handleSubmitError } from '@utils/general';

import {
  useEditWebsiteMutation,
  WebsiteScannerSite,
} from '@api/websiteScanner/websiteScanner.api';

interface ModalPatchWebsiteProps extends ModalProps {
  website: WebsiteScannerSite;
}

const ModalPatchWebsite: FC<ModalPatchWebsiteProps> = ({
  website,
  closeAllOnConfirm = true,
  onConfirmed,
}) => {
  // ==========================================================================
  // Api
  // ==========================================================================
  const [editWebsite, { isLoading }] = useEditWebsiteMutation();

  // ==========================================================================
  // Form
  // ==========================================================================
  const initialValues = {
    url: website.url,
    screenshotEnabled: website.screenshotEnabled,
    screenshotDelay: website.screenshotDelay,
    screenshotScroll: website.screenshotScroll,
  };

  const form = useForm({
    initialValues,
  });

  const onSubmit = async (values: typeof initialValues) => {
    try {
      await editWebsite({
        id: website!.id,
        body: values,
      }).unwrap();

      showNotification({
        title: 'Sito web modificato',
        message: 'Il sito web è stato modificato con successo',
      });

      onConfirmed?.();
      if (closeAllOnConfirm) {
        closeAllModals();
      }
    } catch (e) {
      handleSubmitError(e, form);
    }
  };

  // ==========================================================================
  // Render
  // ==========================================================================
  return (
    <form
      onSubmit={form.onSubmit((values) => {
        onSubmit(values);
      })}
    >
      <Grid>
        <Grid.Col span={12}>
          <TextInput required label="URL:" {...form.getInputProps('url')} />
        </Grid.Col>
        <Grid.Col span={12}>
          <Switch
            mt="lg"
            label="Scansione screenshot abilitata"
            checked={form.values.screenshotEnabled}
            onChange={(e) =>
              form.setFieldValue('screenshotEnabled', e.currentTarget.checked)
            }
          />
          {form.values.screenshotEnabled && (
            <NumberInput
              mt="sm"
              min={0}
              max={10000}
              step={500}
              required
              label="Ritardo screenshot (ms):"
              {...form.getInputProps('screenshotDelay')}
            />
          )}
        </Grid.Col>
        <Grid.Col>
          <NumberInput
            mb="xl"
            min={0}
            max={10000}
            step={500}
            required
            label="Scroll screenshot (px):"
            {...form.getInputProps('screenshotScroll')}
          />
        </Grid.Col>
        <Grid.Col span={8}>
          {form.errors.general && (
            <Text color="red" mt="md">
              {form.errors.general}
            </Text>
          )}
        </Grid.Col>
        <Grid.Col span={4}>
          <Button type="submit" fullWidth mt="md" loading={isLoading}>
            Conferma
          </Button>
        </Grid.Col>
      </Grid>
    </form>
  );
};

export default ModalPatchWebsite;
