import { SortOrder } from '@domain/types';

import api from '../';

export type NotificationsCategory = 'serviceExpiry' | 'websiteScanErrors';

export interface Notification {
  id: number;
  title: string;
  description: string;
  category: NotificationsCategory;
  link: string;
  payload: string;
  read: boolean;
  archived: boolean;
  createdAt: string;
  userId: number;
}

export type NotificationsFilter =
  | 'read'
  | 'notRead'
  | 'archived'
  | 'notArchived'
  | 'all';

export interface NotificationsApiQueryParams {
  sortOrder?: SortOrder;
  page?: number;
  pageLength?: number;
  filter?: NotificationsFilter;
}

interface NotificationsCount {
  count: number;
}

interface ModifyNotificationRequest {
  id: number;
  body: {
    read?: boolean;
    archived?: boolean;
  };
}

const notificationsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getNotificationsCount: builder.query<
      NotificationsCount,
      NotificationsFilter | undefined
    >({
      query: (filter) => ({
        url: 'notifications/count',
        params: { filter },
      }),
      providesTags: ['notifications'],
    }),
    getNotifications: builder.query<
      Notification[],
      NotificationsApiQueryParams
    >({
      query: (params) => ({
        url: '/notifications',
        params,
      }),
      providesTags: ['notifications'],
    }),
    modifyNotification: builder.mutation<
      Notification,
      ModifyNotificationRequest
    >({
      query: ({ id, body }) => ({
        url: `notifications/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['notifications'],
    }),
  }),
});

export const {
  useGetNotificationsCountQuery,
  useGetNotificationsQuery,
  useModifyNotificationMutation,
} = notificationsApi;
