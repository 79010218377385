import { Center, Container, Title, useMantineTheme } from '@mantine/core';
import { useColorScheme } from '@mantine/hooks';

import useAuth from '@hooks/useAuth';

import classes from './pageNotFound.module.css';

function PageNotFound() {
  const colorScheme = useColorScheme();
  const theme = useMantineTheme();

  const { isAuthenticated } = useAuth();

  return isAuthenticated ? (
    <>
      <Center>
        <Container className={classes.root}>
          <div
            className={classes.label}
            style={{
              color:
                colorScheme === 'dark'
                  ? theme.colors.dark[4]
                  : theme.colors.gray[2],
            }}
          >
            404
          </div>
          <Title className={classes.title}>Pagina non disponibile</Title>
        </Container>
      </Center>
    </>
  ) : (
    <Center>
      <Container className={classes.root}>
        <div
          className={classes.label}
          style={{
            color:
              colorScheme === 'dark'
                ? theme.colors.dark[4]
                : theme.colors.gray[2],
          }}
        >
          404
        </div>
        <Title className={classes.title}>Pagina non disponibile</Title>
      </Container>
    </Center>
  );
}
export default PageNotFound;
