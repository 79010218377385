import { generateMockValue } from '@utils/generateMockData';

export const generateDataClients = (count: number) => {
  return generateMockValue({
    type: 'array',
    minLength: count,
    maxLength: count,
    each: {
      type: 'object',
      values: {
        id: 'number',
        name: 'string',
        surname: 'string',
        companyName: 'string',
        email: 'string',
        vat: 'string',
        province: 'string',
        city: 'string',
        address: 'string',
        taxCode: 'string',
        pec: 'string',
        sdi: 'string',
        phone: 'string',
        notes: 'string',
        agencyId: 'number',
        agency: {
          type: 'object',
          values: {
            agencyName: 'string',
          },
        },
        type: 'ClientType',
      },
    },
  });
};
