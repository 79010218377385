import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { UserPermissions } from '@interfaces/auth.interface';

import { selectCurrentUser } from '@slices/auth.slice';

const useUserPermission = (permission: UserPermissions) => {
  const user = useSelector(selectCurrentUser);

  const hasPermission = user?.permissions.includes(permission);

  return useMemo(() => hasPermission, [hasPermission]);
};

export default useUserPermission;
