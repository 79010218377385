import { generateMockValue } from '@utils/generateMockData';

export const generateDataJobs = (count: number) => {
  return generateMockValue({
    type: 'array',
    minLength: count,
    maxLength: count,
    each: {
      type: 'object',
      values: {
        id: 'number',
        name: 'string',
        category: 'JobsCategory',
        status: 'JobsStatus',
        requestData: 'string',
        responseData: 'string',
        createdAt: 'string',
        managed: 'boolean',
        jobBullId: 'number',
      },
    },
  });
};
