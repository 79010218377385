import { FC, useState } from 'react';
import { Link, Outlet } from 'react-router-dom';

import {
  ActionIcon,
  Alert,
  Box,
  Burger,
  Button,
  Container,
  Drawer,
  Group,
  Stack,
  Text,
  Title,
  Tooltip,
  useComputedColorScheme,
  useMantineColorScheme,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import {
  IconAlertCircle,
  IconBell,
  IconChevronLeft,
  IconMoonStars,
  IconSunHigh,
} from '@tabler/icons-react';

import { useGetNotificationsCountQuery } from '@api/notifications/notifications.api';

import IconBadge from '@components/iconBadge/IconBadge';
import Sidebar from '@components/sidebar/Sidebar';

import classes from './Layout.module.css';
import { ContextType } from './useLayoutProps';

const Layout: FC = () => {
  const [opened, setOpened] = useState(false);
  const { data: notificationsCount = { count: 0 } } =
    useGetNotificationsCountQuery('notRead');
  const { toggleColorScheme } = useMantineColorScheme();
  const colorScheme = useComputedColorScheme('light');

  const isSmallerThanMd = useMediaQuery('(max-width: 768px)');

  // Context
  const [title, setTitle] = useState<string>('');
  const [backLink, setBackLink] = useState<
    | {
        title: string;
        to: string;
      }
    | undefined
  >(undefined);
  const [banner, setBanner] = useState<
    | {
        title: string;
        content: string;
      }
    | undefined
  >(undefined);
  const [loadingText, setLoadingText] = useState<string | undefined>(undefined);
  return (
    <div
      className={classes.shell}
      style={{ display: isSmallerThanMd ? 'block' : 'grid' }}
    >
      <Box visibleFrom="md">
        <Sidebar />
      </Box>

      <Box hiddenFrom="md">
        <Drawer
          color="#222222"
          opened={opened}
          onClose={() => setOpened(false)}
          style={{
            backgroundColor: '#222222',
            border: 'none',
            header: {
              marginBottom: 0,
            },
          }}
        >
          <Sidebar />
        </Drawer>
      </Box>

      <div className={classes.container}>
        <Container px="xl" fluid>
          <Group justify="space-between">
            <Group>
              <Burger
                hiddenFrom="md"
                opened={opened}
                p={0}
                onClick={() => setOpened((o) => !o)}
              />

              <Group>
                {backLink?.title && (
                  <Button
                    component={Link}
                    to={backLink.to}
                    leftSection={<IconChevronLeft />}
                  >
                    {backLink.title}
                  </Button>
                )}
                {title && <Title order={1}>{title}</Title>}
              </Group>
            </Group>
            <Group justify="space-between">
              <Tooltip label="Notifiche">
                <IconBadge
                  to="/notifiche"
                  badgeText={
                    notificationsCount.count !== 0
                      ? notificationsCount.count.toString()
                      : null
                  }
                >
                  <IconBell
                    color={colorScheme === 'light' ? '#222222' : '#C1C2C5'}
                  />
                </IconBadge>
              </Tooltip>
              <Tooltip
                label={colorScheme === 'light' ? 'Dark mode' : 'Light mode'}
              >
                <ActionIcon onClick={() => toggleColorScheme()}>
                  {colorScheme === 'light' ? (
                    <IconMoonStars color="#222222" />
                  ) : (
                    <IconSunHigh color="#C1C2C5" />
                  )}
                </ActionIcon>
              </Tooltip>
            </Group>
          </Group>
        </Container>
        <Container size={1500} p="xl" pos="relative">
          <Stack align="center">
            {loadingText && <Text>{loadingText}</Text>}
          </Stack>

          {banner?.title && (
            <Alert
              color="red"
              icon={<IconAlertCircle />}
              title={banner.title}
              mb="xl"
            >
              {banner.content}
            </Alert>
          )}

          <Outlet
            context={
              {
                setTitle,
                setBackLink,
                setBanner,
                setLoadingText,
              } satisfies ContextType
            }
          />
        </Container>
      </div>
    </div>
  );
};

export default Layout;
