import { SortOrder } from '@domain/types';

import api from '../';

interface ServiceShCount {
  count: number;
}

export type SortBySh = 'dateExpiry' | 'shellrentId' | 'name';
export type DateExpiryFilter = 'all' | 'expired' | 'notExpired';
export type ProjectsAssociatedFilter = 'all' | 'associated' | 'notAssociated';

export interface ServiceShApiQueryParams {
  sortBy?: SortBySh;
  sortOrder?: SortOrder;
  searchQuery?: string;
  page?: number;
  pageLength?: number;
  dateExpiryFilter?: DateExpiryFilter;
  projectsAssociatedFilter?: ProjectsAssociatedFilter;
}

export interface ServiceShProjectInfo {
  projectId: number;
  renewPriceMarkup: number;
  toPay: boolean;
  toNotify: boolean;
  dateExpiry: string;
  project?: { id: number; name: string; clientId: number };
}

export interface ProjectRenewPriceMarkups {
  projectId: number;
  renewPriceMarkup: number;
}

export interface ProjectToPay {
  projectId: number;
  toPay: boolean;
}

export interface ProjectToNotify {
  projectId: number;
  toNotify: boolean;
}

export interface ProjectDateExpiry {
  projectId: number;
  dateExpiry: Date | string;
}

export interface ImportServiceSh {
  jobId: number;
  services: number[];
}

export interface ServiceSh {
  id: number;
  shellrentId: number;
  name: string;
  proformNote: string | null;
  purchasePrice: number;
  renewPrice: number;
  renewPriceMarkup: number;
  toPay: boolean;
  toNotify: boolean;
  primaryService: PrimaryService;
  primaryServiceId: number | null;
  toRenew: boolean;
  dateExpiry: string;
  projects: ServiceShProjectInfo[];
}

export interface PrimaryService {
  id: number;
  name: string;
  primaryServiceId: number;
  proformNote: string | null;
  purchasePrice: number;
  renewPrice: number;
  shellrentId: number;
  toRenew: true;
  dateExpiry: string;
}

export interface ServiceShTotal {
  renewPrice: number;
  renewPriceMarkup: number;
}

interface ServiceShPatchRequest {
  id: number;
  body: {
    name?: string;
    proformNote?: string;
    purchasePrice?: number;
    renewPrice?: number;
    toRenew?: boolean;
    toNotify?: boolean;
    dateExpiry?: string;
    projectsIds?: number[];
    projectsRenewPriceMarkups?: ProjectRenewPriceMarkups[];
    projectsToPay?: ProjectToPay[];
    projectsToNotify?: ProjectToNotify[];
    projectsDateExpiry?: ProjectDateExpiry[];
  };
}

interface ServiceShDeleteRequest {
  id: number;
}

//ServiceSh==============
// export interface PServiceShApiQueryParams {
//   sortBy?: SortBySh;
//   sortOrder?: SortOrder;
//   searchQuery?: string;
//   page?: number;
//   pageLength?: number;
// }

export interface ProjectServiceShApiQueryParams {
  id: string;
  params: ServiceShApiQueryParams;
}

interface ProjectServiceShCount {
  count: number;
}

export interface ProjectServiceShCountApiQueryParams {
  id: string;
  params: ServiceShApiCountParams;
}

//cliet service
export interface ServiceShApiQueryParams {
  sortBy?: SortBySh;
  sortOrder?: SortOrder;
  searchQuery?: string;
  page?: number;
  pageLength?: number;
  dateExpiryFilter?: DateExpiryFilter;
}

export interface ServiceShApiCountParams {
  searchQuery?: string;
  dateExpiryFilter?: DateExpiryFilter;
}

export interface ClientServiceShApiQueryParams {
  id: string;
  params: ServiceShApiQueryParams;
}

interface ClientServiceShCount {
  count: number;
}

export interface ClientServiceShCountApiQueryParams {
  id: string;
  params: ServiceShApiCountParams;
}

interface GetTotalServiceShResponse {
  totalCount: number;
  toRenew: number;
  toNotRenew: number;
  renewPriceSum: number;
  renewPriceMarkupSum: number;
  expiredCount: number;
  almostExpiredCount: number;
}

interface GetNewExternalServicesShResponse {
  id: number;
  name: string;
  primaryServiceId?: string;
  primaryServiceName?: string;
}

interface GetProjectTotServiceParams {
  id: number;
  params: { dateExpiryFilter?: DateExpiryFilter };
}

interface GetClientTotServiceParams {
  id: number;
  params: { dateExpiryFilter?: DateExpiryFilter };
}

export interface CustomSecondaryServiceMap {
  id: number;
  shellrentId: number;
  name: string;
  proformNote: string | null;
  purchasePrice: number;
  renewPrice: number;
  toRenew: boolean;
  dateExpiry: string;
  createdAt: string;
  updatedAt: string;
  dataHash: string;
  projects: ServiceShProjectInfo[];
  renewPriceMarkup: number;
  toPay: boolean;
  toNotify: boolean;
  primaryService: PrimaryService;
  primaryServiceId: number | null;
}

export interface CustomServiceShMap {
  id: number;
  shellrentId: number;
  name: string;
  proformNote: string | null;
  purchasePrice: number;
  renewPrice: number;
  toRenew: boolean;
  dateExpiry: string;
  primaryServiceId: number | null;
  createdAt: string;
  updatedAt: string;
  dataHash: string;
  projects: ServiceShProjectInfo[];
  primaryService: PrimaryService;
  secondaryServicesMapped: CustomSecondaryServiceMap[];
  toPay: boolean;
  toNotify: boolean;
  renewPriceMarkup: number;
}

const servicesShApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getServicesSh: builder.query<CustomServiceShMap[], ServiceShApiQueryParams>(
      {
        query: (params) => ({
          url: 'servicessh',
          params,
        }),
        providesTags: ['servicesh'],
      },
    ),
    getServicesShCount: builder.query<ServiceShCount, ServiceShApiCountParams>({
      query: (params) => ({
        url: 'servicessh/count',
        params: params,
      }),
      providesTags: ['servicesh'],
    }),
    patchServicesSh: builder.mutation<void, ServiceShPatchRequest>({
      query: ({ id, body }) => ({
        url: `servicessh/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['servicesh'],
    }),
    deleteServicesSh: builder.mutation<string, ServiceShDeleteRequest>({
      query: ({ id }) => ({
        url: `servicessh/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['servicesh'],
    }),
    getClientTotServiceSh: builder.query<
      ServiceShTotal,
      GetClientTotServiceParams
    >({
      query: ({ id, params }) => ({
        url: `clients/${id}/servicessh/totals`,
        params,
      }),
      providesTags: ['servicesh'],
    }),
    getProjectTotServiceSh: builder.query<
      ServiceShTotal,
      GetProjectTotServiceParams
    >({
      query: ({ id, params }) => ({
        url: `projects/${id}/servicessh/totals`,
        params,
      }),
      providesTags: ['servicesh'],
    }),

    //ProjectServiceSH=======================================
    getProjectServicesSh: builder.query<
      ServiceSh[],
      ProjectServiceShApiQueryParams
    >({
      query: ({ id, params }) => ({
        url: `projects/${id}/servicessh`,
        params,
      }),
      providesTags: ['servicesh'],
    }),
    getProjectServicesShCount: builder.query<
      ProjectServiceShCount,
      ProjectServiceShCountApiQueryParams
    >({
      query: ({ id, params }) => ({
        url: `projects/${id}/servicessh/count`,
        params: params,
      }),
      providesTags: ['servicesh'],
    }),

    //Client ServiceSH=====================================
    getClientServicesSh: builder.query<
      ServiceSh[],
      ClientServiceShApiQueryParams
    >({
      query: ({ id, params }) => ({
        url: `clients/${id}/servicessh`,
        params,
      }),
      providesTags: ['servicesh'],
    }),
    getClientServicesShCount: builder.query<
      ClientServiceShCount,
      ClientServiceShCountApiQueryParams
    >({
      query: ({ id, params }) => ({
        url: `clients/${id}/servicessh/count`,
        params: params,
      }),
      providesTags: ['servicesh'],
    }),
    //total==========================
    getTotalServiceSh: builder.query<GetTotalServiceShResponse, void>({
      query: () => ({
        url: `servicessh/totals`,
      }),
      providesTags: ['servicesh'],
    }),
    startImportServicesJob: builder.mutation<
      GetNewExternalServicesShResponse[],
      void
    >({
      query: () => ({
        url: 'servicessh/new-external',
        method: 'POST',
      }),
      invalidatesTags: ['jobs'],
    }),
    importNewExternalServicesSh: builder.mutation<
      { message: string },
      ImportServiceSh
    >({
      query: (body) => ({
        url: 'servicessh/import-external',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['servicesh', 'jobs'],
    }),
  }),
});

export const {
  useGetServicesShQuery,
  useGetServicesShCountQuery,
  usePatchServicesShMutation,
  useDeleteServicesShMutation,
  useGetClientTotServiceShQuery,
  useGetProjectServicesShQuery,
  useGetProjectServicesShCountQuery,
  useGetClientServicesShQuery,
  useGetClientServicesShCountQuery,
  useGetTotalServiceShQuery,
  useStartImportServicesJobMutation,
  useImportNewExternalServicesShMutation,
  useGetProjectTotServiceShQuery,
} = servicesShApi;
