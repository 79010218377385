import api from '../';

type totalPriceResult = Record<
  number,
  { totalCost: number; totalRevenue: number } | null
>;
const statisticsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getTotalPriceHistory: builder.query<totalPriceResult, void>({
      query: () => ({
        url: 'statistics',
      }),
    }),
  }),
});

export const { useGetTotalPriceHistoryQuery } = statisticsApi;
