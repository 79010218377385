import { FC, useState } from 'react';

import {
  ActionIcon,
  Avatar,
  AvatarProps,
  Box,
  Group,
  Overlay,
  rem,
} from '@mantine/core';
import { openConfirmModal, openModal } from '@mantine/modals';
import { IconPencil, IconTrash } from '@tabler/icons-react';

import useAuth from '@hooks/useAuth';

import ModalPatchUserProfilePicture from './ModalPatchUserProfilePicture';

type UserAvatarProps = AvatarProps;

const UserAvatar: FC<UserAvatarProps> = () => {
  // ==========================================================================
  // General
  // ==========================================================================
  const { user, deleteProfilePicture } = useAuth();

  // ==========================================================================
  // State
  // ==========================================================================
  const [overlayVisible, setOverlayVisible] = useState(false);

  // ==========================================================================
  // Api
  // ==========================================================================

  // ==========================================================================
  // Form
  // ==========================================================================

  // ==========================================================================
  // Handlers
  // ==========================================================================
  const handleEditClick = async () => {
    openModal({
      modalId: 'profilePictureEditor',
      title: 'Modifica immagine',
      size: 'md',
      children: <ModalPatchUserProfilePicture />,
    });
  };

  const handleRemoveClick = async () => {
    openConfirmModal({
      title: 'Rimuovi immagine',
      children: "Vuoi rimuovere l'immagine del profilo?",
      labels: { confirm: 'Rimuovi', cancel: 'Annulla' },
      confirmProps: { color: 'red' },
      size: 'md',
      onConfirm: deleteProfilePicture,
    });
  };

  // ==========================================================================
  // Render
  // ==========================================================================
  return (
    <Box
      style={{ position: 'relative', cursor: 'pointer' }}
      onMouseEnter={() => setOverlayVisible(true)}
      onMouseLeave={() => setOverlayVisible(false)}
    >
      {overlayVisible && (
        <>
          <Overlay radius={1000} />
          <Group
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 201,
            }}
          >
            <ActionIcon variant="filled" onClick={handleEditClick}>
              <IconPencil />
            </ActionIcon>
            {user!.profilePicture && (
              <ActionIcon
                variant="filled"
                color="red"
                onClick={handleRemoveClick}
              >
                <IconTrash />
              </ActionIcon>
            )}
          </Group>
        </>
      )}
      <Avatar
        size={rem('10rem')}
        radius={rem('10rem')}
        src={user!.profilePicture}
      >
        {user!.name![0].toUpperCase()}
        {user!.surname![0].toUpperCase()}
      </Avatar>
    </Box>
  );
};

export default UserAvatar;
