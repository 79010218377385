import {
  SigninRequest,
  SignupRequest,
  User,
  UserWithChangelog,
} from '@interfaces/auth.interface';

import api from '../';

const authApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCurrentUser: build.query<UserWithChangelog, void>({
      query: () => ({ url: 'auth/me' }),
    }),
    signin: build.mutation<User, SigninRequest>({
      query: (body) => ({
        url: 'auth/signin',
        method: 'POST',
        body,
      }),
    }),
    signup: build.mutation<void, SignupRequest>({
      query: (body) => ({
        url: 'auth/signup',
        method: 'POST',
        body,
      }),
    }),
    logout: build.mutation<void, void>({
      query: (body) => ({
        url: 'auth/logout',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useGetCurrentUserQuery,
  useSigninMutation,
  useSignupMutation,
  useLogoutMutation,
} = authApi;
