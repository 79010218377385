import { ModalProps } from '@interfaces/modals.interface';
import { Button, Grid, Text, TextInput, Title } from '@mantine/core';
import { useForm } from '@mantine/form';
import { closeAllModals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { handleSubmitError } from '@utils/general';

import { Agency, usePatchAgencyMutation } from '@api/agencies/agencies.api';

interface ModalPatchAgencyProps extends ModalProps {
  agency: Agency;
}

export default function ModalPatchAgency({
  agency,
  closeAllOnConfirm = true,
  onConfirmed,
}: ModalPatchAgencyProps) {
  // ==========================================================================
  // Api
  // ==========================================================================
  const [patchAgency, { isLoading }] = usePatchAgencyMutation();

  // ==========================================================================
  // State
  // ==========================================================================

  // ==========================================================================
  // Form
  // ==========================================================================
  const initialValues = {
    agencyName: agency.agencyName,
    email: agency.email,
    phone: agency.phone,
    address: agency.address,
    vat: agency.vat,
    province: agency.province,
    city: agency.city,
  };

  const form = useForm({
    initialValues,
  });

  const onSubmit = async (values: typeof initialValues) => {
    try {
      await patchAgency({
        id: agency.id,
        body: { ...values, province: values.province.toUpperCase() },
      }).unwrap();

      showNotification({
        title: 'Agenzia modificata',
        message: "L'agenzia è stato modificato con successo",
      });

      onConfirmed?.();

      if (closeAllOnConfirm) {
        closeAllModals();
      }
    } catch (e) {
      handleSubmitError(e, form);
    }
  };

  // ==========================================================================
  // Render
  // ==========================================================================

  return (
    <form
      onSubmit={form.onSubmit((values) => {
        onSubmit(values);
      })}
    >
      <Grid>
        {/* ROW */}
        <Grid.Col span={6}>
          <TextInput
            required
            label="Nome:"
            {...form.getInputProps('agencyName')}
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <TextInput
            label="Telefono:"
            required
            {...form.getInputProps('phone')}
          />
        </Grid.Col>

        {/* ROW */}
        <Grid.Col span={6}>
          <TextInput label="p.Iva" required {...form.getInputProps('vat')} />
        </Grid.Col>
        <Grid.Col span={6}>
          <TextInput label="Email:" required {...form.getInputProps('email')} />
        </Grid.Col>

        {/* ROW */}
        <Grid.Col>
          <Title mt="md" order={6}>
            INDIRIZZO
          </Title>
        </Grid.Col>

        {/* ROW */}
        <Grid.Col>
          <TextInput
            label="Provincia (Sigla):"
            required
            {...form.getInputProps('province')}
          />
        </Grid.Col>

        {/* ROW */}
        <Grid.Col>
          <TextInput required label="Comune:" {...form.getInputProps('city')} />
        </Grid.Col>

        {/* ROW */}
        <Grid.Col>
          <TextInput
            required
            label="Indirizzo:"
            {...form.getInputProps('address')}
          />
        </Grid.Col>

        {/* ROW */}
        <Grid.Col span={9} mt="md">
          {form.errors.general && (
            <Text c="red" mt="md">
              {form.errors.general}
            </Text>
          )}
        </Grid.Col>
        <Grid.Col span={3}>
          <Button type="submit" fullWidth mt="md" loading={isLoading}>
            Conferma
          </Button>
        </Grid.Col>
      </Grid>
    </form>
  );
}
