import { DataTable, DataTableSortStatus } from 'mantine-datatable';
import { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';

import {
  ActionIcon,
  Divider,
  Group,
  rem,
  Select,
  Text,
  Tooltip,
  useMantineTheme,
} from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { openConfirmModal, openModal } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import {
  IconCategory,
  IconFileText,
  IconInfoSquareRoundedFilled,
  IconPencil,
  IconTrash,
} from '@tabler/icons-react';
import { setMinHeightTable } from '@utils/general';

import {
  Client,
  ClientApiQueryParams,
  ClientDetail,
  ClientType,
  SortBy,
  useDeleteClientMutation,
  useGetClientsCountQuery,
  useGetClientsQuery,
} from '@api/clients/clients.api';
import { generateDataClients } from '@api/clients/clients.mock';
import { isApiError } from '@api/index';

import useLayoutProps from '@components/layout/useLayoutProps';
import ModalAddClient from '@components/ModalAddClient';
import { ModalInfoClient } from '@components/ModalInfoClient';
import ModalPatchClient from '@components/ModalPatchClient';
import PaginationRow from '@components/PaginationRow';
import SearchAddContainer from '@components/SearchAddContainer';

export default function Clients() {
  // ==========================================================================
  // General
  // ==========================================================================
  const [searchParams, setSearchParams] = useSearchParams();
  const theme = useMantineTheme();
  useLayoutProps({
    title: 'Clienti',
  });

  // ==========================================================================
  // State
  // ==========================================================================
  const [filters, setFilters] = useState<ClientApiQueryParams>({
    page: +(searchParams.get('page') || 1),
    pageLength: +(searchParams.get('pageLength') || 50),
    searchQuery: '',
    type: searchParams.get('type')
      ? (searchParams.get('type') as ClientType)
      : undefined,
  });

  const [searchQuery] = useDebouncedValue(filters.searchQuery, 200, {
    leading: true,
  });

  const [sortStatus, setSortStatus] = useState<DataTableSortStatus<Client>>({
    columnAccessor: 'email',
    direction: 'asc',
  });

  useEffect(() => {
    if (sortStatus) {
      setFilters((f: ClientApiQueryParams) => ({
        ...f,
        sortBy: sortStatus.columnAccessor as SortBy,
        sortOrder: sortStatus.direction,
      }));
    }
  }, [sortStatus]);

  // ==========================================================================
  // Api
  // ==========================================================================

  const {
    data: clientsCount = { count: 0 },
    isLoading: isLoadingClientsCount,
  } = useGetClientsCountQuery(searchQuery);

  const {
    data: clients = generateDataClients(2),
    isLoading,
    error: errorClients,
  } = useGetClientsQuery({
    ...filters,
    searchQuery,
  });

  const [clientDelete, { isLoading: deleteLoading }] =
    useDeleteClientMutation();

  // Clients Filtes
  const onClientsTypeChange = (value: ClientType) => {
    searchParams.set('type', value);
    setSearchParams(searchParams);
    setFilters({
      ...filters,
      type: value,
    });
  };

  const typeClientFilter = (
    <Select
      key="type filter"
      value={filters.type}
      onChange={(value: string | null) =>
        onClientsTypeChange(value as ClientType)
      }
      placeholder="Filtro tipologia"
      data={[
        { label: 'Entrambi', value: 'all' },
        { label: 'Soggetto diverso da persona fisica', value: 'company' },
        { label: 'Persona fisica', value: 'holder' },
      ]}
      allowDeselect={false}
    />
  );

  // ==========================================================================
  // Handlers
  // ==========================================================================
  const onEditClientClick = async (client: ClientDetail) => {
    openModal({
      title: 'Modifica cliente',
      children: <ModalPatchClient client={client} />,
    });
  };

  const onGetInfoClientClick = async (client: ClientDetail) => {
    openModal({
      title: 'Informazione cliente',
      children: <ModalInfoClient client={client}></ModalInfoClient>,
      size: 'lg',
    });
  };

  // ==========================================================================
  // Render
  // ==========================================================================

  const totalPages = Math.ceil(clientsCount.count / filters.pageLength!);

  return (
    <SearchAddContainer
      additionalFilters={[typeClientFilter]}
      searchPlaceholder="Ricerca per nominativo"
      searchValue={filters.searchQuery}
      onSearchChange={(newValue) =>
        setFilters({ ...filters, searchQuery: newValue })
      }
      onActionButtonClick={() =>
        openModal({
          title: 'Inserisci nuovo cliente',
          children: <ModalAddClient />,
        })
      }
    >
      <DataTable
        minHeight={setMinHeightTable(clients)}
        withRowBorders
        striped
        styles={{
          root: {
            borderRadius: theme.radius.md,
            boxShadow: theme.shadows.lg,
          },
          header: {
            backgroundColor: '#1e2023',
          },
        }}
        records={clients}
        columns={[
          {
            accessor: '',
            title: '',
            render: (record: Client) => (
              <ActionIcon
                title="Info"
                onClick={() => {
                  onGetInfoClientClick(record);
                }}
              >
                <IconInfoSquareRoundedFilled />
              </ActionIcon>
            ),
          },
          {
            accessor: 'name',
            title: 'Nominativo',
            render: (record: Client) =>
              record.type === 'holder'
                ? record.name + ' ' + record.surname
                : record.companyName,
          },
          { accessor: 'email', title: 'Email', sortable: true },
          {
            accessor: 'agency.agencyName',
            title: 'Agenzia di riferimento',
          },
          {
            accessor: 'type',
            title: 'Tipologia',
            render: (record: Client) =>
              record.type === 'company'
                ? 'Sogetto diverso da persona fisica'
                : 'Persona fisica',
          },

          {
            accessor: 'actions',
            title: '',
            render: (record: Client) => (
              <Group justify="flex-end">
                <Tooltip label="Servizi">
                  <ActionIcon
                    to={`/clienti/${record.id}/servizi`}
                    component={Link}
                  >
                    <IconCategory />
                  </ActionIcon>
                </Tooltip>
                <Tooltip label="Progetti">
                  <ActionIcon
                    to={`/clienti/${record.id}/progetti`}
                    component={Link}
                  >
                    <IconFileText />
                  </ActionIcon>
                </Tooltip>
                <Divider orientation="vertical" size={rem('0.15rem')} />
                <ActionIcon
                  title="Modifica"
                  onClick={() => onEditClientClick(record)}
                >
                  <IconPencil />
                </ActionIcon>
                <ActionIcon
                  title="Elimina"
                  loading={deleteLoading}
                  onClick={async () => {
                    openConfirmModal({
                      title: 'Eliminazione cliente',
                      size: 'lg',
                      children: (
                        <Text>
                          Stai per eliminare il cliente{' '}
                          {record.type === 'company'
                            ? record.companyName
                            : `${record.name} ${record.surname}`}
                          . Sicuro di voler procedere?
                        </Text>
                      ),
                      labels: {
                        confirm: 'Conferma eliminazione',
                        cancel: 'Annulla',
                      },
                      confirmProps: { color: 'red' },
                      onConfirm: async () => {
                        try {
                          await clientDelete({ id: record.id }).unwrap();

                          showNotification({
                            title: 'Eliminazione cliente',
                            message:
                              "L'eliminazione del cliente è avvenuta con successo",
                          });
                        } catch (e) {
                          if (isApiError(e)) {
                            if (e.status === 400) {
                              showNotification({
                                color: 'red',
                                title: 'Errore',
                                message: e.data.message,
                              });
                            } else {
                              console.error(e);
                              showNotification({
                                color: 'red',
                                title: 'Errore',
                                message:
                                  "Errore nell'eliminazione del cliente. Riprova più tardi",
                              });
                            }
                          }
                        }
                      },
                    });
                  }}
                >
                  <IconTrash />
                </ActionIcon>
              </Group>
            ),
          },
        ]}
        fetching={isLoading || isLoadingClientsCount}
        noRecordsText={
          errorClients
            ? 'Errore. Ricaricare la pagina'
            : 'Nessun cliente trovato'
        }
        sortStatus={sortStatus}
        onSortStatusChange={setSortStatus}
      ></DataTable>
      {clients.length > 0 && (
        <PaginationRow
          page={filters.page!}
          pageLength={filters.pageLength!}
          totalPages={totalPages}
          onPageChange={(newPage) => setFilters({ ...filters, page: newPage })}
          onPageLengthChange={(newPageLength) =>
            setFilters({ ...filters, pageLength: newPageLength, page: 1 })
          }
        />
      )}
    </SearchAddContainer>
  );
}
