import { Link } from 'react-router-dom';

import {
  Avatar,
  Badge,
  Code,
  ColorPicker,
  Divider,
  Group,
  Loader,
  Menu,
  rem,
  SegmentedControl,
  Stack,
  Text,
  UnstyledButton,
  useMantineTheme,
} from '@mantine/core';
import { openModal } from '@mantine/modals';
import {
  IconBell,
  IconBriefcase,
  IconBriefcaseFilled,
  IconCapture,
  IconCaptureFilled,
  IconCategory,
  IconCategoryFilled,
  IconChartDonut,
  IconChartDonutFilled,
  IconChevronRight,
  IconFileFilled,
  IconFileText,
  IconLock,
  IconLogout,
  IconSettings,
  IconUser,
  IconUserFilled,
  IconUsers,
} from '@tabler/icons-react';

import env from '@config/env';

import { useLazyGetChangelogsQuery } from '@api/changelogs/changelogs.api';
import { useGetJobsStatusQuery } from '@api/jobs/jobs.api';

import useAuth from '@hooks/useAuth';
import useTheme from '@hooks/useTheme';
import useUiScale, { UiScale } from '@hooks/useUiDimensions';

import ModalChangelog from '@components/ModalChangelog';
import ModalPatchCurrentUser from '@components/ModalPatchCurrentUser';
import EditNotificationsSettings from '@components/ModalPatchNotificationsSettings';
import ModalPatchPasswordProfile from '@components/ModalPatchUserPassword';
import { SidebarItem } from '@components/sidebarItem/SidebarItem';
import UserPermissionGuard from '@components/UserPermissionGuard';

import logo from '@images/logo.svg';

import classes from './Sidebar.module.css';

export default function Sidebar() {
  // ==========================================================================
  // General
  // ==========================================================================
  const theme = useMantineTheme();
  const { scale, setScale } = useUiScale();

  const { primaryColor, setPrimaryColor } = useTheme();

  // ==========================================================================
  // Api
  // ==========================================================================
  const [getChangelogs, { isLoading: isLoadingChangelogs }] =
    useLazyGetChangelogsQuery();
  const { user, logout } = useAuth();

  const { data: jobsStatus } = useGetJobsStatusQuery();

  // ==========================================================================
  // Handler
  // ==========================================================================
  const handleVersionClick = async () => {
    const changelogs = await getChangelogs().unwrap();

    openModal({
      title: 'Changelogs',
      children: <ModalChangelog changelogs={changelogs} />,
      size: 'xl',
    });
  };

  const handleLogout = async () => {
    await logout();
  };

  const onOpenPatchProfileClick = async () => {
    openModal({
      title: 'Modifica profilo',
      children: (
        <ModalPatchCurrentUser
          user={{
            email: user!.email,
            name: user!.name,
            surname: user!.surname,
            profilePicture: user!.profilePicture,
          }}
        />
      ),
      size: 'md',
    });
  };

  const onOpenPatchPasswordProfileClick = async () => {
    openModal({
      title: 'Modifica password profilo',
      children: <ModalPatchPasswordProfile />,
      size: 'md',
    });
  };

  const onOpenPatchSettingsNotificationClick = async () => {
    openModal({
      title: 'Impostazioni notifiche',
      children: <EditNotificationsSettings />,
      size: 'md',
    });
  };

  // ==========================================================================
  // Render
  // ==========================================================================

  const websiteScannerRightSection = jobsStatus && (
    <Group>
      {(jobsStatus.websiteScanner.inProgress.length !== 0 ||
        jobsStatus.websiteScanner.failed.length !== 0) && (
        <Badge
          variant={
            jobsStatus.websiteScanner.inProgress.length > 0 ? 'dot' : 'light'
          }
          c={jobsStatus.websiteScanner.failed.length > 0 ? 'red' : undefined}
        >
          {jobsStatus.websiteScanner.inProgress.length > 0
            ? jobsStatus.websiteScanner.inProgress.length
            : jobsStatus.websiteScanner.failed.length}
        </Badge>
      )}
    </Group>
  );

  const serviceshImportRightSection = jobsStatus && (
    <Group>
      {(jobsStatus.servicesImporter.inProgress.length !== 0 ||
        jobsStatus.servicesImporter.failed.length !== 0 ||
        jobsStatus.servicesImporter.completed.length !== 0) && (
        <Badge
          variant={
            jobsStatus.servicesImporter.inProgress.length > 0 ? 'dot' : 'light'
          }
          c={jobsStatus.servicesImporter.failed.length > 0 ? 'red' : undefined}
        >
          {jobsStatus.servicesImporter.inProgress.length > 0
            ? jobsStatus.servicesImporter.inProgress.length
            : jobsStatus.servicesImporter.failed.length > 0
              ? jobsStatus.servicesImporter.failed.length
              : jobsStatus.servicesImporter.completed.length}
        </Badge>
      )}
    </Group>
  );

  return (
    <Stack className={classes.navbar}>
      {/* Logo */}
      <Group justify="space-between" mt="xl" p="md">
        <Link to="/">
          <img className={classes.logo} src={logo} alt="Logo" />
        </Link>
      </Group>
      <Divider
        label="Amministrazione"
        fw={600}
        px="md"
        labelPosition="left"
        className={classes.divider}
      />

      {/* Menu items */}
      <div className={classes.linksContainer}>
        <SidebarItem
          icon={IconChartDonut}
          link="/"
          label="Dashboard"
          selectedIcon={IconChartDonutFilled}
        />
        <SidebarItem
          icon={IconCategory}
          link="/servizi"
          label="Servizi"
          selectedIcon={IconCategoryFilled}
          rightSection={serviceshImportRightSection}
        />
        <SidebarItem
          icon={IconUser}
          link="/clienti"
          label="Clienti"
          selectedIcon={IconUserFilled}
        />
        <SidebarItem
          icon={IconFileText}
          link="/progetti"
          label="Progetti"
          selectedIcon={IconFileFilled}
        />
        <SidebarItem
          icon={IconBriefcase}
          link="/agenzie"
          label="Agenzie"
          selectedIcon={IconBriefcaseFilled}
        />

        <UserPermissionGuard permission="tools">
          <Divider
            label="Strumenti"
            fw={600}
            p="md"
            labelPosition="left"
            className={classes.divider}
          />
          <SidebarItem
            icon={IconCapture}
            link="/website-scanner"
            label="Website scanner"
            selectedIcon={IconCaptureFilled}
            rightSection={websiteScannerRightSection}
          />
        </UserPermissionGuard>
      </div>

      {/* Changelogs */}
      <Group px="md">
        <UnstyledButton
          onClick={isLoadingChangelogs ? undefined : handleVersionClick}
        >
          <Code fw={700} className={classes.versionBadge}>
            {env.APP_VERSION}
          </Code>
          {isLoadingChangelogs && <Loader />}
        </UnstyledButton>
      </Group>

      {/* Settings & User */}
      <div className={classes.usersItem}>
        <Divider className={classes.divider} />
        <Menu position="right" withArrow offset={23}>
          <Menu.Target>
            <Group
              gap={0}
              className={classes.menuItem}
              style={{ borderRadius: theme.radius?.sm }}
              wrap="nowrap"
            >
              <IconSettings
                className="icon"
                size="1.7rem"
                style={{
                  marginRight: theme.spacing?.md,
                }}
              />
              <Group justify="space-between" style={{ width: '80%' }}>
                <Text fz={rem('1.12rem')} fw={500}>
                  Impostazioni
                </Text>
                <IconChevronRight size="1.2rem" />
              </Group>
            </Group>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Label>Colore primario</Menu.Label>
            <Menu.Item component="div" closeMenuOnClick={false}>
              <ColorPicker
                swatches={[
                  '#a28b75',
                  '#0000ff',
                  '#008000',
                  '#ffff00',
                  '#ff0000',
                  '#800000',
                  '#ff00ff',
                ]}
                value={primaryColor}
                onChangeEnd={setPrimaryColor}
                fullWidth
                format="hex"
              />
            </Menu.Item>
            <Menu.Label>Dimensione interfaccia</Menu.Label>
            <Menu.Item component="div" closeMenuOnClick={false}>
              <SegmentedControl
                data={[
                  { label: 'Piccola', value: 'sm' },
                  { label: 'Media', value: 'md' },
                  { label: 'Grande', value: 'lg' },
                ]}
                value={scale}
                onChange={(value) => setScale(value as UiScale)}
              />
            </Menu.Item>
            <UserPermissionGuard permission="users">
              <Menu.Divider />
              <Menu.Item
                leftSection={<IconUsers size="1rem" />}
                component={Link}
                to="/utenti"
              >
                Gestione utenti
              </Menu.Item>
              <Menu.Item
                leftSection={<IconBell size="1rem" />}
                onClick={() => {
                  onOpenPatchSettingsNotificationClick();
                }}
              >
                Impostazioni notifiche
              </Menu.Item>
            </UserPermissionGuard>
          </Menu.Dropdown>
        </Menu>
        <Menu position="right" withArrow offset={23}>
          <Menu.Target>
            <Group
              className={classes.menuItem}
              style={{ borderRadius: theme.radius.sm }}
              wrap="nowrap"
            >
              <Avatar
                radius={rem('1.7rem')}
                src={user?.profilePicture}
                size={rem('1.7rem')}
              >
                {user?.name[0].toUpperCase()}
                {user?.surname[0].toUpperCase()}
              </Avatar>
              <Group
                justify="space-between"
                wrap="nowrap"
                style={{ width: '80%' }}
              >
                <Text
                  fz={rem('1.15rem')}
                  fw={500}
                  className={classes.currentUserInfo}
                >
                  {user?.name} {user?.surname}
                </Text>
                <IconChevronRight size="1.2rem" />
              </Group>
            </Group>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Item
              leftSection={<IconUser size="1rem" />}
              onClick={() => {
                onOpenPatchProfileClick();
              }}
            >
              Modifica profilo
            </Menu.Item>
            <Menu.Item
              leftSection={<IconLock size="1rem" />}
              onClick={() => {
                onOpenPatchPasswordProfileClick();
              }}
            >
              Modifica password
            </Menu.Item>

            <Menu.Item
              leftSection={<IconLogout size="1rem" />}
              onClick={handleLogout}
            >
              Logout
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </div>
    </Stack>
  );
}
