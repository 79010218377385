import { FC } from 'react';

import { User } from '@interfaces/auth.interface';
import { ModalProps } from '@interfaces/modals.interface';
import { Button, Grid, Text, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { closeAllModals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { handleSubmitError } from '@utils/general';

import { usePatchUserMutation } from '@api/users/users.api';

interface ModalPatchUserProps extends ModalProps {
  user: User;
}

const ModalPatchUser: FC<ModalPatchUserProps> = ({
  user,
  closeAllOnConfirm = true,
  onConfirmed,
}) => {
  // ==========================================================================
  // Api
  // ==========================================================================
  const [patchUser, { isLoading }] = usePatchUserMutation();

  // ==========================================================================
  // State
  // ==========================================================================

  // ==========================================================================
  // Form
  // ==========================================================================
  const initialValues = {
    name: user.name,
    surname: user.surname,
    email: user.email,
  };

  const form = useForm({
    initialValues,
  });

  const onSubmit = async (values: typeof initialValues) => {
    try {
      await patchUser({
        id: user.id,
        body: values,
      }).unwrap();

      showNotification({
        title: 'Utente modificata',
        message: "L'utente è stato modificato con successo",
      });

      onConfirmed?.();
      if (closeAllOnConfirm) {
        closeAllModals();
      }
    } catch (e) {
      handleSubmitError(e, form);
    }
  };

  // ==========================================================================
  // Render
  // ==========================================================================

  return (
    <form
      onSubmit={form.onSubmit((values) => {
        onSubmit(values);
      })}
    >
      <Grid>
        {/* ROW */}
        <Grid.Col span={6}>
          <TextInput required label="Nome:" {...form.getInputProps('name')} />
        </Grid.Col>
        <Grid.Col span={6}>
          <TextInput
            label="Cognome:"
            required
            {...form.getInputProps('surname')}
          />
        </Grid.Col>

        {/* ROW */}
        <Grid.Col span={12}>
          <TextInput label="Email:" required {...form.getInputProps('email')} />
        </Grid.Col>

        {/* ROW */}
        <Grid.Col span={10} mt="md">
          {form.errors.general && (
            <Text color="red" mt="md">
              {form.errors.general}
            </Text>
          )}
        </Grid.Col>
        <Grid.Col span={2}>
          <Button type="submit" fullWidth mt="md" loading={isLoading}>
            Conferma
          </Button>
        </Grid.Col>
      </Grid>
    </form>
  );
};

export default ModalPatchUser;
