import { SortOrder } from '@domain/types';

import { ServiceShImported } from '@api/servicessh/servicesSh.mock';

import api from '../';

export type SortBy = 'name' | 'createdAt';

export type StatusFilter = 'all' | 'completed' | 'failed' | 'inProgress';
export type CategoryFitler = 'all' | 'servicesImporter' | 'websiteScanner';
export type ManagedfFilter = 'true' | 'false';

export interface JobApiQueryParams {
  sortBy?: SortBy;
  sortOrder?: SortOrder;
  searchQuery?: string;
  page?: number;
  pageLength?: number;
  status?: StatusFilter;
  category?: CategoryFitler;
  managed?: ManagedfFilter;
}

export interface JobApiCountParams {
  searchQuery?: string;
  status?: StatusFilter;
  category?: CategoryFitler;
  managed?: ManagedfFilter;
}

export interface JobServiceCountApiQueryParams {
  id: string;
  params: JobApiCountParams;
}

export interface Job {
  id: number;
  name: string;
  category: JobsCategory;
  status: JobsStatus;
  requestData: string;
  responseData: string;
  createdAt: string;
  managed: boolean;
  jobBullId: number;
}

interface JobsCount {
  count: number;
}

export type JobsStatus = 'inProgress' | 'completed' | 'failed';

export type JobsCategory = 'servicesImporter' | 'websiteScanner';

export interface JobsRequest {
  id: number;
}

export interface JobsResponse {
  message: string;
}

interface JobDeleteRequest {
  id: number;
}

export interface JobsStatusResponse {
  websiteScanner: {
    failed: number[];
    completed: number[];
    inProgress: number[];
  };
  servicesImporter: {
    failed: number[];
    completed: number[];
    inProgress: number[];
  };
}

const jobsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getJobs: builder.query<Job[], JobApiQueryParams>({
      query: (params) => ({
        url: 'jobs',
        params,
        cache: 'no-cache',
      }),
      providesTags: ['jobs'],
    }),
    getJobsCount: builder.query<JobsCount, JobApiCountParams>({
      query: (searchQuery) => ({
        url: 'jobs/count',
        params: searchQuery,
      }),
      providesTags: ['jobs'],
    }),
    getJobsStatus: builder.query<JobsStatusResponse, void>({
      query: () => ({
        url: 'jobs/status',
      }),
      providesTags: ['jobs'],
    }),
    deleteJob: builder.mutation<string, JobDeleteRequest>({
      query: ({ id }) => ({
        url: `jobs/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['jobs'],
    }),
    getResponseDataJob: builder.query<ServiceShImported, { id: number }>({
      query: ({ id }) => ({
        url: `jobs/${id}/response`,
        method: 'GET',
      }),
      providesTags: ['jobs'],
    }),
  }),
});

export const {
  useGetJobsQuery,
  useGetJobsCountQuery,
  useDeleteJobMutation,
  useGetResponseDataJobQuery,
  useGetJobsStatusQuery,
} = jobsApi;
