import { FC, useState } from 'react';

import { Box, Image, ImageProps, Overlay } from '@mantine/core';
import { IconEye } from '@tabler/icons-react';

interface ClickableImageProps extends ImageProps {
  onClick: () => void;
  alt: string;
}

const ClickableImage: FC<ClickableImageProps> = ({ onClick, ...rest }) => {
  // ==========================================================================
  // General
  // ==========================================================================

  // ==========================================================================
  // State
  // ==========================================================================
  const [overlayVisible, setOverlayVisible] = useState(false);

  // ==========================================================================
  // Api
  // ==========================================================================

  // ==========================================================================
  // Form
  // ==========================================================================

  // ==========================================================================
  // Handlers
  // ==========================================================================

  // ==========================================================================
  // Render
  // ==========================================================================
  return (
    <Box
      style={{ position: 'relative', cursor: 'pointer' }}
      onClick={() => {
        setOverlayVisible(false);
        onClick();
      }}
      onMouseEnter={() => setOverlayVisible(true)}
      onMouseLeave={() => setOverlayVisible(false)}
    >
      {overlayVisible && (
        <>
          <Overlay />
          <IconEye
            size="3rem"
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 201,
            }}
            color="white"
          />
        </>
      )}
      <Image {...rest} />
    </Box>
  );
};

export default ClickableImage;
