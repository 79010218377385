import api from '../';

interface PasswordResetRequestRequest {
  email: string;
}

interface PasswordResetRequestResponse {
  message: string;
}

interface PasswordResetRequest {
  token: string;
  body: { password: string };
}

interface PasswordResetResponse {
  message: string;
}

interface CheckTokenResponse {
  message: string;
}

const passwordResetApi = api.injectEndpoints({
  endpoints: (builder) => ({
    passwordResetRequest: builder.mutation<
      PasswordResetRequestResponse,
      PasswordResetRequestRequest
    >({
      query: (body) => ({
        url: 'password-reset/request',
        method: 'POST',
        body,
      }),
    }),
    checkTokenPassword: builder.query<CheckTokenResponse, string>({
      query: (token) => ({
        url: `password-reset/${token}`,
      }),
    }),
    passwordReset: builder.mutation<
      PasswordResetResponse,
      PasswordResetRequest
    >({
      query: ({ token, body }) => ({
        url: `password-reset/${token}`,
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  usePasswordResetRequestMutation,
  usePasswordResetMutation,
  useCheckTokenPasswordQuery,
} = passwordResetApi;
