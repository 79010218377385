import { FC } from 'react';

import { ModalProps } from '@interfaces/modals.interface';
import {
  Button,
  Grid,
  Select,
  Text,
  Textarea,
  TextInput,
  Title,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { closeAllModals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { handleSubmitError } from '@utils/general';

import { ClientDetail, usePatchClientMutation } from '@api/clients/clients.api';

import AgencySelector from './AgencySelector';

interface ModalPatchClientProps extends ModalProps {
  client: ClientDetail;
}

const ModalPatchClient: FC<ModalPatchClientProps> = ({
  client,
  closeAllOnConfirm = true,
  onConfirmed,
}) => {
  // ==========================================================================
  // Api
  // ==========================================================================
  const [patchClient, { isLoading }] = usePatchClientMutation();

  // ==========================================================================
  // Form
  // ==========================================================================
  const initialValues = {
    type: client.type,
    name: client.name || '',
    surname: client.surname || '',
    companyName: client.companyName || '',
    vat: client.vat || '',
    taxCode: client.taxCode || '',
    phone: client.phone || '',
    pec: client.pec || '',
    sdi: client.sdi || '',
    email: client.email || '',
    province: client.province || '',
    city: client.city || '',
    address: client.address || '',
    notes: client.notes || '',
    agencyId: client.agencyId.toString(),
  };

  const form = useForm({
    initialValues,
    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Email non valida'),
    },
  });

  const onSubmit = async (values: typeof initialValues) => {
    try {
      await patchClient({
        id: client.id,
        body: {
          ...Object.fromEntries(
            Object.entries(values).map(([key, value]) =>
              value.trim() === '' ? [key, null] : [key, value],
            ),
          ),
          type: values.type,
          agencyId: +values.agencyId,
          province: values.province && values.province.toUpperCase(),
        },
      }).unwrap();

      showNotification({
        title: 'Cliente modificato',
        message: 'Il cliente è stato modificato con successo',
      });

      onConfirmed?.();
      if (closeAllOnConfirm) {
        closeAllModals();
      }
    } catch (e) {
      handleSubmitError(e, form);
    }
  };

  // ==========================================================================
  // Render
  // ==========================================================================
  return (
    <form
      onSubmit={form.onSubmit((values) => {
        onSubmit(values);
      })}
    >
      <Grid>
        {/* ROW */}
        <Grid.Col span={6}>
          <Title order={6}>DATI ANAGRAFICI</Title>
        </Grid.Col>
        <Grid.Col span={6}>
          <Title order={6}>CONTATTI PRINCIPALI</Title>
        </Grid.Col>
        {/* ROW */}
        <Grid.Col span={6}>
          <Select
            color="default"
            required
            label="Tipologia:"
            data={[
              { value: 'holder', label: 'Persona fisica' },
              {
                value: 'company',
                label: 'Sogetto diverso da persona fisica',
              },
            ]}
            {...form.getInputProps('type')}
            allowDeselect={false}
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <TextInput required label="Email:" {...form.getInputProps('email')} />
        </Grid.Col>

        {/* ROW */}
        {form.values.type === 'company' ? (
          <Grid.Col span={6}>
            <TextInput
              label="Ragione sociale:"
              required
              {...form.getInputProps('companyName')}
            />
          </Grid.Col>
        ) : (
          <>
            <Grid.Col span={3}>
              <TextInput
                required
                label="Nome:"
                {...form.getInputProps('name')}
              />
            </Grid.Col>
            <Grid.Col span={3}>
              <TextInput
                required
                label="Cognome:"
                {...form.getInputProps('surname')}
              />
            </Grid.Col>
          </>
        )}

        <Grid.Col span={6}>
          <TextInput label="Telefono:" {...form.getInputProps('phone')} />
        </Grid.Col>

        {/* ROW */}
        <Grid.Col span={6}>
          {form.values.type === 'company' && (
            <TextInput label="P. Iva" {...form.getInputProps('vat')} />
          )}
        </Grid.Col>
        <Grid.Col span={6}>
          {form.values.type === 'company' && (
            <TextInput label="Pec:" {...form.getInputProps('pec')} />
          )}
        </Grid.Col>

        {/* ROW */}
        <Grid.Col span={6}>
          <TextInput label="C. Fiscale" {...form.getInputProps('taxCode')} />
        </Grid.Col>
        {form.values.type === 'company' && (
          <Grid.Col span={6}>
            <TextInput label="Sdi:" {...form.getInputProps('sdi')} />
          </Grid.Col>
        )}

        {/* ROW */}
        <Grid.Col>
          <Title mt="md" order={6}>
            INDIRIZZO
          </Title>
        </Grid.Col>

        {/* ROW */}
        <Grid.Col span={2}>
          <TextInput
            maxLength={2}
            label="Provincia (Sigla):"
            {...form.getInputProps('province')}
          />
        </Grid.Col>
        <Grid.Col span={5}>
          <TextInput label="Comune:" {...form.getInputProps('city')} />
        </Grid.Col>
        <Grid.Col span={5}>
          <TextInput label="Indirizzo:" {...form.getInputProps('address')} />
        </Grid.Col>

        {/* ROW */}
        <Grid.Col>
          <Title mt="md" order={6}>
            INFORMAZIONI AGGIUNTIVE
          </Title>
        </Grid.Col>

        {/* ROW */}
        <Grid.Col>
          <AgencySelector
            required
            onChange={(value) => form.setFieldValue('agencyId', value!)}
            value={form.values.agencyId}
          />
        </Grid.Col>

        {/* ROW */}
        <Grid.Col>
          <Textarea label="Note" {...form.getInputProps('notes')} />
        </Grid.Col>

        {/* ROW */}
        <Grid.Col span={10} mt="md">
          {form.errors.general && (
            <Text color="red" mt="md">
              {form.errors.general}
            </Text>
          )}
        </Grid.Col>
        <Grid.Col span={2}>
          <Button type="submit" fullWidth mt="md" loading={isLoading}>
            Conferma
          </Button>
        </Grid.Col>
      </Grid>
    </form>
  );
};

export default ModalPatchClient;
