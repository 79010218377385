import { DataTableSortStatus } from 'mantine-datatable';
import { FC, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useDebouncedValue } from '@mantine/hooks';

import {
  DateExpiryFilter,
  Service,
  ServiceApiQueryParams,
  SortBy,
  useGetServicesCountQuery,
  useGetServicesQuery,
} from '@api/services/services.api';
import { generateDataServices } from '@api/services/services.mock';
import {
  CustomServiceShMap,
  ServiceShApiQueryParams,
  SortBySh,
  useGetServicesShCountQuery,
  useGetServicesShQuery,
} from '@api/servicessh/servicesSh.api';
import { generateDataServicessh } from '@api/servicessh/servicesSh.mock';

import useLayoutProps from '@components/layout/useLayoutProps';
import ServicesTables from '@components/ServicesTables';

const Services: FC = () => {
  // ==========================================================================
  // General
  // ==========================================================================
  const [searchParams, setSearchParams] = useSearchParams();

  // ==========================================================================
  // State
  // ==========================================================================
  const [filtersServiceSh, setFiltersServiceSh] =
    useState<ServiceShApiQueryParams>({
      page: +(searchParams.get('pageSh') || 1),
      pageLength: +(searchParams.get('pageLengthSh') || 20),
      searchQuery: '',
      dateExpiryFilter: searchParams.get('dateExpiryShFilter')
        ? (searchParams.get('dateExpiryShFilter') as DateExpiryFilter)
        : undefined,
    });

  const [searchQuerySh] = useDebouncedValue(filtersServiceSh.searchQuery, 200, {
    leading: true,
  });

  const [filtersService, setFiltersService] = useState<ServiceApiQueryParams>({
    page: +(searchParams.get('page') || 1),
    pageLength: +(searchParams.get('pageLength') || 20),
    searchQuery: '',
    dateExpiryFilter: searchParams.get('dateExpiryFilter')
      ? (searchParams.get('dateExpiryFilter') as DateExpiryFilter)
      : undefined,
  });

  const [searchQuery] = useDebouncedValue(filtersService.searchQuery, 200, {
    leading: true,
  });

  const [sortStatusService, setSortStatusService] = useState<
    DataTableSortStatus<Service>
  >({
    columnAccessor: 'name',
    direction: 'asc',
  });

  const [sortStatusServiceSh, setSortStatusServiceSh] = useState<
    DataTableSortStatus<CustomServiceShMap>
  >({
    columnAccessor: 'dateExpiry',
    direction: 'asc',
  });

  useEffect(() => {
    if (sortStatusService) {
      setFiltersService((f: ServiceApiQueryParams) => ({
        ...f,
        sortBy: sortStatusService.columnAccessor as SortBy,
        sortOrder: sortStatusService.direction,
      }));
    }
  }, [sortStatusService]);

  useEffect(() => {
    if (sortStatusServiceSh) {
      setFiltersServiceSh((f: ServiceShApiQueryParams) => ({
        ...f,
        sortBy: sortStatusServiceSh.columnAccessor as SortBySh,
        sortOrder: sortStatusServiceSh.direction,
      }));
    }
  }, [sortStatusServiceSh]);

  // ==========================================================================
  // Api
  // ==========================================================================
  const { data: shCount = { count: 0 }, isLoading: isLoadingServicesShCount } =
    useGetServicesShCountQuery({
      searchQuery: searchQuerySh,
      dateExpiryFilter: filtersServiceSh.dateExpiryFilter,
    });

  const {
    data: servicesSh = generateDataServicessh(4),
    error: errorServicesSh,
    isLoading: isLoadingSh,
  } = useGetServicesShQuery({
    ...filtersServiceSh,
    searchQuery: searchQuerySh,
  });

  useLayoutProps({
    title: 'Servizi',
    banner:
      servicesSh.filter((service) => service.projects.length === 0).length > 0
        ? {
            title: 'Servizi incompleti',
            content:
              'Alcuni servizi non sono associati ad un progetto o un cliente.',
          }
        : undefined,
  });

  const {
    data: servicesCount = { count: 0 },
    isLoading: isLoadingServicesCount,
  } = useGetServicesCountQuery({
    searchQuery,
    dateExpiryFilter: filtersService.dateExpiryFilter,
  });

  const {
    data: services = generateDataServices(6),
    error: errorServices,
    isLoading: isLoadingServices,
  } = useGetServicesQuery({
    ...filtersService,
    searchQuery,
  });

  // ==========================================================================
  // Render
  // ==========================================================================

  return (
    <ServicesTables
      hideFilterAssociatedFilter
      servicesData={services as Service[]}
      servicesShData={servicesSh as CustomServiceShMap[]}
      servicesPagination={{
        count: servicesCount.count,
        pageLength: filtersService.pageLength || 1,
        page: filtersService.page || 1,
        onPageChange: (newPage) =>
          setFiltersService({ ...filtersService, page: newPage }),
        onPageLengthChange: (newPageLength) =>
          setFiltersService({
            ...filtersService,
            pageLength: newPageLength,
            page: 1,
          }),
      }}
      servicesShPagination={{
        count: shCount.count,
        pageLength: filtersServiceSh.pageLength || 1,
        page: filtersServiceSh.page || 1,
        onPageChange: (newPage) =>
          setFiltersServiceSh({ ...filtersServiceSh, page: newPage }),
        onPageLengthChange: (newPageLength) =>
          setFiltersServiceSh({
            ...filtersServiceSh,
            pageLength: newPageLength,
            page: 1,
          }),
      }}
      servicesSearchValue={filtersService.searchQuery}
      onServicesSearchValueChange={(newValue) =>
        setFiltersService({ ...filtersService, searchQuery: newValue })
      }
      servicesShSearchValue={filtersServiceSh.searchQuery}
      onServicesShSearchValueChange={(newValue) =>
        setFiltersServiceSh({ ...filtersServiceSh, searchQuery: newValue })
      }
      hideToPayColumn
      hideToNotifyColumn
      servicesDateExpiryFilter={filtersService.dateExpiryFilter}
      onServicesDateExpiryFilterChange={(value) => {
        const ServicesDateExpirySet = value as DateExpiryFilter;
        searchParams.set('dateExpiryFilter', ServicesDateExpirySet);
        setSearchParams(searchParams);

        setFiltersService({
          ...filtersService,
          dateExpiryFilter: ServicesDateExpirySet,
        });
      }}
      servicesShDateExpiryFilter={filtersServiceSh.dateExpiryFilter}
      onServicesShDateExpiryFilterChange={(value) => {
        const ServicesShDateExpirySet = value as DateExpiryFilter;

        searchParams.set('dateExpiryShFilter', ServicesShDateExpirySet);
        setSearchParams(searchParams);

        setFiltersServiceSh({
          ...filtersServiceSh,
          dateExpiryFilter: ServicesShDateExpirySet,
        });
      }}
      loadingServicesBool={isLoadingServices || isLoadingServicesCount}
      loadingServicesShBool={isLoadingSh || isLoadingServicesShCount}
      errorServices={errorServices !== undefined}
      errorServicesSh={errorServicesSh !== undefined}
      // Sorting
      sortStatusService={sortStatusService}
      setSortStatusService={setSortStatusService}
      sortStatusServiceSh={sortStatusServiceSh}
      setSortStatusServiceSh={setSortStatusServiceSh}
    />
  );
};

export default Services;
