import { SortOrder } from '@domain/types';

import api from '../';
import { ClientType } from '../clients/clients.api';

//Project=======================================

interface ProjectCount {
  count: number;
}

export type SortBy = 'name' | 'createdAt';

export interface ProjectApiQueryParams {
  sortBy?: SortBy;
  sortOrder?: SortOrder;
  searchQuery?: string;
  page?: number;
  pageLength?: number;
}

export interface Project {
  id: number;
  name: string;
  toNotify: boolean;
  createdAt: string;
  client: {
    id: number;
    name: string;
    surname: string;
    companyName: string;
    type: ClientType;
    agency: {
      agencyName: string;
    };
  };
}
export interface ProjectAddRequest {
  name: string;
  clientId: number;
}
export interface ProjectAddResponse {
  name: string;
  clientId: number;
}
interface ProjectPatchRequest {
  id: number;
  body: {
    name?: string;
    clientId?: number;
    toNotify?: boolean;
  };
}

export interface ProjectDetail {
  name: string;
  toNotify: boolean;
  clientId: number;
}
//project client=======================================

interface ProjectClientCount {
  count: number;
}
export interface ClientApiQueryParams {
  sortBy?: SortBy;
  sortOrder?: SortOrder;
  searchQuery?: string;
  page?: number;
  pageLength?: number;
}

export interface ProjectClientApiQueryParams {
  id: number;
  params: ClientApiQueryParams;
}

export interface ProjectClientCountApiQueryParams {
  id: number;
  searchQuery?: string;
}

interface ProjectDeleteRequest {
  id: number;
}

interface DefaultProjectResponse {
  id: number;
  name: string;
}

interface DefaultProjectRequest {
  searchQuery?: string;
}

export interface ProjectForSelector {
  id: number;
  name: string;
}

const projectsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getDefaultProjects: builder.query<
      DefaultProjectResponse[],
      DefaultProjectRequest
    >({
      query: (params) => ({
        url: 'projects/defaults',
        params,
      }),
      providesTags: ['project'],
    }),
    getProjectsForSelector: builder.query<ProjectForSelector[], void>({
      query: () => ({
        url: 'projects/all',
      }),
      providesTags: ['project'],
    }),
    getProjects: builder.query<Project[], ProjectApiQueryParams>({
      query: (params) => ({
        url: 'projects',
        params,
      }),
      providesTags: ['project'],
    }),
    getProjectCount: builder.query<ProjectCount, string | undefined>({
      query: (searchQuery) => ({
        url: 'projects/count',
        params: { searchQuery },
      }),
      providesTags: ['project'],
    }),
    postAddProject: builder.mutation<ProjectAddResponse, ProjectAddRequest>({
      query: (body) => ({
        url: 'projects',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['project'],
    }),
    patchProject: builder.mutation<ProjectAddResponse, ProjectPatchRequest>({
      query: ({ id, body }) => ({
        url: `projects/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['project'],
    }),
    deleteProject: builder.mutation<string, ProjectDeleteRequest>({
      query: ({ id }) => ({
        url: `projects/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['project'],
    }),

    //ProjectClient==========================================
    getProjectsClient: builder.query<Project[], ProjectClientApiQueryParams>({
      query: ({ params, id }) => ({
        url: `clients/${id}/projects`,
        params,
      }),
      providesTags: ['project'],
    }),

    getProjectClientCount: builder.query<
      ProjectClientCount,
      ProjectClientCountApiQueryParams
    >({
      query: ({ searchQuery, id }) => ({
        url: `clients/${id}/projects/count`,
        params: { searchQuery },
      }),
      providesTags: ['project'],
    }),
    getSingleProject: builder.query<ProjectDetail, number>({
      query: (id) => ({
        url: `projects/${id}`,
      }),
      providesTags: ['project'],
    }),
  }),
});

export const {
  useGetDefaultProjectsQuery,
  useGetProjectsForSelectorQuery,
  useGetProjectsQuery,
  useGetProjectCountQuery,
  useGetSingleProjectQuery,
  usePostAddProjectMutation,
  usePatchProjectMutation,
  useDeleteProjectMutation,
  //ProjectClient
  useGetProjectClientCountQuery,
  useGetProjectsClientQuery,
} = projectsApi;
