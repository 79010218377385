import { FC } from 'react';

import { ModalProps } from '@interfaces/modals.interface';
import { Button, Grid, Group, Text, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { closeAllModals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { handleSubmitError } from '@utils/general';

import useAuth from '@hooks/useAuth';

import UserAvatar from './UserAvatar';

interface ModalPatchCurrentUserProps extends ModalProps {
  user: {
    email?: string;
    name?: string;
    surname?: string;
    profilePicture?: string | null;
  };
}

const ModalPatchCurrentUser: FC<ModalPatchCurrentUserProps> = ({
  user,
  closeAllOnConfirm = true,
  onConfirmed,
}) => {
  // ==========================================================================
  // General
  // ==========================================================================

  // ==========================================================================
  // State
  // ==========================================================================

  // ==========================================================================
  // Handler
  // ==========================================================================

  // ==========================================================================
  // Api
  // ==========================================================================
  const { patch, isLoading } = useAuth();

  // ==========================================================================
  // Form
  // ==========================================================================
  const initialValues = {
    email: user.email,
    name: user.name,
    surname: user.surname,
  };

  const form = useForm({
    initialValues,
  });

  const onSubmit = async (values: typeof initialValues) => {
    try {
      await patch(values);

      showNotification({
        title: 'Informazioni modificate',
        message:
          'Le informazioni del tuo account sono state modificate con successo',
      });

      onConfirmed?.();

      if (closeAllOnConfirm) {
        closeAllModals();
      }
    } catch (e) {
      handleSubmitError(e, form);
    }
  };

  // ==========================================================================
  // Render
  // ==========================================================================
  return (
    <form
      onSubmit={form.onSubmit((values) => {
        onSubmit(values);
      })}
    >
      <Grid>
        {/* ROW */}
        <Grid.Col span={12}>
          <Group justify="center">
            <UserAvatar />
          </Group>
        </Grid.Col>

        {/* ROW */}
        <Grid.Col span={12}>
          <TextInput label="Email:" required {...form.getInputProps('email')} />
        </Grid.Col>

        {/* ROW */}
        <Grid.Col span={12}>
          <TextInput label="Nome:" required {...form.getInputProps('name')} />
        </Grid.Col>

        {/* ROW */}
        <Grid.Col span={12}>
          <TextInput
            label="Cognome"
            placeholder="Rossi"
            required
            {...form.getInputProps('surname')}
          />
        </Grid.Col>

        {/* ROW */}
        <Grid.Col span={8} mt="md">
          {form.errors.general && (
            <Text color="red" mt="md">
              {form.errors.general}
            </Text>
          )}
        </Grid.Col>
        <Grid.Col span={4}>
          <Button type="submit" fullWidth mt="md" loading={isLoading}>
            Conferma
          </Button>
        </Grid.Col>
      </Grid>
    </form>
  );
};

export default ModalPatchCurrentUser;
