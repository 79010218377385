import { useEffect, useMemo } from 'react';

import { useLocalStorage } from '@mantine/hooks';

export type UiScale = 'sm' | 'md' | 'lg';

const useUiScale = () => {
  const [value, setValue] = useLocalStorage<UiScale>({
    key: 'ui-scale',
    defaultValue: 'md',
  });

  useEffect(() => {
    document.documentElement.style.fontSize =
      value === 'sm' ? '90%' : value === 'md' ? '100%' : '110%';
  }, [value]);

  return useMemo(
    () => ({ scale: value, setScale: setValue }),
    [value, setValue],
  );
};

export default useUiScale;
