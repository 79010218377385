import { FC } from 'react';

import { ModalProps } from '@interfaces/modals.interface';
import { Button, Group, Image, Stack, Text } from '@mantine/core';
import { closeAllModals } from '@mantine/modals';

import {
  useGenerateWebsiteScreenshotMutation,
  WebsiteScannerSite,
} from '@api/websiteScanner/websiteScanner.api';

interface ModalWebsiteScreenshotProps extends ModalProps {
  website: WebsiteScannerSite;
  selectedWebsiteComparisonImage?: string;
}

const ModalWebsiteScreenshot: FC<ModalWebsiteScreenshotProps> = ({
  closeAllOnConfirm = true,
  onConfirmed,
  website,
  selectedWebsiteComparisonImage,
}) => {
  // ==========================================================================
  // General
  // ==========================================================================

  // ==========================================================================
  // State
  // ==========================================================================

  // ==========================================================================
  // Api
  // ==========================================================================
  const [
    generateWebsiteScreenshot,
    { isLoading: isGenerateWebsiteScreenshotLoading },
  ] = useGenerateWebsiteScreenshotMutation();

  // ==========================================================================
  // Form
  // ==========================================================================

  // ==========================================================================
  // Handlers
  // ==========================================================================

  // ==========================================================================
  // Render
  // ==========================================================================
  return (
    <>
      <Group wrap="nowrap">
        {website.screenshotUrl !== null ? (
          <Stack>
            {selectedWebsiteComparisonImage && <Text>Screenshot base</Text>}
            <Image src={website.screenshotUrl} alt={website.screenshot || ''} />
          </Stack>
        ) : (
          <Text>Immagine non caricata correttamente</Text>
        )}
        {selectedWebsiteComparisonImage && (
          <Stack>
            <Text>Screenshot ultima scansione</Text>
            <Image
              src={selectedWebsiteComparisonImage}
              alt={website.screenshot || ''}
            />
          </Stack>
        )}
      </Group>

      <Group mt="xl" justify="flex-end">
        <Button
          onClick={async () => {
            await generateWebsiteScreenshot(website.id).unwrap();

            onConfirmed?.();
            if (closeAllOnConfirm) {
              closeAllModals();
            }
          }}
          loading={isGenerateWebsiteScreenshotLoading}
        >
          Esegui nuovo screenshot base
        </Button>
      </Group>
    </>
  );
};

export default ModalWebsiteScreenshot;
