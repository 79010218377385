import { FC } from 'react';

import useLayoutProps from '@components/layout/useLayoutProps';

const Settings: FC = () => {
  useLayoutProps({ title: 'Impostazioni' });
  return (
    <>
      {/* <ModalPatchProfile
        user={{ email: user?.email, name: user?.name, surname: user?.surname }}
      />
      <ModalPatchUserPassword />

      <ModalPatchNotificationsSettings
        emailNotificationsEnabled={user!.emailNotificationsEnabled}
        pushNotificationsEnabled={user!.pushNotificationsEnabled}
      />

      <Box pos="relative">
        <Overlay />
        <EditOtpSettings />
      </Box> */}
    </>
  );
};

export default Settings;
