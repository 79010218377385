import { Fragment, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Divider, Group, Select, Text } from '@mantine/core';

import { generateDataNotifications } from '@api/notifications/norifications.mock';
import {
  NotificationsApiQueryParams,
  NotificationsFilter,
  useGetNotificationsCountQuery,
  useGetNotificationsQuery,
  useModifyNotificationMutation,
} from '@api/notifications/notifications.api';

import useLayoutProps from '@components/layout/useLayoutProps';
import NotificationCard from '@components/notificationCard/NotificationCard';
import PaginationRow from '@components/PaginationRow';

export default function Notifications() {
  // ==========================================================================
  // General
  // ==========================================================================
  const [searchParams] = useSearchParams();
  useLayoutProps({ title: 'Notifiche' });
  // ==========================================================================
  // State
  // ==========================================================================
  const [filters, setFilters] = useState<NotificationsApiQueryParams>({
    page: +(searchParams.get('page') || 1),
    pageLength: +(searchParams.get('pageLength') || 5),
    filter: undefined,
  });

  // ==========================================================================
  // Api
  // ==========================================================================

  // Get initial notifications count
  const {
    data: notificationsCount = { count: 0 },
    isLoading: isLoadingNotificationsCount,
  } = useGetNotificationsCountQuery(filters.filter);

  const {
    data = generateDataNotifications(5),
    isLoading,
    error: errorNotifications,
  } = useGetNotificationsQuery(filters);

  const [modifyNotification] = useModifyNotificationMutation();

  // Map data
  const notifications = (errorNotifications ? [] : data).map(
    (notification, index) => (
      <Fragment key={notification.id}>
        <NotificationCard
          title={notification.title}
          description={notification.description}
          read={notification.read}
          date={new Date(notification.createdAt)}
          onClick={() => {
            if (!notification.read) {
              modifyNotification({
                id: +notification.id,
                body: { read: true },
              }).unwrap();
            }

            switch (notification.category) {
              case 'serviceExpiry':
                window.location.href = notification.link; // TODO: improve this
                break;
              case 'websiteScanErrors':
                window.location.href = notification.link;
                break;
              default:
                // Nothing to do
                break;
            }
          }}
          loading={isLoading || isLoadingNotificationsCount}
        />

        {index !== data.length - 1 && <Divider mx="sm" />}
      </Fragment>
    ),
  );

  const totalPages = Math.ceil(notificationsCount.count / filters.pageLength!);

  // ==========================================================================
  // Render
  // ==========================================================================
  return (
    <>
      <Group mb="lg">
        <Select
          placeholder="Filtro notifiche"
          data={[
            { label: 'Tutte', value: 'notArchived' },
            { label: 'Non lette', value: 'notRead' },
            { label: 'Lette', value: 'read' },
            { label: 'Archiviate', value: 'archived' },
          ]}
          value={filters.filter}
          onChange={(value) =>
            setFilters({ ...filters, filter: value as NotificationsFilter })
          }
          allowDeselect={false}
        />
      </Group>
      {data.length > 0 && !errorNotifications ? (
        notifications
      ) : (
        <Text fw="bold" my="lg">
          Nessuna notifica presente per i filtri selezionati
        </Text>
      )}
      {notifications.length > 0 && (
        <PaginationRow
          page={filters.page!}
          pageLength={filters.pageLength!}
          totalPages={totalPages}
          onPageChange={(newPage) => setFilters({ ...filters, page: newPage })}
          onPageLengthChange={(newPageLength) =>
            setFilters({ ...filters, pageLength: newPageLength, page: 1 })
          }
        />
      )}
    </>
  );
}
