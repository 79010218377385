import { FC } from 'react';

import { Select } from '@mantine/core';

import { useGetAgenciesQuery } from '@api/agencies/agencies.api';

interface AgencySelectorProps {
  onChange: (value: string | null) => void;
  value: string;
  required?: boolean;
  disabled?: boolean;
}

const AgencySelector: FC<AgencySelectorProps> = ({
  onChange,
  value,
  required,
  disabled,
}) => {
  // ==========================================================================
  // Api
  // ==========================================================================
  const { data, isLoading } = useGetAgenciesQuery({});

  // ==========================================================================
  // Render
  // ==========================================================================
  return (
    <Select
      searchable
      required={required}
      label="Agenzia di riferimento: "
      placeholder="Seleziona agenzia"
      disabled={disabled || isLoading}
      nothingFoundMessage="Nessun'agenzia trovata"
      data={
        data
          ? data.map((agency) => ({
              value: agency.id.toString(),
              label: agency.agencyName,
            }))
          : []
      }
      value={value}
      onChange={onChange}
    />
  );
};

export default AgencySelector;
