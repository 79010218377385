import { DataTable } from 'mantine-datatable';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import {
  ActionIcon,
  Anchor,
  Button,
  Card,
  Divider,
  Grid,
  Group,
  rem,
  Skeleton,
  Text,
  TextInput,
  Tooltip,
  useMantineTheme,
} from '@mantine/core';
import { useLocalStorage } from '@mantine/hooks';
import { openConfirmModal, openModal } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import {
  IconAlertCircle,
  IconClockEdit,
  IconEye,
  IconEyeDotted,
  IconLayoutGrid,
  IconLayoutList,
  IconPencil,
  IconPhoto,
  IconTrash,
  IconZoomScan,
} from '@tabler/icons-react';
import { dateToDateString, dateToHourString } from '@utils/date';
import { setMinHeightTable } from '@utils/general';

import socket from '@lib/socket';

import { isApiError } from '@api/index';
import { useGetJobsStatusQuery } from '@api/jobs/jobs.api';
import {
  useAddWebsiteMutation,
  useDeleteWebsiteMutation,
  useGetWebsitesCountQuery,
  useGetWebsitesQuery,
  useLazyGetTemporaryUrlScreenshotQuery,
  useScanAllWebsitesMutation,
  useScanMultipleWebsitesMutation,
  useScanWebsiteMutation,
  WebsiteScannerSite,
  WebsiteScannerSiteParams,
} from '@api/websiteScanner/websiteScanner.api';
import { generateDataWebsiteScanner } from '@api/websiteScanner/websiteScanner.mock';

import ClickableImage from '@components/ClickableImage';
import useLayoutProps from '@components/layout/useLayoutProps';
import ModalPatchWebsite from '@components/ModalPatchWebsite';
import ModalSetWebsiteScannerCronJobTime from '@components/ModalPatchWebsiteScannerCron';
import ModalWebsiteScreenshot from '@components/ModalWebsiteScreenshot';
import PaginationRow from '@components/PaginationRow';

export default function WebsiteScanner() {
  // ==========================================================================
  // General
  // ==========================================================================
  const theme = useMantineTheme();
  const [searchParams] = useSearchParams();
  const [selectedWebsites, setSelectedWebsites] = useState<
    WebsiteScannerSite[]
  >([]);
  useLayoutProps({
    title: 'Website scanner',
  });
  // ==========================================================================
  // State
  // ==========================================================================
  const [addWebsiteUrl, setAddWebsiteUrl] = useState('');

  const [viewMode, setViewMode] = useLocalStorage<'list' | 'grid'>({
    key: 'website-scanner-view-mode',
    defaultValue: 'list',
  });

  const [filters, setFilters] = useState<WebsiteScannerSiteParams>({
    page: +(searchParams.get('page') || 1),
    pageLength: +(searchParams.get('pageLength') || 50),
  });

  const [scanAllProgress, setScanAllProgress] = useState(0);

  // ==========================================================================
  // Api
  // ==========================================================================
  const {
    data: jobsStatus = {
      websiteScanner: {
        failed: [],
        completed: [],
        inProgress: [],
      },
      servicesImporter: {
        failed: [],
        completed: [],
        inProgress: [],
      },
    },
  } = useGetJobsStatusQuery();

  const { data: websitesCount = { count: 0 } } = useGetWebsitesCountQuery();

  const {
    data: websites = generateDataWebsiteScanner(8),
    isLoading: isWebsitesLoading,
    error: errorWebsiteScanner,
  } = useGetWebsitesQuery({ withScreenshots: true, ...filters });

  const [scanWebsite, { isLoading: isScanWebsitesLoading }] =
    useScanWebsiteMutation();

  const [scanAllWebsites] = useScanAllWebsitesMutation();
  const [scanMultipleWebsites] = useScanMultipleWebsitesMutation();

  const [addWebsite, { isLoading: isAddWebsiteLoading }] =
    useAddWebsiteMutation();

  const [deleteWebsite, { isLoading: isDeleteWebsiteLoading }] =
    useDeleteWebsiteMutation();

  const [getTemporaryUrlScreenshot] = useLazyGetTemporaryUrlScreenshotQuery();

  // ==========================================================================
  // Form
  // ==========================================================================

  // ==========================================================================
  // Handlers
  // ==========================================================================
  const onAddWebsiteClick = async () => {
    try {
      await addWebsite(addWebsiteUrl).unwrap();

      setAddWebsiteUrl('');
    } catch (e) {
      console.error(e);

      if (isApiError(e)) {
        showNotification({
          color: 'red',
          title: 'Errore',
          message: e.data.errors.url,
        });
      }
    }
  };

  const onScanWebsiteClick = async (id: number) => {
    scanWebsite(id);
  };

  const onScanWebsitesClick = async () => {
    try {
      if (selectedWebsites.length > 0) {
        const ids = selectedWebsites.map((w) => w.id);
        await scanMultipleWebsites({ ids }).unwrap();
      } else {
        await scanAllWebsites().unwrap();
      }
    } catch {
      showNotification({
        color: 'red',
        title: 'Errore scansione',
        message: 'Un altra scansione è già in elaborazione',
      });
    }
  };

  const onViewWebsiteScreenshotClick = async (
    website: WebsiteScannerSite,
    getLastScanImage = false,
  ) => {
    let selectedWebsiteComparisonImage;

    if (getLastScanImage) {
      selectedWebsiteComparisonImage = (
        await getTemporaryUrlScreenshot({
          id: website.id,
          params: { lastScan: true },
        }).unwrap()
      ).temporaryImgUrl;
    }

    openModal({
      title: `Screenshot sito ${website.url}`,
      children: (
        <ModalWebsiteScreenshot
          website={website}
          selectedWebsiteComparisonImage={selectedWebsiteComparisonImage}
        />
      ),
    });
  };

  const onEditWebsiteClick = async (website: WebsiteScannerSite) => {
    openModal({
      title: 'Modifica sito web',
      children: <ModalPatchWebsite website={website} />,
      size: 'lg',
    });
  };

  const onDeleteWebsiteClick = (website: WebsiteScannerSite) => {
    openConfirmModal({
      title: 'Eliminazione sito web',
      size: 'lg',
      children: (
        <Text>
          Stai per eliminare il sito web {website.url}. Vuoi procedere?
        </Text>
      ),
      labels: {
        confirm: 'Conferma eliminazione',
        cancel: 'Annulla',
      },
      confirmProps: { color: 'red' },
      onConfirm: async () => {
        try {
          await deleteWebsite(website.id).unwrap();

          showNotification({
            title: 'Eliminazione sito web',
            message: "L'eliminazione del sito web è avvenuta con successo",
          });
        } catch (e) {
          console.error(e);
          showNotification({
            color: 'red',
            title: 'Errore',
            message: "Errore nell'eliminazione del sito.",
          });
        }
      },
    });
  };

  const onEditCronJobClick = () => {
    openModal({
      title: 'Scansione automatica',
      children: <ModalSetWebsiteScannerCronJobTime />,
      size: 'md',
    });
  };

  // Progress socket
  useEffect(() => {
    const onImportProgress = (data: {
      id: number;
      category: string;
      progress: number;
    }) => {
      if (data.category === 'websiteScanner') {
        setScanAllProgress(data.progress);
      }
    };

    // Shellrent import progress
    socket.on('job_progress', onImportProgress);

    return () => {
      socket.off('job_progress', onImportProgress);
    };
  }, []);

  // ==========================================================================
  // Render
  // ==========================================================================
  const totalPages = Math.ceil(websitesCount.count / filters.pageLength!);

  return (
    <>
      <Group justify="space-between" mb="md">
        <Group>
          <TextInput
            placeholder="Inserisci url"
            value={addWebsiteUrl}
            onChange={(e) => setAddWebsiteUrl(e.currentTarget.value)}
            rightSection={
              addWebsiteUrl !== '' && (
                <ActionIcon onClick={() => setAddWebsiteUrl('')}>
                  <IconTrash />
                </ActionIcon>
              )
            }
          />
          <Button onClick={onAddWebsiteClick} loading={isAddWebsiteLoading}>
            Aggiungi sito web
          </Button>
        </Group>
        <Group>
          <Tooltip
            label={`Visualizza ${viewMode === 'list' ? 'griglia' : 'tabella'}`}
          >
            <ActionIcon
              onClick={() => setViewMode(viewMode === 'list' ? 'grid' : 'list')}
            >
              {viewMode === 'list' ? (
                <IconLayoutGrid size="1.6rem" />
              ) : (
                <IconLayoutList size="1.6rem" />
              )}
            </ActionIcon>
          </Tooltip>
          <Button
            onClick={onScanWebsitesClick}
            loading={
              isScanWebsitesLoading ||
              jobsStatus.websiteScanner.inProgress.length > 0 ||
              false
            }
            loaderProps={
              jobsStatus.websiteScanner.inProgress.length > 0 &&
              scanAllProgress > 0 &&
              scanAllProgress < 100
                ? {
                    children: `${scanAllProgress}%`,
                  }
                : undefined
            }
            disabled={websitesCount.count === 0}
          >
            {selectedWebsites.length > 0
              ? 'Scansiona selezionati'
              : 'Scansiona tutto'}
          </Button>
          <Tooltip label="Scansione automatica">
            <ActionIcon onClick={onEditCronJobClick}>
              <IconClockEdit />
            </ActionIcon>
          </Tooltip>
        </Group>
      </Group>
      {viewMode === 'list' ? (
        <>
          <DataTable
            minHeight={setMinHeightTable(websites)}
            withRowBorders
            striped
            styles={{
              root: {
                borderRadius: theme.radius.md,
                boxShadow: theme.shadows.lg,
              },
              header: {
                backgroundColor: '#1e2023',
              },
            }}
            columns={[
              {
                accessor: 'url',
                title: 'Url',
                render: (record: WebsiteScannerSite) => (
                  <Group gap="xs">
                    {record.lastScanResultCode &&
                      record.lastScanResultCode !== '200' && (
                        <Tooltip label="L'ultima scansione ha rilevato un errore">
                          <IconAlertCircle color="red" />
                        </Tooltip>
                      )}

                    <Anchor
                      href={record.url}
                      target="_blank"
                      underline="hover"
                      c="#c9c9c9"
                      fz="sm"
                    >
                      {record.url}
                    </Anchor>
                  </Group>
                ),
              },
              {
                accessor: 'status',
                title: 'Stato',
                render: (record: WebsiteScannerSite) => (
                  <Group>
                    <Text
                      c={record.lastScanResultCode === '200' ? 'green' : 'red'}
                      fw="bold"
                    >
                      {record.lastScanResultCode || '-'}
                    </Text>
                    {record.lastScanResultCode ===
                      'Errore screenshot' /* TODO: use better error codes */ && (
                      <Tooltip label="Confronta screenshot">
                        <ActionIcon
                          onClick={() =>
                            onViewWebsiteScreenshotClick(record, true)
                          }
                        >
                          <IconEye color="red" />
                        </ActionIcon>
                      </Tooltip>
                    )}
                  </Group>
                ),
              },
              {
                accessor: 'lastScan',
                title: 'Ultima scansione',
                render: (record: WebsiteScannerSite) => (
                  <>
                    {record.lastScanResultCode
                      ? dateToDateString(new Date(record.updatedAt)) +
                        ' - ' +
                        dateToHourString(new Date(record.updatedAt))
                      : '-'}
                  </>
                ),
              },
              {
                accessor: 'action',
                title: '',
                render: (record: WebsiteScannerSite) => (
                  <Group justify="flex-end">
                    <Tooltip label="Screenshot">
                      <ActionIcon
                        onClick={() => onViewWebsiteScreenshotClick(record)}
                      >
                        <IconPhoto />
                      </ActionIcon>
                    </Tooltip>
                    <Tooltip label="Scansiona">
                      <ActionIcon
                        onClick={() => onScanWebsiteClick(record.id)}
                        loading={isScanWebsitesLoading}
                      >
                        <IconZoomScan />
                      </ActionIcon>
                    </Tooltip>
                    <Divider orientation="vertical" size={rem('0.15rem')} />
                    <ActionIcon
                      title="Modifica"
                      onClick={() => onEditWebsiteClick(record)}
                    >
                      <IconPencil />
                    </ActionIcon>
                    <ActionIcon
                      title="Elimina"
                      loading={isDeleteWebsiteLoading}
                      onClick={() => onDeleteWebsiteClick(record)}
                    >
                      <IconTrash />
                    </ActionIcon>
                  </Group>
                ),
              },
            ]}
            records={websites}
            fetching={isWebsitesLoading}
            noRecordsText={
              errorWebsiteScanner
                ? 'Errore. Ricaricare la pagina'
                : 'Non è stato aggiunto nessun sito web'
            }
            selectedRecords={selectedWebsites}
            onSelectedRecordsChange={setSelectedWebsites}
          ></DataTable>
        </>
      ) : websites.length === 0 ? (
        errorWebsiteScanner ? (
          <Text mt="xl">Errore. Ricaricare la pagina</Text>
        ) : (
          <Text mt="xl">Non è stato aggiunto nessun sito web</Text>
        )
      ) : (
        <Grid gutter="lg" mt="xl">
          {(websites as WebsiteScannerSite[]).map((website) => (
            <Grid.Col key={website.id} span={{ base: 5, sm: 4, md: 3 }}>
              <Skeleton visible={isWebsitesLoading} style={{ width: '100%' }}>
                <Card>
                  <Card.Section>
                    <ClickableImage
                      onClick={() => onViewWebsiteScreenshotClick(website)}
                      src={website.screenshotUrl}
                      style={{ height: rem('160px') }}
                      alt={website.screenshot || ''}
                    />
                  </Card.Section>

                  <Group mt="md" wrap="nowrap" gap="xs">
                    {website.lastScanResultCode &&
                      website.lastScanResultCode !== '200' && (
                        <Tooltip label="L'ultima scansione ha rilevato un errore">
                          <IconAlertCircle color="red" />
                        </Tooltip>
                      )}
                    <Text
                      w="100%"
                      style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                    >
                      <Anchor
                        href={website.url}
                        target="_blank"
                        underline="hover"
                        c="#c9c9c9"
                      >
                        {website.url}
                      </Anchor>
                    </Text>
                  </Group>

                  <Text mt="sm" fw="bold">
                    Ultima scansione:{' '}
                  </Text>
                  <Divider my={5} />
                  <Group justify="space-between">
                    <Text>
                      {website.lastScanResultCode
                        ? dateToDateString(new Date(website.updatedAt)) +
                          ' - ' +
                          dateToHourString(new Date(website.updatedAt))
                        : '-'}
                    </Text>
                    <Group>
                      <Text
                        c={
                          website.lastScanResultCode === '200' ? 'green' : 'red'
                        }
                        fw="bold"
                      >
                        {website.lastScanResultCode || '-'}
                      </Text>
                      {website.lastScanResultCode ===
                        'Errore screenshot' /* TODO: use better error codes */ && (
                        <Tooltip label="Confronta screenshot">
                          <ActionIcon
                            onClick={() =>
                              onViewWebsiteScreenshotClick(website, true)
                            }
                          >
                            <IconEyeDotted />
                          </ActionIcon>
                        </Tooltip>
                      )}
                    </Group>
                  </Group>
                  <Group justify="space-between" mt="lg">
                    <Button
                      variant="default"
                      onClick={() => onScanWebsiteClick(website.id)}
                      loading={isScanWebsitesLoading}
                      leftSection={<IconZoomScan />}
                    >
                      Scansiona ora
                    </Button>
                    <Group>
                      <ActionIcon
                        title="Modifica"
                        onClick={() => onEditWebsiteClick(website)}
                      >
                        <IconPencil />
                      </ActionIcon>
                      <ActionIcon
                        title="Elimina"
                        loading={isDeleteWebsiteLoading}
                        onClick={() => onDeleteWebsiteClick(website)}
                      >
                        <IconTrash />
                      </ActionIcon>
                    </Group>
                  </Group>
                </Card>
              </Skeleton>
            </Grid.Col>
          ))}
        </Grid>
      )}
      {websites.length > 0 && (
        <PaginationRow
          page={filters.page!}
          pageLength={filters.pageLength!}
          totalPages={totalPages}
          onPageChange={(newPage) => setFilters({ ...filters, page: newPage })}
          onPageLengthChange={(newPageLength) =>
            setFilters({ ...filters, pageLength: newPageLength, page: 1 })
          }
        />
      )}
    </>
  );
}
