import { generateMockValue } from '@utils/generateMockData';

export const generateDataProjects = (count: number) => {
  return generateMockValue({
    type: 'array',
    minLength: count,
    maxLength: count,
    each: {
      type: 'object',
      values: {
        id: 'number',
        name: 'string',
        toNotify: 'boolean',
        createdAt: 'date',
        client: {
          type: 'object',
          values: {
            id: 'number',
            name: 'string',
            surname: 'string',
            companyName: 'string',
            type: 'ClientType',
            agency: {
              type: 'object',
              values: {
                agencyName: 'string',
              },
            },
          },
        },
      },
    },
  });
};
