import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  CurrentUserPatchRequest,
  SigninRequest,
  SignupRequest,
} from '@interfaces/auth.interface';

import {
  useLogoutMutation,
  useSigninMutation,
  useSignupMutation,
} from '@api/auth/auth.api';
import {
  useDeleteCurrentUserProfilePictureMutation,
  usePatchCurrentUserMutation,
  usePatchCurrentUserProfilePictureMutation,
} from '@api/users/users.api';

import {
  logout as logoutAction,
  selectCurrentUser,
  setCredentials,
} from '@slices/auth.slice';

const useAuth = () => {
  const dispatch = useDispatch();

  const user = useSelector(selectCurrentUser);

  const [signinMutation, { isLoading: isLoadingSignin }] = useSigninMutation();
  const [signupMutation, { isLoading: isLoadingSignup }] = useSignupMutation();
  const [logoutMutation, { isLoading: isLoadingLogout }] = useLogoutMutation();
  const [patchMutation, { isLoading: isLoadingPatch }] =
    usePatchCurrentUserMutation();
  const [
    patchProfilePictureMutation,
    { isLoading: isLoadingPatchProfilePicture },
  ] = usePatchCurrentUserProfilePictureMutation();
  const [
    deleteProfilePictureMutation,
    { isLoading: isLoadingDeleteProfilePicture },
  ] = useDeleteCurrentUserProfilePictureMutation();

  const signin = useCallback(
    async (body: SigninRequest) => {
      const user = await signinMutation(body).unwrap();

      dispatch(setCredentials({ user }));
    },
    [dispatch, signinMutation],
  );

  const signup = useCallback(
    async (body: SignupRequest) => {
      await signupMutation(body).unwrap();
    },
    [signupMutation],
  );

  const logout = useCallback(async () => {
    await logoutMutation().unwrap();

    dispatch(logoutAction());
  }, [dispatch, logoutMutation]);

  const patch = useCallback(
    async (body: CurrentUserPatchRequest) => {
      //TODO verificare codice, sitemare permissions
      const patchedUser = await patchMutation(body).unwrap();
      const updatedUser = {
        ...user,
        ...patchedUser,
        permissions: user!.permissions,
      };
      dispatch(setCredentials({ user: updatedUser }));
    },
    [dispatch, patchMutation, user],
  );

  const patchProfilePicture = useCallback(
    async (body: FormData) => {
      //TODO verificare codice, sitemare permissions
      const patchedUser = await patchProfilePictureMutation(body).unwrap();
      const updatedUser = {
        ...user,
        ...patchedUser,
        permissions: user!.permissions,
      };
      dispatch(setCredentials({ user: updatedUser }));
    },
    [dispatch, patchProfilePictureMutation, user],
  );

  const deleteProfilePicture = useCallback(async () => {
    //TODO verificare codice, sitemare permissions
    const patchedUser = await deleteProfilePictureMutation().unwrap();
    const updatedUser = {
      ...user,
      ...patchedUser,
      permissions: user!.permissions,
    };
    dispatch(setCredentials({ user: updatedUser }));
  }, [dispatch, deleteProfilePictureMutation, user]);

  return useMemo(
    () => ({
      user,
      isAuthenticated: user !== null,
      signin,
      signup,
      patch,
      patchProfilePicture,
      deleteProfilePicture,
      logout,
      isLoading:
        isLoadingSignin ||
        isLoadingSignup ||
        isLoadingLogout ||
        isLoadingPatch ||
        isLoadingPatchProfilePicture ||
        isLoadingDeleteProfilePicture,
    }),
    [
      user,
      signin,
      signup,
      patch,
      patchProfilePicture,
      deleteProfilePicture,
      logout,
      isLoadingSignin,
      isLoadingSignup,
      isLoadingLogout,
      isLoadingPatch,
      isLoadingPatchProfilePicture,
      isLoadingDeleteProfilePicture,
    ],
  );
};

export default useAuth;
