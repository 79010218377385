import { FC } from 'react';

import { ModalProps } from '@interfaces/modals.interface';
import { Button, Grid, Text, TextInput, Title } from '@mantine/core';
import { useForm } from '@mantine/form';
import { closeAllModals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { handleSubmitError } from '@utils/general';

import { usePostAddAgencyMutation } from '@api/agencies/agencies.api';

type ModalAddAgencyProps = ModalProps;

const ModalAddAgency: FC<ModalAddAgencyProps> = ({
  closeAllOnConfirm = true,
  onConfirmed,
}) => {
  // ==========================================================================
  // Api
  // ==========================================================================
  const [data, { isLoading }] = usePostAddAgencyMutation();

  // ==========================================================================
  // Form
  // ==========================================================================
  const initialValues = {
    agencyName: '',
    email: '',
    phone: '',
    address: '',
    vat: '',
    province: '',
    city: '',
  };

  const form = useForm({
    initialValues,
  });

  const onSubmit = async (values: typeof initialValues) => {
    try {
      await data({
        ...values,
        province: values.province.toUpperCase(),
      }).unwrap();

      onConfirmed?.();

      if (closeAllOnConfirm) {
        closeAllModals();
      }

      showNotification({
        title: 'Agenzia aggiunta',
        message: "L'agenzia è stato aggiunta con successo",
      });
    } catch (e) {
      handleSubmitError(e, form);
    }
  };

  // ==========================================================================
  // Render
  // ==========================================================================
  return (
    <form
      onSubmit={form.onSubmit((values) => {
        onSubmit(values);
      })}
    >
      <Grid>
        {/* ROW */}
        <Grid.Col span={6}>
          <TextInput
            required
            label="Ragione sociale:"
            {...form.getInputProps('agencyName')}
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <TextInput
            label="Telefono:"
            required
            {...form.getInputProps('phone')}
          />
        </Grid.Col>

        {/* ROW */}
        <Grid.Col span={6}>
          <TextInput label="p.Iva" required {...form.getInputProps('vat')} />
        </Grid.Col>
        <Grid.Col span={6}>
          <TextInput label="Email:" required {...form.getInputProps('email')} />
        </Grid.Col>

        {/* ROW */}
        <Grid.Col>
          <Title mt="md" order={6}>
            INDIRIZZO
          </Title>
        </Grid.Col>

        {/* ROW */}
        <Grid.Col>
          <TextInput
            label="Provincia (Sigla):"
            required
            {...form.getInputProps('province')}
          />
        </Grid.Col>

        {/* ROW */}
        <Grid.Col>
          <TextInput required label="Comune:" {...form.getInputProps('city')} />
        </Grid.Col>

        {/* ROW */}
        <Grid.Col>
          <TextInput
            required
            label="Indirizzo:"
            {...form.getInputProps('address')}
          />
        </Grid.Col>

        {/* ROW */}
        <Grid.Col span={9} mt="md">
          {form.errors.general && (
            <Text color="red" mt="md">
              {form.errors.general}
            </Text>
          )}
        </Grid.Col>
        <Grid.Col span={3}>
          <Button type="submit" fullWidth mt="md" loading={isLoading}>
            Conferma
          </Button>
        </Grid.Col>
      </Grid>
    </form>
  );
};

export default ModalAddAgency;
