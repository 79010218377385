import { generateMockValue } from '@utils/generateMockData';

export const generateDataWebsiteScanner = (count: number) => {
  return generateMockValue({
    type: 'array',
    minLength: count,
    maxLength: count,
    each: {
      type: 'object',
      values: {
        id: 'number',
        name: 'string',
        url: 'string',
        lastScanResultCode: 'string',
        screenshot: 'string',
        screenshotUrl: 'string',
        screenshotEnabled: 'boolean',
        screenshotDelay: 'number',
        screenshotScroll: 'number',
        createdAt: 'string',
        updatedAt: 'string',
      },
    },
  });
};
