import api from '../';

const proformsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getProformByProject: builder.mutation<Blob, string>({
      query: (id) => ({
        url: `projects/${id}/proform`,
        responseHandler: async (response) => await response.blob(),
      }),
    }),
  }),
});

export const { useGetProformByProjectMutation } = proformsApi;
