import { ModalProps } from '@interfaces/modals.interface';
import { Divider, Grid, rem, Space, Text } from '@mantine/core';

import { ClientDetail } from '@api/clients/clients.api';

interface ModalInfoClientrops extends ModalProps {
  client: ClientDetail;
}

export function ModalInfoClient({ client }: ModalInfoClientrops) {
  // ==========================================================================
  // Render
  // ==========================================================================
  return (
    <>
      <Text fw={600} pb="xs">
        INFORMAZIONI
      </Text>
      <Divider />
      <Grid py="md">
        {/* ROW */}
        <Grid.Col span={6}>
          {client.type === 'company' ? (
            <>
              <Text size={rem('0.7rem')} fw={500} c="dimmed">
                Ragione sociale
              </Text>
              <Text>{client.companyName}</Text>
            </>
          ) : (
            <>
              <Text size={rem('0.7rem')} fw={500} c="dimmed">
                Nome
              </Text>
              <Text>{client.name}</Text>
            </>
          )}
        </Grid.Col>
        <Grid.Col span={6}>
          {client.type === 'company' ? (
            <>
              <Text size={rem('0.7rem')} fw={500} c="dimmed">
                Partita iva
              </Text>
              <Text>{client.vat?.toUpperCase() || '-'}</Text>
            </>
          ) : (
            <>
              <Text size={rem('0.7rem')} fw={500} c="dimmed">
                Codice fiscale
              </Text>
              <Text>{client.taxCode || '-'}</Text>
            </>
          )}
        </Grid.Col>

        {/* ROW */}
        {client.type === 'company' && (
          <Grid.Col span={12}>
            <Text size={rem('0.7rem')} fw={500} c="dimmed">
              Codice SDI
            </Text>
            <Text>{client.sdi || '-'}</Text>
          </Grid.Col>
        )}

        {/* ROW */}
        <Grid.Col span={12}>
          <Text fw={600} pb="xs">
            CONTATTI
          </Text>
          <Divider />
        </Grid.Col>

        {/* ROW */}
        <Grid.Col span={6}>
          <Text size={rem('0.7rem')} fw={500} c="dimmed">
            Indirizzo
          </Text>
          <Text>
            {client.address || '-'}{' '}
            {`${client.city || '-'} (${client.province || '-'})`}
          </Text>
        </Grid.Col>
        <Grid.Col span={6}>
          <Text size={rem('0.7rem')} fw={500} c="dimmed">
            Telefono
          </Text>
          <Text>{client.phone || '-'}</Text>
        </Grid.Col>

        {/* ROW */}
        <Grid.Col span={6}>
          <Text size={rem('0.7rem')} fw={500} c="dimmed">
            Email
          </Text>
          <Text>{client.email}</Text>
        </Grid.Col>
        <Grid.Col span={6}>
          {client.type === 'company' && (
            <>
              <Text size={rem('0.7rem')} fw={500} c="dimmed">
                Pec
              </Text>
              <Text>{client.pec || '-'}</Text>
            </>
          )}
        </Grid.Col>
      </Grid>

      <Space h="md" />
    </>
  );
}
