import { FC } from 'react';

import { ModalProps } from '@interfaces/modals.interface';
import { Button, Grid, Text, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { closeAllModals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { handleSubmitError } from '@utils/general';

import { usePostAddUserMutation } from '@api/users/users.api';

type ModalAddUserProps = ModalProps;

const ModalAddUser: FC<ModalAddUserProps> = ({
  closeAllOnConfirm = true,
  onConfirmed,
}) => {
  // ==========================================================================
  // General
  // ==========================================================================

  // ==========================================================================
  // State
  // ==========================================================================

  // ==========================================================================
  // Handler
  // ==========================================================================

  // ==========================================================================
  // Api
  // ==========================================================================
  const [data, { isLoading }] = usePostAddUserMutation();

  // ==========================================================================
  // Form
  // ==========================================================================
  const initialValues = {
    name: '',
    surname: '',
    email: '',
  };

  const form = useForm({
    initialValues,
    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Email non valida'),
    },
  });

  const onSubmit = async (values: typeof initialValues) => {
    try {
      await data({ ...values }).unwrap();

      showNotification({
        title: 'Utente creato',
        message:
          "L'utente è stato creato con successo. Riceverà una email per impostare la password",
      });
      onConfirmed?.();

      if (closeAllOnConfirm) {
        closeAllModals();
      }
    } catch (e) {
      handleSubmitError(e, form);
    }
  };
  // ==========================================================================
  // Render
  // ==========================================================================
  return (
    <form
      onSubmit={form.onSubmit((values) => {
        onSubmit(values);
      })}
    >
      <Grid>
        {/* ROW */}
        <Grid.Col span={12}>
          <TextInput
            color="default"
            required
            label="Nome:"
            {...form.getInputProps('name')}
          />
        </Grid.Col>
        <Grid.Col span={12}>
          <TextInput
            label="Cognome"
            required
            {...form.getInputProps('surname')}
          />
        </Grid.Col>
        <Grid.Col span={12}>
          <TextInput label="Email" required {...form.getInputProps('email')} />
        </Grid.Col>
        <Grid.Col span={8} mt="md">
          {form.errors.general && (
            <Text color="red" mt="md">
              {form.errors.general}
            </Text>
          )}
        </Grid.Col>
        <Grid.Col span={4}>
          <Button type="submit" fullWidth mt="md" loading={isLoading}>
            Conferma
          </Button>
        </Grid.Col>
      </Grid>
    </form>
  );
};

export default ModalAddUser;
