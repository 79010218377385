import {
  CurrentUserPatchRequest,
  RequestUserActivationRequest,
  User,
} from '@interfaces/auth.interface';

import { SortOrder } from '@domain/types';

import api from '../';

export type SortBy = 'name';

interface UserCount {
  count: number;
}

export interface AgencyDetail {
  agencyName: string;
  email: string;
  phone: string;
  province: string;
  cap: string;
  city: string;
  address: string;
  streetNumber: string;
  vat: string;
}

export interface UserAddResponse {
  name: string;
  surname: string;
  email: string;
}
export interface UserAddRequest {
  name: string;
  surname: string;
  email: string;
}

interface UserPatchRequest {
  id: number;
  body: {
    name?: string;
    surname?: string;
  };
}

interface ActivateUserRequest {
  token: string;
  body: {
    password: string;
  };
}

export interface UserApiQueryParams {
  sortBy?: SortBy;
  sortOrder?: SortOrder;
  searchQuery?: string;
  page?: number;
  pageLength?: number;
}

interface UserDeleteRequest {
  id: number;
}

export interface GenerateOtpResponse {
  imageUrl: string;
}

interface CheckTokenResponse {
  message: string;
}

const usersApi = api.injectEndpoints({
  endpoints: (builder) => ({
    requestActivation: builder.mutation<void, RequestUserActivationRequest>({
      query: (body) => ({
        url: `users/activate/request`,
        method: 'POST',
        body,
      }),
    }),
    activateUser: builder.mutation<void, ActivateUserRequest>({
      query: ({ token, body }) => ({
        url: `users/activate/${token}`,
        method: 'POST',
        body,
      }),
    }),
    getUsers: builder.query<User[], UserApiQueryParams>({
      query: (params) => ({
        url: 'users',
        params,
      }),
      providesTags: ['users'],
    }),
    getUsersCount: builder.query<UserCount, string | undefined>({
      query: (searchQuery) => ({
        url: 'users/count',
        params: { searchQuery },
      }),
      providesTags: ['users'],
    }),
    postAddUser: builder.mutation<UserAddResponse, UserAddRequest>({
      //prima risposta
      query: (body) => ({
        url: 'users',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['users'],
    }),
    patchCurrentUser: builder.mutation<User, CurrentUserPatchRequest>({
      query: (body) => ({
        url: 'users',
        method: 'PATCH',
        body,
      }),
    }),
    patchCurrentUserProfilePicture: builder.mutation<User, FormData>({
      query: (body) => ({
        url: 'users/profile-picture',
        method: 'PATCH',
        body,
      }),
    }),
    deleteCurrentUserProfilePicture: builder.mutation<User, void>({
      query: (body) => ({
        url: 'users/profile-picture',
        method: 'DELETE',
        body,
      }),
    }),
    patchUser: builder.mutation<User, UserPatchRequest>({
      query: ({ id, body }) => ({
        url: `users/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['users'],
    }),
    deleteUser: builder.mutation<string, UserDeleteRequest>({
      query: ({ id }) => ({
        url: `users/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['users'],
    }),
    generateOtp: builder.mutation<GenerateOtpResponse, void>({
      query: () => ({
        url: 'users/generate-otp',
        method: 'POST',
      }),
      invalidatesTags: ['users'],
    }),
    checkToken: builder.query<CheckTokenResponse, string>({
      query: (token) => ({
        url: `users/activate/${token}`,
      }),
    }),
  }),
});

export const {
  useRequestActivationMutation,
  useActivateUserMutation,
  useGetUsersQuery,
  useGetUsersCountQuery,
  usePostAddUserMutation,
  usePatchCurrentUserMutation,
  usePatchCurrentUserProfilePictureMutation,
  useDeleteCurrentUserProfilePictureMutation,
  usePatchUserMutation,
  useDeleteUserMutation,
  useGenerateOtpMutation,
  useCheckTokenQuery,
} = usersApi;
