import { FC } from 'react';
import { Link, useParams } from 'react-router-dom';

import {
  Anchor,
  Box,
  Container,
  Group,
  rem,
  Skeleton,
  Space,
  Text,
  useMantineColorScheme,
  useMantineTheme,
} from '@mantine/core';
import {
  IconApps,
  IconBoxMultiple,
  IconFileInvoice,
  IconLetterF,
  IconLetterP,
  IconMail,
  IconMapPin,
  IconPhone,
} from '@tabler/icons-react';

import { useGetSingleClientQuery } from '@api/clients/clients.api';

import useLayoutProps from '@components/layout/useLayoutProps';

import classes from './ClientDetail.module.css';

const DetailClient: FC = () => {
  const { id } = useParams();

  useLayoutProps({
    title: 'Dettaglio Cliente',
    backLink: { to: '/clienti', title: 'Elenco clienti' },
  });

  const theme = useMantineTheme();
  const { colorScheme } = useMantineColorScheme();

  const { data: clientData, isLoading } = useGetSingleClientQuery(+id!);

  return (
    <>
      {clientData ? (
        <>
          <Box visibleFrom="md">
            <div className={classes.containerGrid}>
              <div className={classes.item1}>
                <Container className={classes.anagrafe}>
                  <div>
                    <div
                      style={{
                        filter: colorScheme === 'dark' ? 'invert(1)' : 'none',
                      }}
                    >
                      <svg
                        width="129"
                        height="129"
                        viewBox="0 0 129 129"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M63.7834 111.621C90.2033 111.621 111.621 90.2033 111.621 63.7834C111.621 37.3634 90.2033 15.9458 63.7834 15.9458C37.3634 15.9458 15.9458 37.3634 15.9458 63.7834C15.9458 90.2033 37.3634 111.621 63.7834 111.621Z"
                          stroke="black"
                          strokeWidth="3"
                        />
                        <path
                          d="M63.7834 69.0986C72.5901 69.0986 79.7292 61.9594 79.7292 53.1527C79.7292 44.3462 72.5901 37.2069 63.7834 37.2069C54.9767 37.2069 47.8375 44.3462 47.8375 53.1527C47.8375 61.9594 54.9767 69.0986 63.7834 69.0986Z"
                          stroke="black"
                          strokeWidth="3"
                        />
                        <path
                          d="M32.7846 100.188C34.1001 95.8093 36.7922 91.9714 40.4611 89.2436C44.1302 86.5158 48.5808 85.0434 53.1527 85.0447H74.4139C78.9917 85.0431 83.4478 86.5191 87.1194 89.2532C90.7911 91.9873 93.4823 95.8335 94.7926 100.22"
                          stroke="black"
                          strokeWidth="3"
                        />
                      </svg>
                    </div>
                    <Skeleton visible={isLoading}>
                      {clientData.type === 'company' ? (
                        <Text ta="center">
                          Soggetto diverso da <br /> persona fisica
                        </Text>
                      ) : (
                        <Text ta="center">Persona fisica</Text>
                      )}
                    </Skeleton>
                  </div>

                  {/* Company name */}
                  <div style={{ paddingLeft: rem('2rem'), width: '100%' }}>
                    <Skeleton visible={isLoading}>
                      <Text fw="900" size="xl" mb="xs">
                        {clientData.type === 'company'
                          ? clientData.companyName!.toUpperCase()
                          : clientData.name!.toUpperCase() +
                            ' ' +
                            clientData.surname!.toUpperCase()}
                      </Text>
                    </Skeleton>

                    {/* address & provincie */}
                    <Group
                      mb="xs"
                      style={{
                        display: 'block',
                        width: '100%',
                        color:
                          colorScheme === 'dark'
                            ? theme.colors.dark[0]
                            : theme.black,
                      }}
                    >
                      <Group>
                        <IconMapPin size="1.6rem" />
                        <div style={{ flex: 1 }}>
                          <Text fw={700}>INDIRIZZO</Text>
                          <Skeleton visible={isLoading}>
                            <Text>
                              {clientData.address || 'Non inserito'}
                              {`${clientData.city || '-'} (${
                                clientData.province || '-'
                              })`}
                            </Text>
                          </Skeleton>
                        </div>
                      </Group>
                    </Group>

                    {/* partita iva */}
                    <Group
                      mb="xs"
                      style={(theme) => ({
                        display: 'block',
                        width: '100%',
                        color:
                          colorScheme === 'dark'
                            ? theme.colors.dark[0]
                            : theme.black,
                      })}
                    >
                      <Group>
                        <IconLetterP size="1.6rem" />
                        <div style={{ flex: 1 }}>
                          <Text fw={700}>PARTITA IVA</Text>
                          <Skeleton visible={isLoading}>
                            <Text>
                              {clientData.vat?.toUpperCase() || 'Non inserito'}
                            </Text>
                          </Skeleton>
                        </div>
                      </Group>
                    </Group>

                    {/* codice fiscale */}
                    <Group
                      mb="xs"
                      style={(theme) => ({
                        display: 'block',
                        width: '100%',
                        color:
                          colorScheme === 'dark'
                            ? theme.colors.dark[0]
                            : theme.black,
                      })}
                    >
                      <Group wrap="nowrap">
                        <IconLetterF size="1.6rem" />
                        <div style={{ flex: 1 }}>
                          <Text fw={700}>CODICE FISCALE</Text>
                          <Skeleton visible={isLoading}>
                            <Text>{clientData.taxCode || 'Non inserito'}</Text>
                          </Skeleton>
                        </div>
                      </Group>
                    </Group>

                    {/* servizi */}
                    <Anchor
                      component={Link}
                      mb="sm"
                      to={`/clienti/${clientData.id}/servizi/`}
                      style={{
                        textDecoration: 'none',
                        display: 'block',
                        width: '100%',
                        color:
                          colorScheme === 'dark'
                            ? theme.colors.dark[0]
                            : theme.black,
                      }}
                    >
                      <Group>
                        <IconApps size="1.6rem" />
                        <div style={{ flex: 1 }}>
                          <Text
                            fw={700}
                            style={{
                              display: 'block',
                              width: '100%',
                              color:
                                colorScheme === 'dark'
                                  ? theme.colors.dark[0]
                                  : theme.black,
                            }}
                          >
                            SERVIZI
                          </Text>
                        </div>
                      </Group>
                    </Anchor>

                    {/* progetti */}
                    <Anchor
                      component={Link}
                      to={`/clienti/${clientData.id}/progetti/`}
                      mb="md"
                      style={{
                        display: 'block',
                        width: '100%',
                        color:
                          colorScheme === 'dark'
                            ? theme.colors.dark[0]
                            : theme.black,
                      }}
                    >
                      <Group>
                        <IconBoxMultiple size="1.6rem" />
                        <div style={{ flex: 1 }}>
                          <Text
                            fw={700}
                            style={{
                              display: 'block',
                              width: '100%',
                              color:
                                colorScheme === 'dark'
                                  ? theme.colors.dark[0]
                                  : theme.black,
                            }}
                          >
                            PROGETTI
                          </Text>
                        </div>
                      </Group>
                    </Anchor>
                  </div>
                </Container>
              </div>

              <div className={classes.item2}>
                {/* telefono */}
                <Group mb="sm">
                  <Group>
                    <IconPhone size="1.6rem" />
                    <div style={{ flex: 1 }}>
                      <Text fw={700}>TELEFONO</Text>
                      <Skeleton visible={isLoading}>
                        <Text>
                          {clientData.phone ? (
                            <Anchor
                              underline="never"
                              href={`tel:${clientData.phone}`}
                              style={{
                                display: 'block',
                                width: '100%',
                                color:
                                  colorScheme === 'dark'
                                    ? theme.colors.dark[0]
                                    : theme.black,
                              }}
                            >
                              {clientData.phone}
                            </Anchor>
                          ) : (
                            'Non inserito'
                          )}
                        </Text>
                      </Skeleton>
                    </div>
                  </Group>
                </Group>

                {/* email */}
                <Group mb="sm">
                  <Group>
                    <IconMail size="1.6rem" />
                    <div style={{ flex: 1 }}>
                      <Text fw={700}>EMAIL</Text>
                      <Skeleton visible={isLoading}>
                        <Text>
                          {clientData.email ? (
                            <Anchor
                              underline="never"
                              href={`mailto:${clientData.email}`}
                              style={{
                                display: 'block',
                                width: '100%',
                                color:
                                  colorScheme === 'dark'
                                    ? theme.colors.dark[0]
                                    : theme.black,
                              }}
                            >
                              {clientData.email}
                            </Anchor>
                          ) : (
                            'Non inserito'
                          )}
                        </Text>
                      </Skeleton>
                    </div>
                  </Group>
                </Group>

                {/* pec */}
                <Group mb="sm">
                  <Group>
                    <IconMail size="1.6rem" />
                    <div style={{ flex: 1 }}>
                      <Text fw={700}>PEC</Text>
                      <Skeleton visible={isLoading}>
                        <Text>
                          {clientData.pec ? (
                            <Anchor
                              underline="never"
                              href={`mailto:${clientData.pec}`}
                              style={{
                                display: 'block',
                                width: '100%',
                                color:
                                  colorScheme === 'dark'
                                    ? theme.colors.dark[0]
                                    : theme.black,
                              }}
                            >
                              {clientData.pec}
                            </Anchor>
                          ) : (
                            'Non inserito'
                          )}
                        </Text>
                      </Skeleton>
                    </div>
                  </Group>
                </Group>

                {/* SDI */}
                <Group mb="sm">
                  <Group>
                    <IconFileInvoice size="1.6rem" />
                    <div style={{ flex: 1 }}>
                      <Text fw={700}>SDI</Text>
                      <Skeleton visible={isLoading}>
                        <Text>
                          {clientData.sdi ? (
                            <Anchor
                              underline="never"
                              href={`mailto:${clientData.sdi}`}
                              style={{
                                display: 'block',
                                width: '100%',
                                color:
                                  colorScheme === 'dark'
                                    ? theme.colors.dark[0]
                                    : theme.black,
                              }}
                            >
                              {clientData.sdi}
                            </Anchor>
                          ) : (
                            'Non inserito'
                          )}
                        </Text>
                      </Skeleton>
                    </div>
                  </Group>
                </Group>
              </div>
              <Skeleton visible={isLoading}>
                {clientData.notes ? (
                  <div className={classes.item3}>
                    <Text>{clientData.notes}</Text>
                  </div>
                ) : (
                  <div>
                    <Space h="xl" />
                    <Text>Nessun nota presente per questo cliente</Text>
                  </div>
                )}
              </Skeleton>
            </div>
          </Box>

          <Box hiddenFrom="md">
            <div className={classes.containerMedia}>
              <div>
                {/* Company name */}
                <Skeleton visible={isLoading}>
                  <Text fw={'bold'} size="md" mb="xs">
                    {clientData.type === 'company'
                      ? `${clientData.companyName!.toUpperCase()} (Soggetto diverso da Persona Fisica)`
                      : `${clientData.name!.toUpperCase()} ${clientData.surname!.toUpperCase()} (Persona Fisica)`}
                  </Text>
                </Skeleton>

                {/* address & provincie */}
                <Group
                  mb="xs"
                  style={{
                    display: 'block',
                    width: '100%',
                    color:
                      colorScheme === 'dark'
                        ? theme.colors.dark[0]
                        : theme.black,
                  }}
                >
                  <Group>
                    <IconMapPin size="1.6rem" />
                    <div style={{ flex: 1 }}>
                      <Text fw={700}>INDIRIZZO</Text>
                      <Skeleton visible={isLoading}>
                        <Text>
                          {clientData.address || 'Non inserito'}`
                          {`${clientData.city || '-'} (${
                            clientData.province || '-'
                          })`}
                        </Text>
                      </Skeleton>
                    </div>
                  </Group>
                </Group>

                {/* partita iva */}
                <Group
                  mb="xs"
                  style={{
                    display: 'block',
                    width: '100%',
                    color:
                      colorScheme === 'dark'
                        ? theme.colors.dark[0]
                        : theme.black,
                  }}
                >
                  <Group>
                    <IconLetterP size="1.6rem" />
                    <div style={{ flex: 1 }}>
                      <Text fw={700}>PARTITA IVA</Text>
                      <Skeleton visible={isLoading}>
                        <Text>
                          {clientData.vat?.toUpperCase() || 'Non inserito'}
                        </Text>
                      </Skeleton>
                    </div>
                  </Group>
                </Group>

                {/* codice fiscale */}
                <Group
                  mb="xs"
                  style={{
                    display: 'block',
                    width: '100%',
                    color:
                      colorScheme === 'dark'
                        ? theme.colors.dark[0]
                        : theme.black,
                  }}
                >
                  <Group wrap="nowrap">
                    <IconLetterF size="1.6rem" />
                    <div style={{ flex: 1 }}>
                      <Text fw={700}>CODICE FISCALE</Text>
                      <Skeleton visible={isLoading}>
                        <Text>{clientData.taxCode || 'Non inserito'}</Text>
                      </Skeleton>
                    </div>
                  </Group>
                </Group>

                {/* servizi */}
                <Anchor
                  component={Link}
                  to={`/clienti/${clientData.id}/servizi/`}
                  mb="xs"
                  style={{
                    textDecoration: 'none',
                    display: 'block',
                    width: '100%',
                    color:
                      colorScheme === 'dark'
                        ? theme.colors.dark[0]
                        : theme.black,
                  }}
                >
                  <Group>
                    <IconApps size="1.6rem" />
                    <div style={{ flex: 1 }}>
                      <Text
                        fw={700}
                        style={{
                          display: 'block',
                          width: '100%',
                          color:
                            colorScheme === 'dark'
                              ? theme.colors.dark[0]
                              : theme.black,
                        }}
                      >
                        SERVIZI
                      </Text>
                    </div>
                  </Group>
                </Anchor>

                {/* progetti */}
                <Anchor
                  component={Link}
                  to={`/cliente/${clientData.id}/progetti/`}
                  mb="xs"
                  style={{
                    textDecoration: 'none',
                    display: 'block',
                    width: '100%',
                    color:
                      colorScheme === 'dark'
                        ? theme.colors.dark[0]
                        : theme.black,
                  }}
                >
                  <Group>
                    <IconBoxMultiple size="1.6rem" />
                    <div style={{ flex: 1 }}>
                      <Text
                        fw={700}
                        style={{
                          textDecoration: 'none',
                          display: 'block',
                          width: '100%',
                          color:
                            colorScheme === 'dark'
                              ? theme.colors.dark[0]
                              : theme.black,
                        }}
                      >
                        PROGETTI
                      </Text>
                    </div>
                  </Group>
                </Anchor>
              </div>

              {/* Telefono */}
              <Group
                mb="xs"
                style={{
                  textDecoration: 'none',
                  display: 'block',
                  width: '100%',
                  color:
                    colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,
                }}
              >
                <Group>
                  <IconPhone size="1.6rem" />
                  <div style={{ flex: 1 }}>
                    <Text fw={700}>TELEFONO</Text>
                    <Skeleton visible={isLoading}>
                      <Text>
                        {clientData.phone ? (
                          <Anchor
                            underline="never"
                            href={`tel:${clientData.phone}`}
                            style={{
                              textDecoration: 'none',
                              display: 'block',
                              width: '100%',
                              color:
                                colorScheme === 'dark'
                                  ? theme.colors.dark[0]
                                  : theme.black,
                            }}
                          >
                            {clientData.phone}
                          </Anchor>
                        ) : (
                          'Non inserito'
                        )}
                      </Text>
                    </Skeleton>
                  </div>
                </Group>
              </Group>

              {/* Email */}
              <Group mb="xs">
                <Group>
                  <IconMail size="1.6rem" />
                  <div style={{ flex: 1 }}>
                    <Text fw={700}>EMAIL</Text>
                    <Skeleton visible={isLoading}>
                      <Text>
                        {clientData.email ? (
                          <Anchor
                            underline="never"
                            href={`mailto:${clientData.email}`}
                            style={{
                              textDecoration: 'none',
                              display: 'block',
                              width: '100%',
                              color:
                                colorScheme === 'dark'
                                  ? theme.colors.dark[0]
                                  : theme.black,
                            }}
                          >
                            {clientData.email}
                          </Anchor>
                        ) : (
                          'Non inserito'
                        )}
                      </Text>
                    </Skeleton>
                  </div>
                </Group>
              </Group>

              {/* Pec */}
              <Group mb="xs">
                <Group>
                  <IconMail size="1.6rem" />
                  <div style={{ flex: 1 }}>
                    <Text fw={700}>PEC</Text>
                    <Skeleton visible={isLoading}>
                      <Text>
                        {clientData.pec ? (
                          <Anchor
                            underline="never"
                            href={`mailto:${clientData.pec}`}
                            style={{
                              textDecoration: 'none',
                              display: 'block',
                              width: '100%',
                              color:
                                colorScheme === 'dark'
                                  ? theme.colors.dark[0]
                                  : theme.black,
                            }}
                          >
                            {clientData.pec}
                          </Anchor>
                        ) : (
                          'Non inserito'
                        )}
                      </Text>
                    </Skeleton>
                  </div>
                </Group>
              </Group>

              {/* Sdi */}
              <Group mb="xs">
                <Group>
                  <IconMail size="1.6rem" />
                  <div style={{ flex: 1 }}>
                    <Text fw={700}>SDI</Text>
                    <Skeleton visible={isLoading}>
                      <Text>
                        {clientData.sdi ? (
                          <Anchor
                            underline="never"
                            href={`mailto:${clientData.sdi}`}
                            style={{
                              textDecoration: 'none',
                              display: 'block',
                              width: '100%',
                              color:
                                colorScheme === 'dark'
                                  ? theme.colors.dark[0]
                                  : theme.black,
                            }}
                          >
                            {clientData.sdi}
                          </Anchor>
                        ) : (
                          'Non inserito'
                        )}
                      </Text>
                    </Skeleton>
                  </div>
                </Group>
              </Group>

              <Skeleton visible={isLoading}>
                {clientData.notes ? (
                  <div className={classes.item3}>
                    <Text>{clientData.notes}</Text>
                  </div>
                ) : (
                  <div>
                    <Space h="xl" />
                    <Text>Nessun nota presente per questo cliente</Text>
                  </div>
                )}
              </Skeleton>
            </div>
          </Box>
        </>
      ) : (
        <Text>Cliente con id {id} non trovato</Text>
      )}
    </>
  );
};

export default DetailClient;
