import { FC } from 'react';

import { Group, Skeleton, Text, Title, UnstyledButton } from '@mantine/core';
import { IconBell } from '@tabler/icons-react';
import { dateToDateString, dateToHourString } from '@utils/date';

import theme from '@styles/theme';

import classes from './Notification.module.css';

interface NotificationCardProps {
  title: string;
  description: string;
  read: boolean;
  date: Date;
  onClick: () => void;
  loading?: boolean;
}

const NotificationCard: FC<NotificationCardProps> = ({
  title,
  description,
  read,
  date,
  onClick,
  loading = false,
}) => {
  // ==========================================================================
  // General
  // ==========================================================================
  // ==========================================================================
  // Render
  // ==========================================================================
  return (
    <UnstyledButton
      className={classes.notificationButton}
      p="md"
      onClick={onClick}
      style={{ opacity: read ? 0.5 : 1, borderRadius: theme.radius?.md }}
    >
      <Skeleton visible={loading} style={{ width: '100%' }}>
        <Group justify="space-between">
          <Group>
            <div
              className={classes.notificationIcon}
              style={{ backgroundColor: theme.colors?.gray?.[2] }}
            >
              <IconBell />
            </div>
            <div>
              <Title order={5} style={{ fontWeight: read ? 'normal' : '' }}>
                {title}
              </Title>
              <Text>{description}</Text>
            </div>
          </Group>
          <div>
            <Text ta="right">{dateToDateString(date)}</Text>
            <Text ta="right">{dateToHourString(date)}</Text>
          </div>
        </Group>
      </Skeleton>
    </UnstyledButton>
  );
};

export default NotificationCard;
