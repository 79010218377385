import api from '../';

interface UserSettings {
  key: string;
  value: string;
}

interface UserSettingsModifyParams {
  settings: {
    key: string;
    value: string;
  }[];
}

interface UserSettingsModifyResponse {
  message: string;
}

const userSettingsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getUserSettings: builder.query<UserSettings[], void>({
      query: () => ({
        url: 'user-settings',
      }),
      providesTags: ['userSettings'],
    }),
    modifyUserSettings: builder.mutation<
      UserSettingsModifyResponse,
      UserSettingsModifyParams
    >({
      query: (body) => ({
        url: 'user-settings',
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['userSettings'],
    }),
  }),
});

export const { useGetUserSettingsQuery, useModifyUserSettingsMutation } =
  userSettingsApi;
