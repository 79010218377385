import { ActionIcon, TextInput } from '@mantine/core';
import { IconSearch, IconTrash } from '@tabler/icons-react';

import classes from './SearchBar.module.css';

interface SearchBarProps {
  placeholder: string;
  value?: string;
  onChange: (newValue: string) => void;
}

const SearchBar: React.FC<SearchBarProps> = ({
  placeholder,
  value,
  onChange,
}) => {
  // ==========================================================================
  // General
  // ==========================================================================

  // ==========================================================================
  // Render
  // ==========================================================================

  return (
    <TextInput
      placeholder={placeholder}
      leftSection={<IconSearch size="1rem" />}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      className={classes.textInput}
      rightSection={
        value !== '' && (
          <ActionIcon onClick={() => onChange('')}>
            <IconTrash size="1.3rem" />
          </ActionIcon>
        )
      }
    />
  );
};

export default SearchBar;
