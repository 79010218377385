import { FC } from 'react';

import { ModalProps } from '@interfaces/modals.interface';
import { Button, Grid, PasswordInput, Text } from '@mantine/core';
import { useForm } from '@mantine/form';
import { closeAllModals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { handleSubmitError } from '@utils/general';

import useAuth from '@hooks/useAuth';

type ModalPatchUserPassword = ModalProps;

const ModalPatchUserPassword: FC<ModalPatchUserPassword> = ({
  closeAllOnConfirm = true,
  onConfirmed,
}) => {
  // ==========================================================================
  // General
  // ==========================================================================

  // ==========================================================================
  // State
  // ==========================================================================

  // ==========================================================================
  // Handler
  // ==========================================================================

  // ==========================================================================
  // Api
  // ==========================================================================
  const { patch, isLoading } = useAuth();

  // ==========================================================================
  // Form
  // ==========================================================================
  const initialValues = {
    oldPassword: '',
    password: '',
    passwordConfirm: '',
  };

  const form = useForm({
    initialValues,
  });

  const onSubmit = async (values: typeof initialValues) => {
    try {
      await patch(values);

      showNotification({
        title: 'Password modificata',
        message: 'La password del tuo account è stata modificata con successo',
      });

      onConfirmed?.();

      if (closeAllOnConfirm) {
        closeAllModals();
      }
    } catch (e) {
      handleSubmitError(e, form);
    }
  };

  // ==========================================================================
  // Render
  // ==========================================================================
  return (
    <form
      onSubmit={form.onSubmit((values) => {
        onSubmit(values);
      })}
    >
      <Grid>
        {/* ROW */}
        <Grid.Col span={12}>
          <PasswordInput
            label="Vecchia password:"
            required
            {...form.getInputProps('oldPassword')}
          />
        </Grid.Col>
        <Grid.Col span={12}>
          <PasswordInput
            label="Nuova password:"
            required
            {...form.getInputProps('password')}
          />
        </Grid.Col>

        {/* ROW */}
        <Grid.Col span={12}>
          <PasswordInput
            label="Ripeti password:"
            required
            {...form.getInputProps('passwordConfirm')}
          />
        </Grid.Col>

        {/* ROW */}
        <Grid.Col span={8} mt="md">
          {form.errors.general && (
            <Text color="red" mt="md">
              {form.errors.general}
            </Text>
          )}
        </Grid.Col>
        <Grid.Col span={4}>
          <Button type="submit" fullWidth mt="md" loading={isLoading}>
            Conferma
          </Button>
        </Grid.Col>
      </Grid>
    </form>
  );
};

export default ModalPatchUserPassword;
