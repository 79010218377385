import { List, rem, Text } from '@mantine/core';

interface ModalConfirmImportServiceShProps {
  data: {
    newServices: number;
    updateServices: number;
    deleteServices: number;
  };
}

export default function ModalConfirmImportServiceSh({
  data,
}: ModalConfirmImportServiceShProps) {
  // ==========================================================================
  // General
  // ==========================================================================

  // ==========================================================================
  // State
  // ==========================================================================

  // ==========================================================================
  // Api
  // ==========================================================================

  // ==========================================================================
  // Form
  // ==========================================================================

  // ==========================================================================
  // Handlers
  // ==========================================================================

  // ==========================================================================
  // Render
  // ==========================================================================
  return (
    <>
      <Text>Sei sicuro di gestire i servizi selezionati?</Text>
      <List pl={rem('1.5rem')}>
        {data.deleteServices > 0 && (
          <List.Item>
            <Text fs="italic">
              Stai per eliminare {data.deleteServices} servizi
            </Text>
            <Text fs="italic" c="dimmed" fz="sm">
              Tutti i servizi secondari collegati verrano cancellati
            </Text>
          </List.Item>
        )}
        {data.newServices > 0 && (
          <List.Item fs="italic">
            Stai per importare {data.newServices} servizi
          </List.Item>
        )}

        {data.updateServices > 0 && (
          <List.Item fs="italic">
            Stai per aggiornare {data.updateServices} servizi
          </List.Item>
        )}
      </List>
    </>
  );
}
