import { SortOrder } from '@domain/types';

import api from '../';

export type SortBy = 'agencyName' | 'email';

interface AgencyCount {
  count: number;
}

export interface AgencyDetail {
  agencyName: string;
  email: string;
  phone: string;
  province: string;
  city: string;
  address: string;
  vat: string;
  toNotify: boolean;
}

export interface AgencyAddResponse {
  agencyName: string;
  email: string;
  phone: string;
  province: string;
  city: string;
  address: string;
  vat: string;
}
export interface AgencyAddRequest {
  agencyName: string;
  email: string;
  phone: string;
  province: string;
  city: string;
  address: string;
  vat: string;
}

interface AgencyPatchRequest {
  id: number;
  body: {
    agencyName?: string;
    email?: string;
    phone?: string;
    province?: string;
    city?: string;
    address?: string;
    vat?: string;
    toNotify?: boolean;
  };
}

export interface Agency {
  id: number;
  agencyName: string;
  email: string;
  phone: string;
  toNotify: boolean;
  province: string;
  city: string;
  address: string;
  vat: string;
}

export interface AgencyForSelector {
  id: number;
  agencyName: string;
}

export interface AgencyApiQueryParams {
  sortBy?: SortBy;
  sortOrder?: SortOrder;
  searchQuery?: string;
  page?: number;
  pageLength?: number;
}
interface AgencyDeleteRequest {
  id: number;
}

const agencyApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAgencies: builder.query<Agency[], AgencyApiQueryParams>({
      query: (params) => ({
        url: 'agencies',
        params,
      }),
      providesTags: ['agency'],
    }),
    getAgenciesCount: builder.query<AgencyCount, string | undefined>({
      query: (searchQuery) => ({
        url: 'agencies/count',
        params: { searchQuery },
      }),
      providesTags: ['agency'],
    }),
    postAddAgency: builder.mutation<AgencyAddResponse, AgencyAddRequest>({
      //prima risposta
      query: (body) => ({
        url: 'agencies',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['agency'],
    }),
    patchAgency: builder.mutation<AgencyDetail, AgencyPatchRequest>({
      query: ({ id, body }) => ({
        url: `agencies/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['agency'],
    }),
    deleteAgency: builder.mutation<string, AgencyDeleteRequest>({
      query: ({ id }) => ({
        url: `agencies/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['agency'],
    }),
  }),
});

export const {
  useGetAgenciesQuery,
  useGetAgenciesCountQuery,
  usePatchAgencyMutation,
  usePostAddAgencyMutation,
  useDeleteAgencyMutation,
} = agencyApi;
