import api from '../';

type VersionType = 'major' | 'minor' | 'patch';

export interface Changelog {
  version: string;
  versionType: VersionType;
  bugFixes: string | null;
  features: string | null;
  createdAt: string;
}

const changelogsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getChangelogs: builder.query<Changelog[], void>({
      query: () => ({
        url: 'changelogs',
      }),
    }),
  }),
});

export const { useLazyGetChangelogsQuery } = changelogsApi;
