import { FC } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

import {
  Anchor,
  Button,
  Container,
  LoadingOverlay,
  Paper,
  PasswordInput,
  Text,
  Title,
  useMantineColorScheme,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { handleSubmitError } from '@utils/general';

import {
  useCheckTokenPasswordQuery,
  usePasswordResetMutation,
} from '@api/passwordReset/passwordReset.api';

import logo from '@images/logo-black.svg';
import logoWhite from '@images/logo.svg';

import classes from './PasswordReset.module.css';

const PasswordReset: FC = () => {
  // ==========================================================================
  // General
  // ==========================================================================

  const { colorScheme } = useMantineColorScheme();

  const { token } = useParams();

  const navigate = useNavigate();
  // ==========================================================================
  // State
  // ==========================================================================

  // ==========================================================================
  // Api
  // ==========================================================================
  const { isError, isLoading: isLoadingCheckToken } =
    useCheckTokenPasswordQuery(token!);

  const [passwordReset, { isLoading }] = usePasswordResetMutation();

  // ==========================================================================
  // Form
  // ==========================================================================
  const initialValues = {
    password: '',
    passwordConfirm: '',
  };

  const form = useForm({
    initialValues,
    validate: {
      passwordConfirm: (value, values) =>
        value !== values.password ? 'Le password non corrispondono.' : null,
    },
  });

  const onSubmit = async (values: typeof initialValues) => {
    try {
      await passwordReset({
        token: token!,
        body: { password: values.password },
      }).unwrap();

      showNotification({
        title: 'Password modificata',
        message: 'La tua password è stata reimpostata con successo',
      });

      navigate('/');
    } catch (e) {
      handleSubmitError(e, form);
    }
  };

  // ==========================================================================
  // Render
  // ==========================================================================

  return (
    <>
      <LoadingOverlay visible={isLoadingCheckToken} />
      <Container size={420} my={120}>
        {colorScheme === 'light' ? (
          <img src={logo} alt="Miroir Studio" className={classes.logo} />
        ) : (
          <img src={logoWhite} alt="Miroir Studio" className={classes.logo} />
        )}

        <Text color="dimmed" ta="center" mt="xl">
          Sai già la tua password?{' '}
          <Anchor to="/" component={Link}>
            Accedi
          </Anchor>
        </Text>
        <Paper p={30} mt={30} className={classes.pw_paper}>
          {isError ? (
            <>
              <Text>Link di ripristino password scaduto.</Text>
              <Anchor to="/password-reset" component={Link}>
                Richiedine uno nuovo
              </Anchor>
            </>
          ) : (
            <form
              onSubmit={form.onSubmit((values) => {
                onSubmit(values);
              })}
            >
              <Title order={2} ta="center" mb="lg">
                Reset della password
              </Title>
              <Text mb="lg">Inserisci una nuova password.</Text>
              <PasswordInput
                label="Nuova password"
                placeholder="La tua nuova password"
                required
                {...form.getInputProps('password')}
              />
              <PasswordInput
                mt="xs"
                label="Ripeti password"
                placeholder="La tua nuova password"
                required
                {...form.getInputProps('passwordConfirm')}
              />
              {form.errors.general && (
                <Text color="red" p="8px">
                  {form.errors.general}
                </Text>
              )}
              <Button type="submit" fullWidth mt="xl" loading={isLoading}>
                Conferma
              </Button>
            </form>
          )}
        </Paper>
      </Container>
    </>
  );
};

export default PasswordReset;
