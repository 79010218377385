import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RouterProvider } from 'react-router-dom';

import { Center, Loader } from '@mantine/core';
import { openModal } from '@mantine/modals';

import { useGetCurrentUserQuery } from '@api/auth/auth.api';
import { usePatchCurrentUserMutation } from '@api/users/users.api';

import { setCredentials } from '@slices/auth.slice';

import ModalChangelog from '@components/ModalChangelog';

import router from '@routes/router';

export default function App() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const { data: user, isLoading } = useGetCurrentUserQuery();
  const [patchUser] = usePatchCurrentUserMutation();

  // Get user when page is reloaded
  useEffect(() => {
    const f = async () => {
      if (!isLoading) {
        try {
          if (user) {
            dispatch(setCredentials({ user }));

            if (user.changelog) {
              openModal({
                title: 'Novità nuova versione',
                children: (
                  <ModalChangelog
                    changelogs={[user.changelog]}
                    onConfirmed={async () => {
                      await patchUser({
                        lastSeenChangelogsVersion: user.changelog.version,
                      }).unwrap();
                    }}
                  />
                ),
                withCloseButton: false,
                closeOnClickOutside: false,
                closeOnEscape: false,
                size: 'xl',
              });
            }
          }

          // Get push not token
          // TODO: improve this. do not call api on every load
          // const token = await getPushNotificationsToken();

          // await modifyUser({ fcmToken: token }).unwrap();
        } catch {
          // Nothing to do
        }

        setLoading(false);
      }
    };

    f();
  }, [dispatch, user, isLoading, patchUser]);

  return loading ? (
    <Center style={{ height: '100vh' }}>
      <Loader />
    </Center>
  ) : (
    <RouterProvider router={router} />
  );
}
