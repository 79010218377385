import { useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';

export interface LayoutProps {
  title: string;
  loadingText?: string;
  backLink?: {
    title: string;
    to: string;
  };
  banner?: { title: string; content: string };
}

export interface ContextType {
  setTitle: (title: string) => void;
  setBackLink: (backLink: { title: string; to: string } | undefined) => void;
  setBanner: (banner: { title: string; content: string } | undefined) => void;
  setLoadingText: (loadingText: string | undefined) => void;
}

export default function useLayoutProps(props: LayoutProps) {
  const { setTitle, setBackLink, setBanner, setLoadingText } =
    useOutletContext<ContextType>();

  useEffect(() => {
    setTitle(props.title);
    if (props.backLink) {
      setBackLink({
        title: props.backLink.title,
        to: props.backLink.to,
      });
    }
    if (props.banner) {
      setBanner({
        title: props.banner.title,
        content: props.banner.content,
      });
    }
    setLoadingText(props.loadingText);
    return () => {
      setTitle('');
      setBackLink(undefined);
      setBanner(undefined);
      setLoadingText(undefined);
    };
  }, [
    props.backLink,
    props.backLink?.title,
    props.backLink?.to,
    props.banner,
    props.banner?.content,
    props.banner?.title,
    props.loadingText,
    props.title,
    setBackLink,
    setBanner,
    setLoadingText,
    setTitle,
  ]);
}
