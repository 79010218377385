import { UseFormReturnType } from '@mantine/form';

import { isApiError } from '@api/index';

export const handleSubmitError = <T>(
  e: unknown,
  form: UseFormReturnType<T>,
) => {
  if (isApiError(e)) {
    if (e.status === 400) {
      form.setErrors({ general: e.data.message, ...e.data.errors });
    } else if (e.status === 401) {
      form.setErrors({ general: e.data.message });
    } else {
      console.error(e);
      form.setErrors({
        general: 'Errore inatteso. Per favore riprova più tardi.',
      });
    }
  } else {
    console.error(e);
    form.setErrors({
      general: 'Errore inatteso. Per favore riprova più tardi.',
    });
  }
};

export const setMinHeightTable = <T>(arr: T[]): number =>
  arr.length > 0 ? 0 : 120;
