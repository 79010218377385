import React from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';

import {
  Anchor,
  Button,
  Container,
  Paper,
  Text,
  TextInput,
  Title,
  useMantineColorScheme,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { handleSubmitError } from '@utils/general';

import { usePasswordResetRequestMutation } from '@api/passwordReset/passwordReset.api';

import useAuth from '@hooks/useAuth';

import logo from '@images/logo-black.svg';
import logoWhite from '@images/logo.svg';

import classes from './PasswordResetRequest.module.css';

const PasswordResetRequest: React.FC = () => {
  // ==========================================================================
  // General
  // ==========================================================================

  const { colorScheme } = useMantineColorScheme();

  const { user } = useAuth();

  const navigate = useNavigate();
  // ==========================================================================
  // State
  // ==========================================================================

  // ==========================================================================
  // Api
  // ==========================================================================
  const [passwordResetRequest, { isLoading }] =
    usePasswordResetRequestMutation();
  // ==========================================================================
  // Form
  // ==========================================================================
  const initialValues = {
    email: '',
  };

  const form = useForm({
    initialValues,
  });

  const onSubmit = async (values: typeof initialValues) => {
    try {
      await passwordResetRequest(values).unwrap();

      showNotification({
        title: 'Richiesta ripristino effettuata',
        message:
          "Se l'email inserita è corretta riceverai un messaggio con un link per reimpostare la tua password",
      });

      navigate('/');
    } catch (e) {
      handleSubmitError(e, form);
    }
  };
  // ==========================================================================
  // Render
  // ==========================================================================

  return user ? (
    <Navigate to="/ordini" replace={true} />
  ) : (
    <Container size={420} my={120}>
      {colorScheme === 'light' ? (
        <img src={logo} alt="Miroir Studio" className={classes.logo} />
      ) : (
        <img src={logoWhite} alt="Miroir Studio" className={classes.logo} />
      )}

      <Text color="dimmed" ta="center" mt="xl">
        Sai già la tua password?{' '}
        <Anchor to="/" component={Link}>
          Accedi
        </Anchor>
      </Text>
      <Paper p={30} mt={30} className={classes.pw_paper}>
        <form
          onSubmit={form.onSubmit((values) => {
            onSubmit(values);
          })}
        >
          <Title order={2} ta="center" mb="lg">
            Recupera password
          </Title>
          <Text mb="lg">
            Inserisci l'email con la quale ti sei registrato e riceverai un link
            per ripristinare la tua password.
          </Text>
          <TextInput
            label="Email"
            placeholder="email@example.com"
            required
            {...form.getInputProps('email')}
          />
          {form.errors.general && (
            <Text color="red" p="8px">
              {form.errors.general}
            </Text>
          )}

          <Button type="submit" fullWidth mt="xl" loading={isLoading}>
            Recupera password
          </Button>
        </form>
      </Paper>
    </Container>
  );
};

export default PasswordResetRequest;
