import { ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { UserPermissions } from '@interfaces/auth.interface';
import { Center, Title } from '@mantine/core';

import useAuth from '@hooks/useAuth';
import useUserPermission from '@hooks/useUserPermission';

import { LOGIN_PATH } from '@routes/router';

export default function RequirePermissionRoute({
  permission,
  element,
}: {
  permission: UserPermissions;
  element: ReactNode;
}) {
  const location = useLocation();

  const { isAuthenticated } = useAuth();

  const hasPermission = useUserPermission(permission);

  return isAuthenticated ? (
    hasPermission ? (
      element
    ) : (
      <>
        <Center>
          <Title>Non puoi accedere a questa pagina</Title>
        </Center>
      </>
    )
  ) : (
    <Navigate to={`${LOGIN_PATH}/?r=${location.pathname}`} />
  );
}
