import api from '../';

export interface WebsiteScannerSite {
  id: number;
  url: string;
  lastScanResultCode: string | null;
  screenshot: string | null;
  screenshotUrl: string | null;
  screenshotEnabled: boolean;
  screenshotDelay: number;
  screenshotScroll: number;
  createdAt: string;
  updatedAt: string;
}

export interface WebsiteScannerSitesPatchRequest {
  id: number;
  body: {
    url?: string;
    screenshotEnabled?: boolean;
    screenshotDelay?: number;
    screenshotScroll?: number;
  };
}

interface ScanMultipleWebsitesResponse {
  message: string;
}

interface ScanMultipleWebsitesRequest {
  ids: number[];
}

interface WebsiteScreenshotResponse {
  id: number;
  temporaryImgUrl: string;
  url: string;
}

export interface WebsiteScannerSiteParams {
  withScreenshots?: boolean;
  page?: number;
  pageLength?: number;
}

interface WebsiteScannerScreenshotParams {
  id: number;
  params: {
    lastScan?: boolean;
  };
}

export interface WebsitesCount {
  count: number;
}

const websiteScannerApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getWebsitesCount: builder.query<WebsitesCount, void>({
      query: () => ({
        url: 'website-scanner/sites/count',
      }),
      providesTags: ['websites'],
    }),
    getWebsites: builder.query<WebsiteScannerSite[], WebsiteScannerSiteParams>({
      query: (params) => ({
        url: 'website-scanner/sites',
        params,
      }),
      providesTags: ['websites'],
    }),
    editWebsite: builder.mutation<
      WebsiteScannerSite,
      WebsiteScannerSitesPatchRequest
    >({
      query: ({ id, body }) => ({
        url: `website-scanner/sites/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['websites'],
    }),
    scanWebsite: builder.mutation<WebsiteScannerSite, number>({
      query: (id) => ({
        url: `website-scanner/scan/${id}`,
        method: 'POST',
      }),
      invalidatesTags: ['websites'],
    }),
    scanAllWebsites: builder.mutation<ScanMultipleWebsitesResponse, void>({
      query: () => ({
        url: 'website-scanner/scan/all',
        method: 'POST',
      }),
      invalidatesTags: ['websites'],
    }),
    scanMultipleWebsites: builder.mutation<
      ScanMultipleWebsitesResponse,
      ScanMultipleWebsitesRequest
    >({
      query: (body) => ({
        url: 'website-scanner/scan/multiple',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['websites'],
    }),
    addWebsite: builder.mutation<WebsiteScannerSite, string>({
      query: (url) => ({
        url: `website-scanner/sites`,
        method: 'POST',
        body: { url },
      }),
      invalidatesTags: ['websites'],
    }),
    generateWebsiteScreenshot: builder.mutation<WebsiteScannerSite, number>({
      query: (id) => ({
        url: `website-scanner/sites/${id}/screenshot`,
        method: 'POST',
      }),
      invalidatesTags: ['websites'],
    }),
    deleteWebsite: builder.mutation<WebsiteScannerSite, number>({
      query: (id) => ({
        url: `website-scanner/sites/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['websites'],
    }),
    getTemporaryUrlScreenshot: builder.query<
      WebsiteScreenshotResponse,
      WebsiteScannerScreenshotParams
    >({
      query: ({ id, params }) => ({
        url: `website-scanner/sites/${id}/image`,
        params,
      }),
    }),
  }),
});

export const {
  useGetWebsitesCountQuery,
  useGetWebsitesQuery,
  useScanWebsiteMutation,
  useScanAllWebsitesMutation,
  useAddWebsiteMutation,
  useGenerateWebsiteScreenshotMutation,
  useDeleteWebsiteMutation,
  useLazyGetTemporaryUrlScreenshotQuery,
  useEditWebsiteMutation,
  useScanMultipleWebsitesMutation,
} = websiteScannerApi;
