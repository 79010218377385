import React, { useState } from 'react';
import { Link, Navigate, useSearchParams } from 'react-router-dom';

import {
  Anchor,
  Button,
  Container,
  Group,
  NumberInput,
  Paper,
  PasswordInput,
  Text,
  TextInput,
  useMantineColorScheme,
} from '@mantine/core';
import { useForm } from '@mantine/form';

import { isApiError } from '@api/index';

import useAuth from '@hooks/useAuth';

import logo from '@images/logo-black.svg';
import logoWhite from '@images/logo.svg';

import classes from './Login.module.css';

const Login: React.FC = () => {
  // ==========================================================================
  // General
  // ==========================================================================

  const { colorScheme } = useMantineColorScheme();

  const [searchParams] = useSearchParams();

  const { isAuthenticated, signin, isLoading } = useAuth();

  // ==========================================================================
  // State
  // ==========================================================================
  const [displayOtpField, setDisplayOtpField] = useState(false);

  // ==========================================================================
  // Api
  // ==========================================================================

  // ==========================================================================
  // Form
  // ==========================================================================
  const initialValues = {
    email: '',
    password: '',
    // otp: '',
    // recaptchaToken: '',

    // termsOfService ck di qualcosa
  };

  const form = useForm({
    initialValues,
    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Email non valida'),
    },
  });

  const tryLogin = async (values: typeof initialValues) => {
    try {
      await signin({
        ...values,
        // otp: displayOtpField ? values.otp.toString() : undefined,
      });
    } catch (e) {
      if (isApiError(e)) {
        if (e.status === 401) {
          if (e.data.message === 'require-otp') {
            setDisplayOtpField(true);
            form.setErrors({ general: 'Inserire il codice di controllo' });
          } else {
            form.setErrors({ general: e.data.message });
          }
        } else if (e.status === 400) {
          form.setErrors({ general: e.data.message, ...e.data.errors });
        } else {
          console.error(e);
          form.setErrors({
            general: 'Errore inatteso. Per favore riprova più tardi.',
          });
        }
      }
    }
  };

  const onSubmit = (values: typeof initialValues) => {
    // if (env.NODE_ENV === 'production') {
    //   // @ts-ignore
    //   window['grecaptcha'].ready(function () {
    //     // @ts-ignore
    //     window['grecaptcha']
    //       .execute(env.RECAPTCHA_PUBLIC_KEY, { action: 'submit' })
    //       .then((token: string) => {
    //         tryLogin({ ...values, recaptchaToken: token });
    //       });
    //   });
    // } else {
    tryLogin(values);
    // }
  };
  // ==========================================================================
  // Render
  // ==========================================================================

  return isAuthenticated ? (
    <Navigate to={searchParams.get('r') || '/'} replace={true} />
  ) : (
    <Container size={420} my={120}>
      {colorScheme === 'light' ? (
        <img src={logo} alt="Miroir Studio" className={classes.logo} />
      ) : (
        <img src={logoWhite} alt="Miroir Studio" className={classes.logo} />
      )}

      <Paper withBorder shadow="xl" p={30} mt={50} radius="md">
        <form onSubmit={form.onSubmit((values) => onSubmit(values))}>
          <TextInput
            label="Email"
            placeholder="example@miroirstudio.com"
            required
            {...form.getInputProps('email')}
          />
          <PasswordInput
            label="Password"
            placeholder="La tua password"
            required
            mt="md"
            {...form.getInputProps('password')}
          />
          {displayOtpField && (
            <NumberInput
              label="Codice di controllo"
              placeholder="123456"
              hideControls
              {...form.getInputProps('otp')}
            />
          )}
          <Group justify="space-between" mt="md">
            <Anchor
              to="/password-reset"
              component={Link}
              c="dimmed"
              style={{
                display: 'block',
                width: '100%',
              }}
            >
              Password dimenticata?
            </Anchor>
          </Group>
          {form.errors.general && (
            <Text color="red" p="8px">
              {form.errors.general}
            </Text>
          )}
          <Button type="submit" fullWidth mt="xl" loading={isLoading}>
            Accedi
          </Button>
        </form>
      </Paper>
    </Container>
  );
};

export default Login;
