import { generateMockValue } from '@utils/generateMockData';

export const generateAgenciesMock = (count: number) => {
  return generateMockValue({
    type: 'array',
    minLength: count,
    maxLength: count,
    each: {
      type: 'object',
      values: {
        id: 'number',
        agencyName: 'string',
        email: 'string',
        phone: 'string',
        toNotify: 'boolean',
        province: 'string',
        city: 'string',
        address: 'string',
        vat: 'string',
      },
    },
  });
};
