import { useMemo } from 'react';

import { useLocalStorage } from '@mantine/hooks';

const useTheme = () => {
  const [value, setValue] = useLocalStorage<string>({
    key: 'theme-primary-color',
    defaultValue: '#a28b75',
  });

  return useMemo(
    () => ({ primaryColor: value, setPrimaryColor: setValue }),
    [setValue, value],
  );
};

export default useTheme;
