import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { showNotification } from '@mantine/notifications';

import socket from '@lib/socket';

import api from '@api/index';

const getNotificationStartedPaylodFromCategory = (
  category: string,
): { title: string; message: string } => {
  switch (category) {
    case 'websiteScanner':
      return {
        title: 'Scansione avviata',
        message: 'Scansione siti web avviata con successo',
      };
    case 'servicesImporter':
      return {
        title: 'Importazione avviata',
        message: 'Importazione servizi avviata con successo',
      };
    default:
      return {
        title: 'Job avviato',
        message: 'Job avviato con successo',
      };
  }
};

const getNotificationCompletedPaylodFromCategory = (
  category: string,
): { title: string; message: string } => {
  switch (category) {
    case 'websiteScanner':
      return {
        title: 'Scansione completata',
        message: 'Scansione siti web completata con successo',
      };
    case 'servicesImporter':
      return {
        title: 'Importazione completata',
        message: 'Importazione servizi compeltata con successo',
      };
    default:
      return {
        title: 'Job completato',
        message: 'Job completato con successo',
      };
  }
};

const getNotificationFailedPaylodFromCategory = (
  category: string,
): { title: string; message: string } => {
  switch (category) {
    case 'websiteScanner':
      return {
        title: 'Scansione fallita',
        message: 'La scansione è fallita',
      };
    case 'servicesImporter':
      return {
        title: 'Importazione fallita',
        message: "L'importazione è fallita",
      };
    default:
      return {
        title: 'Job fallito',
        message: 'Il job è fallito',
      };
  }
};

export default function GlobalSocketHandler() {
  const dispatch = useDispatch();

  // Notification socket
  useEffect(() => {
    const onJobStarted = (data: { id: number; category: string }) => {
      const category = data.category;

      if (data) {
        showNotification({
          ...getNotificationStartedPaylodFromCategory(category),
          style: { cursor: 'pointer' },
          // onClick: () => {
          //   window.location.href = '/jobs';
          // },
        });

        dispatch(api.util.invalidateTags(['jobs']));
      }
    };

    // Website scaner completed
    socket.on('job_started', onJobStarted);

    const onJobCompleted = (data: { id: number; category: string }) => {
      // const id = data.id;
      const category = data.category;

      if (data) {
        showNotification({
          ...getNotificationCompletedPaylodFromCategory(category),
          style: { cursor: 'pointer' },

          // onClick: () => {
          //   window.location.href = `/jobs/${category}/${id}`;
          // },
        });

        dispatch(api.util.invalidateTags(['jobs']));

        if (category === 'websiteScanner') {
          dispatch(api.util.invalidateTags(['websites', 'notifications']));
        }
      }
    };

    // Website scaner completed
    socket.on('job_completed', onJobCompleted);

    const onJobFailed = (data: { id: number; category: string }) => {
      const category = data.category;

      if (data) {
        showNotification({
          ...getNotificationFailedPaylodFromCategory(category),
          color: 'red',
        });

        dispatch(api.util.invalidateTags(['jobs']));
      }
    };

    // Shellerent import failed
    socket.on('job_failed', onJobFailed);

    return () => {
      socket.off('job_started', onJobStarted);
      socket.off('job_completed', onJobCompleted);
      socket.off('job_failed', onJobFailed);
    };
  }, [dispatch]);

  return <></>;
}
