import { FC, Fragment } from 'react';

import { ModalProps } from '@interfaces/modals.interface';
import { Accordion, Button, Divider, Group, Text, Title } from '@mantine/core';
import { closeAllModals } from '@mantine/modals';
import { dateToDateString } from '@utils/date';

import { Changelog } from '@api/changelogs/changelogs.api';

interface ModalChangelogProps extends ModalProps {
  changelogs: Changelog[];
}

const ModalChangelog: FC<ModalChangelogProps> = ({
  changelogs,
  closeAllOnConfirm = true,
  onConfirmed,
}) => {
  // ==========================================================================
  // Handlers
  // ==========================================================================
  const onOkClicked = async () => {
    onConfirmed?.();

    if (closeAllOnConfirm) {
      closeAllModals();
    }
  };

  return (
    <>
      <Group gap="xs" mb="md">
        <Title order={3}>{changelogs[0].version}</Title>
        <Text fs="italic">
          ({dateToDateString(new Date(changelogs[0].createdAt))})
        </Text>
      </Group>
      {changelogs[0].features && (
        <>
          <Text fw="bold">Features</Text>
          <div
            dangerouslySetInnerHTML={{ __html: changelogs[0].features }}
          ></div>
        </>
      )}
      {changelogs[0].bugFixes && (
        <>
          <Text fw="bold">Bug fix</Text>
          <div
            dangerouslySetInnerHTML={{ __html: changelogs[0].bugFixes }}
          ></div>
        </>
      )}
      <Accordion variant="separated" mt="xl">
        <Accordion.Item value="1">
          <Accordion.Control>Vecchi changelog</Accordion.Control>
          <Accordion.Panel>
            {changelogs.slice(1).map((changelog) => (
              <Fragment key={changelog.version}>
                <Group gap="xs" mb="md">
                  <Title order={3}>{changelog.version}</Title>
                  <Text fs="italic">
                    ({dateToDateString(new Date(changelog.createdAt))})
                  </Text>
                </Group>
                {changelog.features && (
                  <>
                    <Text fw="bold">Features</Text>
                    <div
                      dangerouslySetInnerHTML={{ __html: changelog.features }}
                    ></div>
                  </>
                )}
                {changelog.bugFixes && (
                  <>
                    <Text fw="bold">Bug fix</Text>
                    <div
                      dangerouslySetInnerHTML={{ __html: changelog.bugFixes }}
                    ></div>
                  </>
                )}
                <Divider my="md" />
              </Fragment>
            ))}
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>

      <Group justify="flex-end" mt="md">
        <Button onClick={onOkClicked}>OK</Button>
      </Group>
    </>
  );
};

export default ModalChangelog;
