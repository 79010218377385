import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  Button,
  Container,
  Grid,
  LoadingOverlay,
  Paper,
  PasswordInput,
  Text,
  TextInput,
  Title,
  useMantineColorScheme,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { handleSubmitError } from '@utils/general';

import {
  useActivateUserMutation,
  useCheckTokenQuery,
  useRequestActivationMutation,
} from '@api/users/users.api';

import logo from '@images/logo-black.svg';
import logoWhite from '@images/logo.svg';

import classes from './UserActivate.module.css';

const UserActivate: FC = () => {
  // ==========================================================================
  // General
  // ==========================================================================

  const { colorScheme } = useMantineColorScheme();

  const { token } = useParams();

  const navigate = useNavigate();
  // ==========================================================================
  // State
  // ==========================================================================

  // ==========================================================================
  // Api
  // ==========================================================================
  const { isError, isLoading: isLoadingCheckToken } = useCheckTokenQuery(
    token!,
  );

  const [activateUser, { isLoading: isLoadingActivateUser }] =
    useActivateUserMutation();

  const [RequestActivation, { isLoading: isLoadingRequestActivation }] =
    useRequestActivationMutation();

  // ==========================================================================
  // Form
  // ==========================================================================
  const initialValuesSetPassword = {
    password: '',
    passwordConfirm: '',
  };

  const formSetPassord = useForm({
    initialValues: initialValuesSetPassword,
    validate: {
      passwordConfirm: (value, values) =>
        value !== values.password ? 'Le password non corrispondono.' : null,
    },
  });

  const onSubmitSetPassword = async (
    values: typeof initialValuesSetPassword,
  ) => {
    try {
      await activateUser({
        token: token!,
        body: { password: values.password },
      }).unwrap();

      showNotification({
        title: 'Password impostata correttamente',
        message: 'La tua password è stata impostata con successo',
      });

      navigate('/');
    } catch (e) {
      handleSubmitError(e, formSetPassord);
    }
  };

  const initialValuesExpiredLink = {
    email: '',
  };

  const formExpiredLink = useForm({
    initialValues: initialValuesExpiredLink,
  });

  const onSubmitExpiredLink = async (
    values: typeof initialValuesExpiredLink,
  ) => {
    try {
      await RequestActivation({ email: values.email }).unwrap();

      showNotification({
        title: 'Richiesta inviata',
        message: 'Richiesta attivazione inviata. Controlla la tua email',
      });
    } catch (e) {
      handleSubmitError(e, formExpiredLink);
    }
  };

  // ==========================================================================
  // Render
  // ==========================================================================

  return (
    <>
      <LoadingOverlay visible={isLoadingCheckToken} />
      <Container size={420} my={120}>
        {colorScheme === 'light' ? (
          <img src={logo} alt="Miroir Studio" className={classes.logo} />
        ) : (
          <img src={logoWhite} alt="Miroir Studio" className={classes.logo} />
        )}

        <Paper p={30} mt={30} className={classes.pw_paper}>
          {isError ? (
            <form
              onSubmit={formExpiredLink.onSubmit((values) => {
                onSubmitExpiredLink(values);
              })}
            >
              <Grid>
                <Grid.Col span={12}>
                  <Title order={2} ta="center" mb="lg">
                    Link scaduto
                  </Title>
                </Grid.Col>
                <Grid.Col span={12}>
                  <Text mb="md">Link di creazione password scaduto.</Text>
                  <TextInput
                    label="Inserisci email"
                    required
                    placeholder="email"
                    {...formExpiredLink.getInputProps('email')}
                  />
                  <Button
                    type="submit"
                    fullWidth
                    mt="xl"
                    loading={isLoadingRequestActivation}
                  >
                    Richiedi nuovo link
                  </Button>
                </Grid.Col>
              </Grid>
            </form>
          ) : (
            <form
              onSubmit={formSetPassord.onSubmit((values) => {
                onSubmitSetPassword(values);
              })}
            >
              <Title order={2} ta="center" mb="lg">
                Imposta password
              </Title>

              <PasswordInput
                label="Password"
                placeholder="password"
                required
                {...formSetPassord.getInputProps('password')}
              />
              <PasswordInput
                mt="xs"
                label="Ripeti password"
                placeholder="password"
                required
                {...formSetPassord.getInputProps('passwordConfirm')}
              />
              {formSetPassord.errors.general && (
                <Text color="red" p="8px">
                  {formSetPassord.errors.general}
                </Text>
              )}
              <Button
                type="submit"
                fullWidth
                mt="xl"
                loading={isLoadingActivateUser}
              >
                Conferma
              </Button>
            </form>
          )}
        </Paper>
      </Container>
    </>
  );
};

export default UserActivate;
