import { SortOrder } from '@domain/types';

import api from '../';

interface ClientCount {
  count: number;
}

export type SortBy = 'companyName' | 'email';

export type ClientType = 'company' | 'holder';

export interface ClientApiQueryParams {
  sortBy?: SortBy;
  sortOrder?: SortOrder;
  searchQuery?: string;
  page?: number;
  pageLength?: number;
  type?: ClientType;
}

export interface Client {
  id: number;
  name: string;
  surname: string;
  companyName: string;
  email: string;
  vat: string | null;
  province: string | null;
  city: string | null;
  address: string | null;
  taxCode: string | null;
  pec: string | null;
  sdi: string | null;
  phone: string | null;
  notes: string | null;
  agencyId: number;
  agency: {
    agencyName: string;
  };
  type: ClientType;
}

export interface ClientDetail {
  id: number;
  type: ClientType;
  name: string | null;
  surname: string | null;
  companyName: string | null;
  vat: string | null;
  province: string | null;
  city: string | null;
  address: string | null;
  taxCode: string | null;
  email: string;
  phone: string | null;
  pec: string | null;
  sdi: string | null;
  notes: string | null;
  agencyId: number;
  agency: {
    agencyName: string;
  };
}

export interface ClientAddRequest {
  type: ClientType;
  name?: string;
  surname?: string;
  companyName?: string;
  vat?: string;
  province?: string;
  city?: string;
  address?: string;
  taxCode?: string;
  email?: string;
  phone?: string;
  pec?: string;
  sdi?: string;
  notes?: string;
  agencyId: number;
}

interface ClientPatchRequest {
  id: number;
  body: {
    type: string;
    name?: string;
    surname?: string;
    companyName?: string;
    vat?: string;
    province?: string;
    city?: string;
    address?: string;
    taxCode?: string;
    email?: string;
    phone?: string;
    pec?: string;
    sdi?: string;
    notes?: string;
    agencyId?: number;
  };
}

interface ClientDeleteRequest {
  id: number;
}

const clientsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getClients: builder.query<Client[], ClientApiQueryParams>({
      query: (params) => ({
        url: 'clients',
        params,
      }),
      providesTags: ['client'],
    }),
    getClientsCount: builder.query<ClientCount, string | undefined>({
      query: (searchQuery) => ({
        url: 'clients/count',
        params: { searchQuery },
      }),
      providesTags: ['client'],
    }),
    getSingleClient: builder.query<ClientDetail, number>({
      query: (id) => ({
        url: `clients/${id}`,
      }),
      providesTags: ['client'],
    }),
    addClient: builder.mutation<Client, ClientAddRequest>({
      query: (body) => ({
        url: 'clients',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['client'],
    }),
    patchClient: builder.mutation<Client, ClientPatchRequest>({
      query: ({ id, body }) => ({
        url: `/clients/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['client'],
    }),
    deleteClient: builder.mutation<string, ClientDeleteRequest>({
      query: ({ id }) => ({
        url: `clients/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['client'],
    }),
  }),
});

export const {
  useGetClientsQuery,
  useGetClientsCountQuery,
  useGetSingleClientQuery,
  useAddClientMutation,
  usePatchClientMutation,
  useDeleteClientMutation,
} = clientsApi;
