import { ForwardRefExoticComponent, ReactNode, RefAttributes } from 'react';
import { NavLink } from 'react-router-dom';

import { ModalProps } from '@interfaces/modals.interface';
import { Group, rem, Text, useMantineTheme } from '@mantine/core';
import { Icon, IconProps } from '@tabler/icons-react';

import classes from './SidebarItem.module.css';

interface SidebarItemProps extends ModalProps {
  link: string;
  label: string;
  icon: ForwardRefExoticComponent<IconProps & RefAttributes<Icon>>;
  selectedIcon: ForwardRefExoticComponent<IconProps & RefAttributes<Icon>>;
  rightSection?: ReactNode;
}

export function SidebarItem({
  link,
  label,
  icon: LinkIcon,
  selectedIcon: LinkSelectedIcon,
  rightSection,
}: SidebarItemProps) {
  // ==========================================================================
  // General
  // ==========================================================================
  const theme = useMantineTheme();

  // ==========================================================================
  // Api
  // ==========================================================================

  // ==========================================================================
  // State
  // ==========================================================================

  // ==========================================================================
  // Form
  // ==========================================================================

  // ==========================================================================
  // Render
  // ==========================================================================
  return (
    <NavLink to={link} key={link} end className={classes.link}>
      <LinkIcon
        className="icon"
        size="1.9rem"
        style={{
          marginRight: theme.spacing?.md,
        }}
      />
      <LinkSelectedIcon
        className="selected-icon"
        size="1.9rem"
        style={{
          marginRight: theme.spacing?.md,
        }}
      />
      <Group justify="space-between" style={{ width: '100%' }}>
        <Text fz={rem('1.12rem')} className={classes.label}>
          {label}
        </Text>
        {rightSection}
      </Group>
    </NavLink>
  );
}
