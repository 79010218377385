import { DataTableSortStatus } from 'mantine-datatable';
import { FC, useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { Button } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import { IconFileText } from '@tabler/icons-react';
import { numberToCurrency } from '@utils/number';

import { useGetProformByProjectMutation } from '@api/proforms/proforms.api';
import { useGetSingleProjectQuery } from '@api/projects/projects.api';
import {
  DateExpiryFilter,
  Service,
  ServiceApiQueryParams,
  SortBy,
  useGetProjectServicesCountQuery,
  useGetProjectServicesQuery,
  useGetProjectTotServiceQuery,
} from '@api/services/services.api';
import { generateDataServices } from '@api/services/services.mock';
import {
  CustomServiceShMap,
  ServiceShApiQueryParams,
  SortBySh,
  useGetProjectServicesShCountQuery,
  useGetProjectServicesShQuery,
  useGetProjectTotServiceShQuery,
} from '@api/servicessh/servicesSh.api';
import { generateDataServicessh } from '@api/servicessh/servicesSh.mock';

import useLayoutProps from '@components/layout/useLayoutProps';
import ServicesTables from '@components/ServicesTables';

const ProjectServices: FC = () => {
  // ==========================================================================
  // General
  // ==========================================================================
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  // ==========================================================================
  // State
  // ==========================================================================

  const [filtersServiceSh, setFiltersServiceSh] =
    useState<ServiceShApiQueryParams>({
      page: +(searchParams.get('page') || 1),
      pageLength: +(searchParams.get('pageLength') || 20),
      searchQuery: '',
      dateExpiryFilter: searchParams.get('dateExpiryShFilter')
        ? (searchParams.get('dateExpiryShFilter') as DateExpiryFilter)
        : undefined,
    });

  const [searchQuerySh] = useDebouncedValue(filtersServiceSh.searchQuery, 200, {
    leading: true,
  });

  const [filtersService, setFiltersService] = useState<ServiceApiQueryParams>({
    page: +(searchParams.get('page') || 1),
    pageLength: +(searchParams.get('pageLength') || 20),
    searchQuery: '',
    dateExpiryFilter: searchParams.get('dateExpiryFilter')
      ? (searchParams.get('dateExpiryFilter') as DateExpiryFilter)
      : undefined,
  });

  const [searchQuery] = useDebouncedValue(filtersService.searchQuery, 200, {
    leading: true,
  });

  const [sortStatusService, setSortStatusService] = useState<
    DataTableSortStatus<Service>
  >({
    columnAccessor: 'name',
    direction: 'asc',
  });

  const [sortStatusServiceSh, setSortStatusServiceSh] = useState<
    DataTableSortStatus<CustomServiceShMap>
  >({
    columnAccessor: 'dateExpiry',
    direction: 'asc',
  });

  useEffect(() => {
    if (sortStatusService) {
      setFiltersService((f: ServiceApiQueryParams) => ({
        ...f,
        sortBy: sortStatusService.columnAccessor as SortBy,
        sortOrder: sortStatusService.direction,
      }));
    }

    if (sortStatusServiceSh) {
      setFiltersServiceSh((f: ServiceShApiQueryParams) => ({
        ...f,
        sortBy: sortStatusServiceSh.columnAccessor as SortBySh,
        sortOrder: sortStatusServiceSh.direction,
      }));
    }
  }, [sortStatusService, sortStatusServiceSh]);

  // ==========================================================================
  // Api
  // ==========================================================================
  const { data: shCount = { count: 0 }, isLoading: isLoadingShCount } =
    useGetProjectServicesShCountQuery({
      id: id!,
      params: {
        searchQuery: searchQuerySh,
        dateExpiryFilter: filtersServiceSh.dateExpiryFilter,
      },
    });

  const {
    data: servicesSh = generateDataServicessh(4),
    isLoading: isLoadingSh,
    error: errorProjectServicesSh,
  } = useGetProjectServicesShQuery({
    id: id!,
    params: {
      ...filtersServiceSh,
      searchQuery: searchQuerySh,
    },
  });

  const {
    data: servicesCount = { count: 0 },
    isLoading: isLoadingServicesCount,
  } = useGetProjectServicesCountQuery({
    id: id!,
    params: {
      searchQuery: searchQuery,
      dateExpiryFilter: filtersService.dateExpiryFilter,
    },
  });

  const {
    data: services = generateDataServices(2),
    isLoading: isLoadingServices,
    error: errorProjectServices,
  } = useGetProjectServicesQuery({
    id: id!,
    params: {
      ...filtersService,
      searchQuery,
    },
  });

  const { data: project, isLoading: isLoadingProject } =
    useGetSingleProjectQuery(+id!);

  const [getProform, { isLoading: isLoadingProform }] =
    useGetProformByProjectMutation();

  useLayoutProps({
    title: `Servizi del progetto: ${project?.name}`,
    loadingText: isLoadingProform ? 'Generazione proforma...' : undefined,
  });

  const { data: totalService, isLoading: isLoadingProjectTotService } =
    useGetProjectTotServiceQuery({
      id: +id!,
      params: {
        dateExpiryFilter: filtersService.dateExpiryFilter,
      },
    });

  const { data: totalServiceSh, isLoading: isLoadingProjectTotServiceSh } =
    useGetProjectTotServiceShQuery({
      id: +id!,
      params: {
        dateExpiryFilter: filtersServiceSh.dateExpiryFilter,
      },
    });

  const getMappedServicesSh = (
    serviceSh: CustomServiceShMap[],
  ): CustomServiceShMap[] =>
    serviceSh.map((sh) => {
      const project = sh.projects.find((project) => project.projectId === +id!);

      return {
        ...sh,
        renewPriceMarkup: project ? project.renewPriceMarkup : 0,
        dateExpiry: project ? project.dateExpiry : '',
        toNotify: project ? project.toNotify : false,
      };
    });

  const getMappedServices = (service: Service[]): Service[] =>
    service.map((s) => {
      const project = s.projects.find((project) => project.projectId === +id!);

      return {
        ...s,
        renewPriceMarkup: project ? project.renewPriceMarkup : 0,
        dateExpiry: project ? project.dateExpiry : '',
        toNotify: project ? project.toNotify : false,
      };
    });

  // const totalCost = [
  //   {
  //     key: 'total',
  //     data: totalService
  //       ? [
  //           <Text style={{ fontWeight: 'bold' }} pl="md">
  //             Totali:
  //           </Text>,
  //           <></>,
  //           <></>,
  //           <b>
  //             €
  //             {totalService.renewPrice === null
  //               ? 0
  //               : numberToCurrency(totalService.renewPrice)}
  //           </b>,
  //           <b>
  //             €
  //             {totalService.renewPriceMarkup === null
  //               ? 0
  //               : numberToCurrency(totalService.renewPriceMarkup)}
  //           </b>,
  //           <></>,
  //           <></>,
  //           <></>,
  //           <></>,
  //         ]
  //       : [],
  //   },
  // ];
  // const totalCostSh = [
  //   {
  //     key: 'totalSh',
  //     data: totalServiceSh
  //       ? [
  //           <Text style={{ fontWeight: 'bold' }} pl="md">
  //             Totali:
  //           </Text>,
  //           <></>,
  //           <></>,
  //           <></>,
  //           <b>
  //             €
  //             {totalServiceSh.renewPrice === null
  //               ? 0
  //               : numberToCurrency(totalServiceSh.renewPrice)}
  //           </b>,
  //           <b>
  //             €
  //             {totalServiceSh.renewPriceMarkup === null
  //               ? 0
  //               : numberToCurrency(totalServiceSh.renewPriceMarkup)}
  //           </b>,
  //           <></>,
  //           <></>,
  //           <></>,
  //           <></>,
  //         ]
  //       : [],
  //   },
  // ];
  const rinnovoService =
    totalService && totalService.renewPrice !== null
      ? numberToCurrency(totalService.renewPrice)
      : 0;

  const miroirService =
    totalService && totalService.renewPriceMarkup !== null
      ? numberToCurrency(totalService.renewPriceMarkup)
      : 0;

  const rinnovoServiceSh =
    totalServiceSh && totalServiceSh.renewPrice !== null
      ? numberToCurrency(totalServiceSh.renewPrice)
      : 0;

  const miroirServiceSh =
    totalServiceSh && totalServiceSh.renewPriceMarkup !== null
      ? numberToCurrency(totalServiceSh.renewPriceMarkup)
      : 0;

  // ==========================================================================
  // Render
  // ==========================================================================
  return (
    <>
      <Button
        leftSection={<IconFileText />}
        mb="md"
        loading={isLoadingProform}
        onClick={async () => {
          try {
            const blob = await getProform(id!).unwrap();

            const alink = document.createElement('a');
            alink.href = window.URL.createObjectURL(blob);
            alink.download = `Proforma ${project?.name}.pdf`;
            alink.click();
            alink.remove();
          } catch (e) {
            console.error(e);
            showNotification({
              title: 'Errore',
              message: 'Errore nella generazione della proforma',
              color: 'red',
            });
          }
        }}
      >
        Scarica proforma
      </Button>

      <ServicesTables
        servicesData={
          errorProjectServices ? [] : getMappedServices(services as Service[])
        }
        servicesShData={
          errorProjectServicesSh
            ? []
            : getMappedServicesSh(servicesSh as CustomServiceShMap[])
        }
        servicesFooter={{ miroir: miroirService, rinnovo: rinnovoService }}
        servicesShFooter={{
          miroir: miroirServiceSh,
          rinnovo: rinnovoServiceSh,
        }}
        servicesShPagination={{
          count: shCount.count,
          pageLength: filtersServiceSh.pageLength || 1,
          page: filtersServiceSh.page || 1,
          onPageChange: (newPage) =>
            setFiltersService({ ...filtersService, page: newPage }),
          onPageLengthChange: (newPageLength) =>
            setFiltersService({
              ...filtersService,
              pageLength: newPageLength,
              page: 1,
            }),
        }}
        servicesPagination={{
          count: servicesCount.count,
          pageLength: filtersService.pageLength || 1,
          page: filtersService.page || 1,
          onPageChange: (newPage) =>
            setFiltersServiceSh({ ...filtersServiceSh, page: newPage }),
          onPageLengthChange: (newPageLength) =>
            setFiltersServiceSh({
              ...filtersServiceSh,
              pageLength: newPageLength,
              page: 1,
            }),
        }}
        servicesSearchValue={filtersService.searchQuery}
        onServicesSearchValueChange={(newValue) =>
          setFiltersService({ ...filtersService, searchQuery: newValue })
        }
        servicesShSearchValue={filtersServiceSh.searchQuery}
        onServicesShSearchValueChange={(newValue) =>
          setFiltersServiceSh({ ...filtersServiceSh, searchQuery: newValue })
        }
        hideAddServicesButtons
        hideToRenewColumn
        hideFilterAssociatedFilter={false}
        servicesDateExpiryFilter={filtersService.dateExpiryFilter}
        onServicesDateExpiryFilterChange={(value) => {
          const servicesDateExpiryFilterSet = value as DateExpiryFilter;

          searchParams.set('dateExpiryFilter', servicesDateExpiryFilterSet);
          setSearchParams(searchParams);

          setFiltersService({
            ...filtersService,
            dateExpiryFilter: servicesDateExpiryFilterSet,
          });
        }}
        servicesShDateExpiryFilter={filtersServiceSh.dateExpiryFilter}
        onServicesShDateExpiryFilterChange={(value) => {
          const servicesShDateExpiryFilterSet = value as DateExpiryFilter;
          searchParams.set('dateExpiryShFilter', servicesShDateExpiryFilterSet);
          setSearchParams(searchParams);

          setFiltersServiceSh({
            ...filtersServiceSh,
            dateExpiryFilter: servicesShDateExpiryFilterSet,
          });
        }}
        loadingServicesBool={
          isLoadingServices ||
          isLoadingServicesCount ||
          isLoadingProjectTotService ||
          isLoadingProject
        }
        loadingServicesShBool={
          isLoadingSh ||
          isLoadingShCount ||
          isLoadingProjectTotServiceSh ||
          isLoadingProject
        }
        errorServices={errorProjectServices !== undefined}
        errorServicesSh={errorProjectServicesSh !== undefined}
        // Sorting
        sortStatusService={sortStatusService}
        setSortStatusService={setSortStatusService}
        sortStatusServiceSh={sortStatusServiceSh}
        setSortStatusServiceSh={setSortStatusServiceSh}
      />
    </>
  );
};

export default ProjectServices;
