import { useEffect } from 'react';

import { ModalProps } from '@interfaces/modals.interface';
import { Button, Grid, Switch, Text } from '@mantine/core';
import { TimeInput } from '@mantine/dates';
import { useForm } from '@mantine/form';
import { closeAllModals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { handleSubmitError } from '@utils/general';

import { useGetCronQuery, useUpsertCronMutation } from '@api/cron/cron.api';

type ModalPatchWebsiteScannerCronProps = ModalProps;

export default function ModalPatchWebsiteScannerCron({
  closeAllOnConfirm = true,
  onConfirmed,
}: ModalPatchWebsiteScannerCronProps) {
  // ==========================================================================
  // Api
  // ==========================================================================
  const { data: cron } = useGetCronQuery('websiteScannerScan');

  const [upsertCron, { isLoading }] = useUpsertCronMutation();

  // ==========================================================================
  // Form
  // ==========================================================================
  const initialValues = {
    active: false,
    time: '08:00',
  };

  useEffect(() => {
    if (cron) {
      form.setValues({
        active: cron.active,
        time: `${cron.cronTime[2].padStart(2, '0')}:${cron.cronTime[1].padStart(
          2,
          '0',
        )}`,
      });
    }
  }, [cron]);

  const form = useForm({
    initialValues,
  });

  const onSubmit = async (values: typeof initialValues) => {
    try {
      const splittedDate = values.time.split(':');

      await upsertCron({
        type: 'websiteScannerScan',
        cronTime: [
          '0',
          (+splittedDate[1]).toString(),
          (+splittedDate[0]).toString(),
          '*',
          '*',
          '*',
        ], // TODO: convert to utc
        active: values.active,
      }).unwrap();

      onConfirmed?.();

      if (closeAllOnConfirm) {
        closeAllModals();
      }

      showNotification({
        title: 'Orario modificato',
        message: "L'orario di scansione è stato modificato con successo",
      });
    } catch (e) {
      handleSubmitError(e, form);
    }
  };

  // ==========================================================================
  // Render
  // ==========================================================================
  return (
    <form
      onSubmit={form.onSubmit((values) => {
        onSubmit(values);
      })}
    >
      <Grid>
        {/* ROW */}
        <Grid.Col span={12}>
          <Switch
            label="Attivo"
            checked={form.values.active}
            onChange={(e) =>
              form.setFieldValue('active', e.currentTarget.checked)
            }
          />
        </Grid.Col>

        {/* ROW */}
        <Grid.Col span={12}>
          <TimeInput label="Orario scansione" {...form.getInputProps('time')} />
        </Grid.Col>

        {/* ROW */}
        <Grid.Col span={8} mt="md">
          {form.errors.general && (
            <Text c="red" mt="md">
              {form.errors.general}
            </Text>
          )}
        </Grid.Col>
        <Grid.Col span={4}>
          <Button type="submit" fullWidth mt="md" loading={isLoading}>
            Conferma
          </Button>
        </Grid.Col>
      </Grid>
    </form>
  );
}
