import {
  ActionIcon,
  createTheme,
  Loader,
  Paper,
  Select,
  Skeleton,
  Switch,
  Tooltip,
} from '@mantine/core';

const theme = createTheme({
  fontFamily: 'Montserrat, sans-serif',
  headings: {
    fontFamily: 'Montserrat, sans-serif',
    sizes: {
      // h1: {
      //   fontSize: '1.5rem',
      // },
      // h2: {
      //   fontSize: '1.2rem',
      // },
    },
  },

  black: '#222222',

  // colors: {
  //   brand: [
  //     '#fdf4eb',
  //     '#eee7df',
  //     '#d8cdc3',
  //     '#c1b1a2',
  //     '#ae9a87',
  //     '#a28b75',
  //     '#9d846b',
  //     '#8a7159',
  //     '#7b644d',
  //     '#6d553d',
  //   ],
  // },

  primaryColor: 'brand',

  defaultRadius: 'md',

  scale: 0.9,

  components: {
    Paper: Paper.extend({
      defaultProps: {
        shadow: 'lg',
      },
    }),

    Switch: Switch.extend({
      styles: {
        track: {
          cursor: 'pointer',
        },
      },
    }),

    Skeleton: Skeleton.extend({
      styles: {
        root: {
          width: 'max-content',
        },
      },
    }),

    Loader: Loader.extend({
      defaultProps: {
        type: 'dots',
      },
    }),

    ActionIcon: ActionIcon.extend({
      defaultProps: {
        variant: 'transparent',
      },
    }),

    Select: Select.extend({
      defaultProps: {
        checkIconPosition: 'right',
      },
    }),

    Tooltip: Tooltip.extend({
      defaultProps: {
        transitionProps: { transition: 'fade', duration: 300 },
      },
    }),
  },
});

export default theme;
