import { forwardRef, ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { ActionIcon, Badge } from '@mantine/core';

import classes from './IconBadge.module.css';

interface IconBadgeProps {
  badgeText?: string | null;
  to: string;
  children: ReactNode;
}

export default forwardRef<HTMLAnchorElement, IconBadgeProps>(function IconBadge(
  { badgeText, to, children },
  ref,
) {
  // ==========================================================================
  // Render
  // ==========================================================================
  return (
    <ActionIcon
      to={to}
      component={Link}
      pos="relative"
      variant="transparent"
      ref={ref}
    >
      {children}
      {badgeText && (
        <Badge className={classes.badge} size="sm">
          {badgeText}
        </Badge>
      )}
    </ActionIcon>
  );
});
