import { UserPermissions } from '@interfaces/auth.interface';

import { ClientType } from '@api/clients/clients.api';
import { JobsCategory, JobsStatus } from '@api/jobs/jobs.api';
import { NotificationsCategory } from '@api/notifications/notifications.api';

type MockableCustomTypes =
  | 'ClientType'
  | 'JobsStatus'
  | 'JobsCategory'
  | 'NotificationsCategory'
  | 'UserPermissions';

type MockableType =
  | 'string'
  | 'number'
  | 'boolean'
  | 'date'
  | 'null'
  | MockableCustomTypes;

interface MockableStringOptions {
  type: 'string';
  minLength?: number;
  maxLength?: number;
}

interface MockableNumberOptions {
  type: 'number';
  min?: number;
  max?: number;
  decimals?: number;
}

interface MockableDateOptions {
  type: 'date';
  minDate?: Date;
  maxDate?: Date;
}

interface MockableArrayOptions {
  type: 'array';
  minLength?: number;
  maxLength?: number;
  each: MockTemplate;
}

interface MockableObjectOptions {
  type: 'object';
  values: Record<string, MockTemplate>;
}
// TODO: add object

type MockTemplate =
  | MockableType
  | MockableStringOptions
  | MockableNumberOptions
  | MockableDateOptions
  | MockableArrayOptions
  | MockableObjectOptions;

const generateRandomString = (minLength: number, maxLength: number): string => {
  const length =
    Math.floor(Math.random() * (maxLength - minLength + 1)) + minLength;
  const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let result = '';
  for (let i = 0; i < length; i++) {
    result += charset.charAt(Math.floor(Math.random() * charset.length));
  }
  return result;
};

const generateRandomNumber = (min: number, max: number): number => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

const generateRandomDate = (minDate: Date, maxDate: Date): Date => {
  return new Date(
    minDate.getTime() + Math.random() * (maxDate.getTime() - minDate.getTime()),
  );
};

const generateRandomArray = (
  minLength: number,
  maxLength: number,
  type: MockTemplate,
): unknown[] => {
  const length = generateRandomNumber(minLength, maxLength);

  return new Array(length).fill(null).map(() => generateMockValue(type));
};

const generateRandomCustomType = (type: MockableCustomTypes) => {
  const clientType: ClientType[] = ['company', 'holder'];
  const jobsStatus: JobsStatus[] = ['inProgress', 'completed', 'failed'];
  const jobsCategory: JobsCategory[] = ['servicesImporter', 'websiteScanner'];
  const notificationsCategory: NotificationsCategory[] = [
    'serviceExpiry',
    'websiteScanErrors',
  ];
  const userPermissions: UserPermissions[] = [
    'administrative',
    'tools',
    'users',
  ];

  switch (type) {
    case 'ClientType':
      return clientType[Math.floor(Math.random() * clientType.length)];

    case 'JobsStatus':
      return jobsStatus[Math.floor(Math.random() * jobsStatus.length)];

    case 'JobsCategory':
      return jobsCategory[Math.floor(Math.random() * jobsCategory.length)];

    case 'NotificationsCategory':
      return notificationsCategory[
        Math.floor(Math.random() * notificationsCategory.length)
      ];

    case 'UserPermissions':
      return userPermissions[
        Math.floor(Math.random() * userPermissions.length)
      ];
  }
};

// Generate Mock Template Value
// ======================================================

type GenerateMockValue<T extends MockTemplate> = T extends MockableStringOptions
  ? string
  : T extends MockableNumberOptions
    ? number
    : T extends MockableDateOptions
      ? Date
      : T extends MockableArrayOptions
        ? GenerateMockValue<T['each']>[]
        : T extends MockableObjectOptions
          ? { [K in keyof T['values']]: GenerateMockValue<T['values'][K]> }
          : T extends MockableType
            ? T extends 'string'
              ? string
              : T extends 'number'
                ? number
                : T extends 'boolean'
                  ? boolean
                  : T extends 'date'
                    ? Date
                    : T extends 'null'
                      ? null
                      : T extends 'ClientType'
                        ? ClientType
                        : T extends 'JobsStatus'
                          ? JobsStatus
                          : T extends 'JobsCategory'
                            ? JobsCategory
                            : T extends 'NotificationsCategory'
                              ? NotificationsCategory
                              : T extends 'UserPermissions'
                                ? UserPermissions
                                : never
            : never;

export const generateMockValue = <T extends MockTemplate>(
  type: T,
): GenerateMockValue<T> => {
  const options =
    typeof type === 'string'
      ? { type }
      : (type as Exclude<MockTemplate, MockableType>);

  switch (options.type) {
    case 'string': {
      const o = options as MockableStringOptions;
      return generateRandomString(
        o.minLength || 5,
        o.maxLength || 20,
      ) as GenerateMockValue<T>;
    }
    case 'number': {
      const o = options as MockableNumberOptions;
      return generateRandomNumber(
        o.min || 1,
        o.max || 100000,
        // TODO: add decimal
      ) as GenerateMockValue<T>;
    }
    case 'boolean':
      return (Math.random() < 0.5) as GenerateMockValue<T>;
    case 'date': {
      const o = options as MockableDateOptions;
      return generateRandomDate(
        o.minDate || new Date(2000, 0, 1),
        o.maxDate || new Date(2023, 11, 1),
      ) as GenerateMockValue<T>;
    }
    case 'null':
      return null as GenerateMockValue<T>;
    case 'array': {
      const o = options as MockableArrayOptions;
      return generateRandomArray(
        o.minLength || 5,
        o.maxLength || 5,
        o.each,
      ) as GenerateMockValue<T>;
    }
    case 'object': {
      const o = options as MockableObjectOptions;
      const mockObject = {} as Record<string, unknown>;
      for (const key in o.values) {
        mockObject[key] = generateMockValue(o.values[key]);
      }

      return mockObject as GenerateMockValue<T>;
    }
    default:
      return generateRandomCustomType(
        options.type as MockableCustomTypes,
      ) as GenerateMockValue<T>; // TODO: temp
  }
};
