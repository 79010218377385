import { FC, ReactNode } from 'react';

import { UserPermissions } from '@interfaces/auth.interface';

import useUserPermission from '@hooks/useUserPermission';

interface UserPermissionGuardProps {
  permission: UserPermissions;
  children: ReactNode;
}

const UserPermissionGuard: FC<UserPermissionGuardProps> = ({
  permission,
  children,
}) => {
  const hasPermission = useUserPermission(permission);

  return hasPermission && children;
};

export default UserPermissionGuard;
