import { User } from '@interfaces/auth.interface';
import { generateMockValue } from '@utils/generateMockData';

export const generateUserListMock = (count: number): User[] => {
  return generateMockValue({
    type: 'array',
    minLength: count,
    maxLength: count,
    each: {
      type: 'object',
      values: {
        id: 'number',
        email: 'string',
        name: 'string',
        surname: 'string',
        emailNotificationsEnabled: 'boolean',
        pushNotificationsEnabled: 'boolean',
        profilePicture: 'null',
        permissions: {
          type: 'array',
          minLength: 0,
          maxLength: 0,
          each: 'UserPermissions',
        },
      },
    },
  });
};
